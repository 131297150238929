
const recentSearchList = (function () {
    let _rs = [];
    function saveSearch(value) {
        if (localStorage['_ps']) {
            if (value !== "" && value.trim() !== "") {
                if (!_rs.includes(value)) {
                    _rs.unshift(value);
                    localStorage.setItem('_ps', JSON.stringify(_rs));
                } else {
                    const index = _rs.indexOf(value);
                    _rs.unshift(_rs.splice(index, 1)[0]);
                    localStorage.setItem('_ps', JSON.stringify(_rs));
                }
            }
        } else {
            if (value !== "" && value.trim() !== "") {
                localStorage.setItem('_ps', JSON.stringify([value]));
            }
        }
    }
    function fetchSearch() {
        if (localStorage['_ps']) {
            _rs = JSON.parse(localStorage['_ps']);
        }
        return _rs;
    }
    return {
        saveSearch: saveSearch,
        fetchSearch: fetchSearch
    };
}());
export default recentSearchList;
