import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import utils from './app-util'

export default function LogoBranding({ showLogoText = true }) {
    const [title, setTitle] = useState("Plannie");
    const history = useHistory();
    useEffect(() => {
        if (history.location.pathname !== "/search" && history.location.pathname !== "/") {
            setTitle("Go to Plannie Home");
        }
    }, [history]);
    function onClick(e) {
        e.preventDefault();
        history.push({ pathname: '/search' })
        return false;
    }
    return (
        <div className="brand-wrapper">
            <a className='d-block text-decoration-none' href='/#' onClick={onClick}>
                <div className="brand-wrapper-inner d-flex justify-content-center align-items-center" title={title}>
                    <div className="logo-wrap d-inline-block mr-2">
                        <img className="logo" alt="" src={utils.logo} />
                    </div>
                    {
                        showLogoText ?
                            <div className="brand-name-wrapper d-inline-block position-relative ml-2">
                                <h2 className="brand-name m-0">Plannie</h2>
                            </div>
                            : null
                    }

                </div>
            </a>
        </div>
    );
}
