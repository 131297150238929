import React, { useState, useEffect } from 'react';
import PlannieSearch from './plannie-search';
import getCountryFlag from '../countryflag';
import RaceSpinner from '../spinner';
import { useHistory } from "react-router-dom";
import UserProfile from '../user-profile';
import utils from '../app-util';
import LogoBranding from '../logo-branding';
import { toast } from 'react-toastify';
// import IdleTimeTracker from '../IdleTimeTrack/IdleTimeTracker';
import { useAppContext, useMount, useFilterDispatchContext } from '../context/context';
import { plannieUtils } from './plannie-utils';

function catchError403(err, supressmessagepopup = false) {
    document.querySelector(".search-input-field").disabled = true;
    document.querySelector("#search-icon-btn").disabled = true;
    if (typeof err.json === "function") {
        err.json().then(function (jsonErr) {
            if (!supressmessagepopup) {
                toast.error(jsonErr.error);
            } else {
                console.warn("You have no permission to access Plannie");
            }
        }).catch(function (genericErr) {
            console.log(genericErr);
        })
    } else {
        console.log('Fetch Error :', err);
    }
}
function getUserInfo(setAdminInfo) {
    const interval = setInterval(() => {
        if (window.sessionStorage.getItem("_at") !== null && window.sessionStorage.getItem("_at") !== undefined) {
            fetch("/userinfo_plannie", { headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") } })
                .then(function (response) {
                    if (response.status !== 200) {
                        console.log('Error Status Code: ' + response.status);
                        catchError403(response, true);
                        utils.checkSessionStatus(response)
                    } else {
                        response.json().then(function (resultResponse) {
                            if (resultResponse.success) {
                                setAdminInfo({isSuperUser: resultResponse.super_user, otheruser: resultResponse.otheruser});
                                sessionStorage.setItem('_uinfo', JSON.stringify(resultResponse));
                            }
                        });
                    }
                }).catch(function (err) {
                    catchError403(err, true);
                });
            clearInterval(interval);
        }
    }, 100);
}
function PlannieSearchpage(_props) {
    const [loading, setLoading] = useState(false);
    const isSearchPage = true;
    const history = useHistory();
    const [adminInfo, setAdminInfo] = useState({isSuperUser: false, otheruser: false});
    const { setState, state: { tourActive },} = useAppContext();
    const dispatchFilter = useFilterDispatchContext();
    useMount(()=>{
        if(tourActive){
            setState({ run: false});
        }
    });
    useEffect(() => {
        getUserInfo(setAdminInfo);
    }, [])


    function handleSearchOnKeyDown(inputVal) {
        if (isSearchPage && (inputVal !== "")) {
            const val = inputVal;
            setLoading(true);
            fetch("/plannie_results?query=" + val, {
                headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
            })
                .then((res) => {
                    if (res.status === 403) {
                        return Promise.reject(res);
                    } else {
                        return utils.checkSessionStatus(res);
                    }
                })
                .then(
                    (results) => {
                        if (results.primavera.Activity_ID !==undefined && results.primavera.Activity_ID.length > 0 ) {
                            const data =  plannieUtils.formatData(results);
                            dispatchFilter({
                                type: 'initial',
                                payload: {},
                                selection: {
                                  setResultCount: ()=>{},
                                  mainData: data.plannieFormatted,
                                  currentSelected: {},
                                  allSelected: {},
                                  basefilterElement: {}
                                }
                              });
                            const indices = results.primavera.Index;
                            sessionStorage.setItem("indices", JSON.stringify(indices));
                            sessionStorage.setItem('query', inputVal);
                           
                                history.push({ pathname: '/dashboard', state: { query: val, results: data,  isSuperUser:adminInfo.isSuperUser, otheruser: adminInfo.otheruser } })
                                    
                            } else {
                                setLoading(false);
                                toast.error("Search keyword doesn't fetch any results, please rephrase.");
                                document.querySelector('.search-input-field').value = "";
                            }
                        },
                        (err) => {
                            catchError403(err)
                            setLoading(false);
                            toast.error("Search keyword doesn't fetch any results, please rephrase.");
                            document.querySelector('.search-input-field').value = "";
                        }
                ).catch((err) => {
                    console.error(err);
                    catchError403(err)
                    setLoading(false);
                });
        }
        
    }
    return (
        <div className="container-fluid">
            <div style={{ position: 'absolute', right: '25px', top: '20px', zIndex: 2222 }}>
                <UserProfile isSuperUser={adminInfo.isSuperUser} otheruser={adminInfo.otheruser}/>
            </div>
            <div className="row align-item-stretch">
                <div className="col-6 landing-page-left-split">
                    <header className="position-absolute mt-4">
                        <LogoBranding showLogoText={false} />
                    </header>
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="w-100 px-1 position-relative">
                            <PlannieSearch isAdvancedSearch={false} isSearchPage={true} searchCallback={handleSearchOnKeyDown} />
                        </div>
                    </div>
                </div>
                <div className="col landing-page-right-split vh-100 d-flex justify-content-center align-items-center">
                    <div className='vh-100 w-100 position-absolute bg-light modal-backdrop fade show' ></div>
                    <div className='card bg-light p-4' style={{zIndex: 1041}}>
                        <div>
                            {/* <h5 className='mb-3' style={{color: 'var(--red-bg-color)'}}>Plannie - Your partner in data driven project planning</h5> */}
                            <p>Embark on a new era of data-driven project planning with 'Plannie'. Developed through meticulous data collection from Primavera, 'Plannie' serves as a vital tool for aiding planners and project professionals in assessing, reviewing, and comparing activity duration, float, predecessors, and successors.</p>
                            <p>'Plannie' offers extensive analysis of schedule data, drawn from over 2000 unique projects and encompassing 1 million distinct activities.</p>
                            <p>We value and encourage your feedback, suggestions, and comments to continuously enhance the capabilities and effectiveness of 'Plannie.' Please feel free to contact us at: <a href='mailto:rajesh.kalra@shell.com'>rajesh.kalra@shell.com</a> or <a href='mailto:c.udayappan@shell.com'>c.udayappan@shell.com</a></p>
                            <h6 className='text-danger'>Disclaimer:</h6>
                            <p>It's essential to recognize that the efficacy of 'Plannie' analysis hinges on the quality of raw data sourced from Primavera hence it's imperative to exercise caution when utilizing and disseminating this data.</p>
                            <p>Please note that 'Plannie' is not intended as a benchmarking tool and is solely for reference purposes.</p>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <RaceSpinner /> : null}
            <div className='footer-info position-absolute p-2 border w-100 app-bg-color text-center small' style={{bottom: 0, left: 0, zIndex: 1042}}>
                <span className='mb-0 font-weight-bold mr-4'>Plannie v1.0 - Apr 2024</span>
                <svg style={{position: 'relative', top: '-1.7px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#dc3545" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg>
                <span className='text-danger'>Confidential - "Use by authorized personnel only."</span>
            </div>
        </div>



        // <div className="container-fluid px-0">
        //     <div className='bg-light' style={{height: '60px'}}>
        //     <div style={{ position: 'absolute', right: '25px', top: '20px', zIndex: 1 }}>
        //         <UserProfile isSuperUser={adminInfo.isSuperUser} otheruser={adminInfo.otheruser}/>
        //     </div>
        //             <header className="position-absolute mt-4" style={{ left: '25px', top: '-10px', zIndex: 9999, }}>
        //                 <LogoBranding showLogoText={false} />
        //             </header>
        //     </div>
        //     <div className="row align-item-stretch">
        //         <div className="col-12 landing-page-left-split">
        //             {/* <header className="position-absolute mt-4">
        //                 <LogoBranding showLogoText={false} />
        //             </header> */}
        //             <div className="landing-page-bg d-flex flex-column justify-content-center align-items-center vh-100">
        //                 <div className='vh-100 w-100 position-absolute bg-dark modal-backdrop fade show' style={{zIndex: 0}} ></div>
        //                 <div className="w-100 px-5 position-relative" style={{opacity: 0.9}}>
        //                     <PlannieSearch isAdvancedSearch={false} isSearchPage={true} searchCallback={handleSearchOnKeyDown} />
        //                 </div>
        //                 <div className='p-4 mt-1' style={{zIndex: 1, opacity: 0.9}}>
        //                     <div className='bg-light p-4'>
        //                         <p>Embark on a new era of data-driven project planning with "Plannie." Developed through meticulous data collection from Primavera, "Plannie" represents a tool designed to support the planners & project professionals in estimation, review, and comparison of activity duration, float, predecessors, and successors.</p>
        //                         <p>It's essential to recognize that the efficacy of "Plannie" analysis hinges on the quality of raw data sourced from Primavera hence it's imperative to exercise caution when utilizing and disseminating this data.</p>
        //                         <p>We welcome and encourage your invaluable feedback, suggestions, and comments to continually enhance the capabilities and effectiveness of "Plannie." Please don't hesitate to reach out to us at: <a href='mailto:rajesh.kalra@shell.com'>rajesh.kalra@shell.com</a></p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         {/* <div className="col landing-page-right-split"></div> */}
        //     </div>
        //     {loading ? <RaceSpinner /> : null}
        // </div>

    );
}

export default PlannieSearchpage;
