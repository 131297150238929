import React from 'react'
import * as d3 from "d3";
import d3Tip from "d3-tip";
import utils from '../app-util';
import { plannieUtils } from './plannie-utils';
import { createLegend } from './grouped-stacked-bar-plot';

const TEXT_ANCHOR = "text-anchor";
const colorRange = ['#8bb3f9', '#8bb3f9']
const groupColor = ["#dd1d21"];
function plannieColorScale(groups){
    return d3.scaleOrdinal().domain(groups).range(colorRange);
}

function stackMax(stacks) {
    return d3.max(stacks, function (d) {
        return d[1];
    });
}
function stackMin(stacks) {
    return d3.min(stacks, function (d) {
        return d[0];
    });
}

class StackedBarchart extends React.Component {
    constructor(props) {
        super(props);
        this.createBarChart = this.createBarChart.bind(this);
        this.node = React.createRef();
        this.msgElem = React.createRef();
        this.legendContainer = React.createRef();
        this.legendsRef = React.createRef();
    }

    componentDidMount() {
        this.margin = { top: 50, right: 10, bottom: 80, left: 50 };
        this.svg = d3.select(this.node.current);
        this.width = (plannieUtils.getFixedPlotDimension().width - this.margin.left - this.margin.right - 90) * 60 / 100;
        this.height = 400; // plannieUtils.getFixedPlotDimension().height - this.margin.left - this.margin.right;
        this.xScale = d3.scaleBand().range([0, this.width]).padding(0.1);
        this.yScale = d3.scaleLinear().range([this.height, 0]);
        this.xAxis = d3.axisBottom(this.xScale);
        this.yAxis = d3.axisLeft(this.yScale);
        
        this.center = d3.scaleLinear().range([0, this.width]);
        this.centerLine = d3.axisTop(this.center).ticks(0);
        this.svg.append("g")
                .attr("class", "centerline")
                .attr("transform", `translate(${this.margin.left}, ${this.yScale(0) + this.margin.top} )`)
        d3.select(this.node.current).select(function () {
            // this.parentNode.style.overflow = "auto";
        })
        this.svg
            .attr("width", this.width + this.margin.left + this.margin.right)
            .attr("height", this.height + this.margin.top + this.margin.bottom);
        this.svg.append("g").attr("class", "x axis ")
            .attr('id', "xAxis")
            .attr("transform", `translate(${this.margin.left}, ${this.height + this.margin.top})`);
        this.svg.append("g")
            .attr("class", "y axis")
            .attr('id', "yAxis")
            .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

        this.svg.append("text")
            .attr('class', "xlabel")
            .attr(TEXT_ANCHOR, "end")
            .attr("x", this.width / 2 + 100)
            .attr("y", this.height + this.margin.bottom + this.margin.top - 5)
            .text(this.props.data.x_label)
            .style("font-size", "12px");
        this.svg.append("text")
            .attr('class', "ylabel")
            .attr("x", -200)
            .attr("y", 15)
            .attr("transform", "rotate(-90)")
            .style("font-size", "12px")
            .attr(TEXT_ANCHOR, "start")
            .text(this.props.data.y_label);

        this.tip = d3Tip()
            .attr("class", "d3-tooltip")
            .offset([-10, 0])
            .html(function (_d) {
                const nodeData = d3.select(this).data()[0];
                const key = d3.select(this).attr("data-key");
                return `${key}: ${nodeData.data[key]}`;
            });
        this.svg.call(this.tip)
        
        this.createBarChart();

        const __this = this
        const defaultWidth = parseFloat(__this.svg.attr('width') - 50);
        const defaultHeight = parseFloat(__this.svg.attr('height'));
        function updateOnFS() {
            document.querySelector(".tab-pane.active.show.graph .f-screen").style.width = __this.width * 60 / 100;
            document.querySelector(".tab-pane.active.show.graph .f-screen").style.height = __this.height;
            __this.svg.attr("width", __this.width + __this.margin.left + __this.margin.right)
                .attr("height", __this.height + __this.margin.top + __this.margin.bottom);
            __this.xScale.range([0, __this.width]).padding(0.1)
            __this.yScale.range([__this.height, 0])
            __this.svg.select('.x.axis').attr("transform", "translate(" + __this.margin.left + "," + (__this.height + __this.margin.top) + ")");
            __this.svg
                .transition()
                .attr("width", __this.width + __this.margin.left + __this.margin.right)
                .attr("height", __this.height + __this.margin.top + __this.margin.bottom)

            __this.svg.select(".xlabel")
                .attr("x", __this.width / 2 + 100)
                .attr("y", __this.height + __this.margin.bottom + __this.margin.top - 5);
        }
        [...document.querySelectorAll(".plannie-graph-analysis .tab-pane.graph")].forEach(function (element) {
            element.addEventListener('fullscreenchange', (_e) => {
                if (document.fullscreenElement) {
                    __this.width = window.innerWidth * 60 / 100;
                    __this.height = window.innerHeight - 100;
                    updateOnFS()
                } else {
                    __this.width = defaultWidth;
                    __this.height = defaultHeight;
                    updateOnFS()
                }
                setTimeout(() => {
                    __this.createBarChart();
                }, 100);
            })
        })
        this.svg.attr("class", this.props.type);
        
    }

    // shouldComponentUpdate(prevProps, _prevState, _snapshot) {
    //     if (this.props.dataType === 'quality') {
    //         const qualityTab = JSON.parse(sessionStorage.getItem("qualityTab"));
    //         if ((prevProps.data.isNew || (prevProps.data.isNew === undefined)) && !qualityTab.otclicked && !qualityTab.stclicked && qualityTab.newload) {
    //             this.createBarChart();
    //         }
    //         return false;
    //     }
    //     else if(prevProps.data.isNew || (prevProps.data.isNew === undefined)) {
    //         this.createBarChart();
    //         return true;
    //     }
    //     return false;
    // }
    componentDidUpdate() {
        // console.log(this.props.data)
         if(this.props.data.isNew) {
            this.createBarChart();
        }
    }


    checkValue(values, keys) {
        let allZero = true;
        for (let i = 0; i < values.length; ++i) {
            for (let j = 0; j < keys.length; ++j) {
                if (values[i][keys[j]] > 0) {
                    allZero = false;
                    break;
                }
            }
        }
        return allZero;
    }
    checkIfAllValueZero(values, _newData, keys) {
        let allZero = true;
        if ((values !== undefined) && (_newData !== undefined)) {
            allZero = this.checkValue(values, keys)
        }
        return allZero;
    }
    rectBarOnClick(_selfContext) {
        let filter = false;
        if (d3.select(_selfContext).classed("active") && this.svg.classed('clickactive')) {
            d3.select(_selfContext).classed("active", false);
            this.svg.classed('clickactive', false);

        } else {
            d3.select(_selfContext).classed("active", true);
            this.svg.classed('clickactive', true);
            filter = true;
        }
        // clear the highlighted rect on other rect clicking
        
        if ((this.isRectHighlighted !== null) && !this.isRectHighlighted.isSameNode(_selfContext)) {
            this.isRectHighlighted.classList.remove('active');
        }
        
        this.isRectHighlighted = _selfContext;

        const idsOrCategory = d3.select(_selfContext).attr("data-ids");
        this.props.stackedBarChartFIlterCallback({ id: idsOrCategory, filter: filter }, "float analysis", false);
    }
    createBarChart() {
        const __this = this;
        const data = this.props.data.data;
        if (!this.checkIfAllValueZero(data, this.props.data.isNew, this.props.data.groups)) {
            __this.svg.style('display', 'block')
            __this.msgElem.current.classList.remove('active');
            // __this.legendContainer.current.classList.remove('d-none');
            this.props.data.isNew = false;
            const groups = this.props.data.groups;
            __this.color = plannieColorScale(groups);
            
            createLegend(["Critical"], __this.legendContainer, __this.margin, `translate(${(__this.margin.left - (__this.margin.left + __this.margin.right + 30))},0)`, groupColor, true, "url(#maskfloat)");
            createLegend(["Non-critical"], __this.legendsRef, __this.margin, `translate(${(__this.margin.left + ((__this.legendsRef.current.getBBox().width === 0 ? 69.5 : __this.legendsRef.current.getBBox().width)) - (__this.margin.left + __this.margin.right))},0)`, colorRange, false);
            
            const stack = d3.stack().keys(groups).order(d3.stackOrderNone).offset(d3.stackOffsetDiverging);

            __this.xScale.domain(data.map(function (d) {
                return d.label;
            }));
            __this.yScale.domain([d3.min(stack(data), stackMin), d3.max(stack(data), stackMax)]);

            //------------------------------------------------------------------------------------------------------
            const x_var = "label";
            this.isRectHighlighted = null;
            __this.svg.selectAll(".bar").remove();
            groups.forEach(function (key, key_index) {
                const bar = __this.svg.selectAll(".bar-" + key.replace(/\s|,|:|\(|\)|\//g, '_'))
                    .data(stack(data)[key_index], function (d) {
                        return d.data[x_var] + "-" + key;
                    });
                const bw =  (__this.xScale.bandwidth() / 4);
                bar.transition()
                    .attr("x", function (d) { return __this.xScale(d.data[x_var]) + bw; })
                    .attr("y", function (d) { return __this.yScale(d[1]); })
                    .attr("height", function (d) { return __this.yScale(d[0]) - __this.yScale(d[1]); });
                bar.enter().append("rect")
                    .attr("class", function (_d) { return "bar bar-" + key.replace(/\s|,|:|\(|\)|\//g, '_'); })
                    .attr("data", function (d) {

                        return d.data[key];
                    })
                    .attr("data-key", key)
                    .attr("data-ids", function (d, i) {
                        return d.data[`${__this.props.dataType}_ids`][key][i];
                    })
                    .attr("x", function (d) { return __this.xScale(d.data[x_var]) + bw; })
                    .attr("y", function (d) { 
                        return __this.yScale(d[1]); 
                    })
                    .attr("height", function (d) { return __this.yScale(d[0]) - __this.yScale(d[1]); })
                    .attr("width", __this.xScale.bandwidth() / 2)
                    .attr("fill", function (_d) { 
                        if (key_index === 0) {
                            return groupColor[0]
                        } else{
                            return __this.color(key); 
                        }
                    })
                    .style('mask', () => {
                        if (key_index === 0) {
                            return `url(#maskfloat)`;
                        }
                    })
                    .attr("transform", "translate(" + __this.margin.left + "," + __this.margin.top + ")")
                    .on("mouseover", __this.tip.show)
                    .on("mouseout", __this.tip.hide)
                    .on("click", function (_d) {
                        __this.rectBarOnClick(this);
                    }).style("cursor", "pointer");
            });
            __this.svg.select('.centerline')
            .attr("transform", `translate(${__this.margin.left}, ${__this.yScale(0) + __this.margin.top} )`)
            __this.svg.select('.centerline').call(__this.centerLine);
            __this.svg.select('.x.axis').call(__this.xAxis);
            __this.svg.select('.y.axis').call(__this.yAxis);
            d3.select("#xAxis").selectAll("text")
                .attr("y", 3)
                .attr("dy", ".5em")
                .attr("x", "-.4em")
                .style("text-anchor", "end")
                .attr("transform", function (_d) {
                    return "translate(0,0) rotate(-45)";
                });
        } else {
            __this.svg.style('display', 'none')
            __this.msgElem.current.classList.add('active');
            // __this.legendContainer.current.classList.add('d-none');
        }
    }

    render() {
        return (
            <>
                <div className='no-data-msg-placeholder font-italic my-4 small text-center' ref={this.msgElem}>No data found</div>
                {/* <div className='legendContainerStackedBarchart' ref={this.legendContainer} style={{textAlign: 'justify'}}></div> */}
                <svg id={`${this.props.type}-plot`} ref={this.node} width="100%" height="300">
                    <g ref={this.legendContainer}></g>
                    <g ref={this.legendsRef}></g>
                    <defs>
                        <pattern id="patternStripeFloat"
                            width="4" height="4"
                            patternUnits="userSpaceOnUse"
                            patternTransform="rotate(45)">
                            <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
                        </pattern>
                        <mask id="maskfloat">
                            <rect x="0" y="0" width="100%" height="100%" fill="url(#patternStripeFloat)" />
                        </mask>
                    </defs>
                </svg>
            </>
        );
    }
}
export default StackedBarchart
