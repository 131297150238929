import React from "react";

export function PlannieList(props) {
  const predecessorList =formatMostCommonData('predecessor');
  const successorList =formatMostCommonData('successor');
  function formatMostCommonData(dataType) {
    const dataList = [];
    const sourceData=dataType==='predecessor'?props.data.predecessor:props.data.successor;
    if (sourceData !== undefined && Object.keys(sourceData).length > 0) {
        for (let i = 0; i < sourceData.Activity_ID.length; i++) {
          dataList.push({
                Activity_ID:sourceData.Activity_ID[i],
                Activity_Name: sourceData.Activity_Name[i],
                Calender: sourceData.Calender[i],
                
            })
        }
        return dataList;
    } else {
        return [];
    }
}
  return (
    <>
      <div className="row div_mostCommon">
        <div className="col-sm div_predecessors">
          <div className="div_header bold_text">Most Common Predecessors</div>
          <ol>
        {predecessorList.map((post) =>
          <li key={post.Activity_ID}>
            <div>
            <b>{post.Activity_Name}</b>
            </div>
            <div>
            Activity Code: {post.Activity_ID} , Calendar: {post.Calender}
            </div>

          </li>
         
        )}
      </ol>
        </div>
        <div className="col-sm div_successors">
          <div className="div_header bold_text">Most Common Successors</div>
          <ol>
          {successorList.map((post) =>
          <li key={post.Activity_ID}>
            <div>
            <b>{post.Activity_Name}</b>
            </div>
            <div>
            Activity Code: {post.Activity_ID} , Calendar: {post.Calender}
            </div>

          </li>
         
        )}
      </ol>
        </div>
      </div>
    </>
  );
}
