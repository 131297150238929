import { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import utils from '../app-util';
import RaceSpinner from '../spinner';

function loadSearchQueriesList(setRows, setLoading) {
    setLoading(true);
    fetch('/get-frequent-search-queries-list', {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
        .then(function (response) {
            if (response.status !== 200) {
                setLoading(false);
                utils.checkSessionStatus(response);
                console.log('Error Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                // Format the data to be grid ready
                setRows(data);
                setLoading(false);
            });
        }).catch(function (err) {
            setLoading(false);
            console.log('Fetch Error :', err);
        });
}
export default function PlannieMostFrequentlySearchedQueries() {
    const columns = [
        {
            key: 'search_query', name: "Search Queries"
        },
        {
            key: 'count', name: "Count"
        }
    ];
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        loadSearchQueriesList(setRows, setLoading);
    }, [])
    return (
        <div>
            <DataGrid className=''
                columns={columns}
                rows={rows}
            />
            {loading ? <RaceSpinner /> : null}
        </div>
    );
}
