import React, { useEffect, useState } from "react";
import PlotMenu from "../plannieComponent/plannie-plot-menu";
import alertMessage from "../alert";
import { filterLoader } from "../filter-utils";
import utils from "../app-util";
import plotInformation from "../data/plotInformation.json";
import PlannieResultCard from "./plannie-ResultCard";
import StackedBarchart from "./stacked-bar-chart";
import { PlannieList } from "./plannie-list";
import { toast } from "react-toastify";
import download from '../../assets/download.svg'

import {
  stackedBarFilterOnCall,
  loadFilteredDataForPlanPlots,
} from "./plannie-FilterUtils";

import GroupedStatckedBarPlot from "./grouped-stacked-bar-plot";
import BoxPlot from "./box-plot";
import { plannieUtils } from "./plannie-utils";
import RaceSpinner from "../spinner";
import PromptModal from "./PromptModal";


const useStateUpdate = (
  data,
  setactualDurationGraphData,
  setfloatGraphData,
  setUniqueProjectCount,
  setmostCommonData,
  setDurationboxPlotData,
  setFloatboxPlotData
) => {
  useEffect(() => {
    const formattedActualPlotData = plannieUtils.formatPlannieGroupedBarPlotData(
      data.duration_blduration_graph
    );
    setDurationboxPlotData(data.duration_box_graph);
    setFloatboxPlotData(data.float_box_graph);
    const formattedFloatingPlotData = plannieUtils.formatPlannieGraphData(
      data.total_float_graph
    );
    const formattedMostCommonData = data;
    const unique_count_projects = data.unique_count_projects;
    setactualDurationGraphData(formattedActualPlotData);
    setfloatGraphData({ ...formattedFloatingPlotData });
    setmostCommonData({ ...formattedMostCommonData });
    setUniqueProjectCount(unique_count_projects.unique_count_projects);

  }, [data, setactualDurationGraphData, setfloatGraphData,setUniqueProjectCount,setmostCommonData, setDurationboxPlotData, setFloatboxPlotData]);
};


function Plannie({
  planniedata,
  setResultCount,
  resultCount,
  activeSelectedFilters
}) {
  const [loading, setLoading] = useState(false);

  const [actualDurationGraphData, setactualDurationGraphData] = useState(
    plannieUtils.formatPlannieGroupedBarPlotData(planniedata.primavera.duration_blduration_graph)
  );

  const [floatGraphData, setfloatGraphData] = useState(
    plannieUtils.formatPlannieGraphData(planniedata.primavera.total_float_graph)
  );
  const [mostCommonData, setmostCommonData] = useState(planniedata.primavera);
  const [uniqueProjectCount, setUniqueProjectCount] = useState(
    planniedata.primavera.unique_count_projects.unique_count_projects
  );
  const [durationboxPlotData, setDurationboxPlotData] = useState(planniedata.primavera.duration_box_graph);
  const [floatboxPlotData, setFloatboxPlotData] = useState(planniedata.primavera.float_box_graph);

  const [promptmodal, setPromptmodal] = useState({open: false, message: ""});
  function onPromptModalClose() {
    setPromptmodal({open: false, message: ""});
  }
  useStateUpdate(
    planniedata.primavera,
    setactualDurationGraphData,
    setfloatGraphData,
    setUniqueProjectCount,
    setmostCommonData,
    setDurationboxPlotData,
    setFloatboxPlotData
  );
  function formatGraphData(graphData) {
    setactualDurationGraphData(
      plannieUtils.formatPlannieGroupedBarPlotData(graphData.duration_blduration_graph)
    );
    setDurationboxPlotData(graphData.duration_box_graph);
    setFloatboxPlotData(graphData.float_box_graph);
    setfloatGraphData(plannieUtils.formatPlannieGraphData(graphData.total_float_graph));
    setmostCommonData(graphData);
    GetUniqueProjectCountFromCards();

  }
  function plotRefreshOnclick() {
    loadFilteredDataForPlanPlots(
      "get-filtered-allgraphs-in-one",
      formatGraphData,
      setLoading
    );
  }
  function boxPlotUpdateOnBarchartFilter(ids, callbackFrom) {
    const filterURL = {
      "duration": "get-filtered-duration-box-plots",
      "float analysis": "get-filtered-float-box-plots"
    }
    setLoading(true);
    fetch(`/${filterURL[callbackFrom]}/${ids}`, {
      headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
      return utils.checkSessionStatus(res);
    }).then((results) => {
      setLoading(false);
      results.isNew = true;
      if (callbackFrom === 'duration') {
        setDurationboxPlotData(results);
      } else {
        setFloatboxPlotData(results);
      }
    }, (err) => {
      setLoading(false);
      console.error("Error: " + err);
    }).catch((err) => {
      setLoading(false);
      console.error("Error: " + err);
    });
  }
  /**
   * This callback is used to filter the result. And used for both box plot (duration & float) and bar plot (duration & float)
   * @param {*} data - data needed for filter
   * @param {*} callbackFrom - Name of bar plot call, to update box plot based on bar plot click
   * @param {*} isBoxplot - if box plot clicked to filter
   * @param {*} whichBoxPlotCall - if box plot clicked which box plot clicked to filter(this is needed only for box plot filter)
   */
  function stackedBarChartFilterOnCall(data, callbackFrom, isBoxplot=false, whichBoxPlotCall='duration-boxplot') {
    const count = stackedBarFilterOnCall(data, activeSelectedFilters, isBoxplot, whichBoxPlotCall);
    //On boxplot filter applied don't update box plot itself
    if (!isBoxplot) {
      setTimeout(() => {
        if (data.filter) {
          boxPlotUpdateOnBarchartFilter(JSON.stringify(data.id.split(",")), callbackFrom);
        } else {
          const filterdIds = utils.getVisibleResultIDs('indices', '.plannie-item.d-block', '.quality-item.d-none');
          boxPlotUpdateOnBarchartFilter(filterdIds, callbackFrom);
        }
      }, 1200);
    }
    setResultCount(count);
    GetUniqueProjectCountFromCards();
  }
  function GetUniqueProjectCountFromCards() {
    const visibleResultCards = document.querySelectorAll(
      ".plannie-item.search-item.d-block"
    );
    const uniqueDataAttributes = {};
    visibleResultCards.forEach((div) => {
      const dataAttributeValue = div.getAttribute("data-projectname");
      if (dataAttributeValue && !uniqueDataAttributes[dataAttributeValue]) {
        uniqueDataAttributes[dataAttributeValue] = true;
      }
    });
    setUniqueProjectCount(Object.keys(uniqueDataAttributes).length);
  }

  function GetUniqueProjectCountFromData() {
    let projectnames = planniedata.primavera.Project_Name;
    const uniqueDataAttributes = {};
    projectnames.forEach((div) => {
      const dataAttributeValue = div;
      if (dataAttributeValue && !uniqueDataAttributes[dataAttributeValue]) {
        uniqueDataAttributes[dataAttributeValue] = true;
      }
    });
  }


  function clearBtnOnClick(e) {
    const classSelector = `.${e.currentTarget.dataset.classname.split("-")[0]}`;
    const elementRootSVG = document.querySelector(classSelector);
    if (elementRootSVG.classList.contains("clickactive")) {
      let count = 0;
      document
        .querySelector(`svg${classSelector}`)
        .classList.remove("clickactive");
      // count = displayAllQualityCard();
      // setQualitystate({filterCount: count});
    }
  }

  function downloadSearchResultOnClick(e) {
    const activityIds = utils.getVisibleResultIDs("indices", ".plannie-item.d-block", ".plannie-item.d-none", "id")
    utils.makeDownloadRequest(`/download-search-result-plannie/${activityIds}`, "Results", e.currentTarget, "Download Search Results" );
  }
  const [isCardView, setIsCardView] = useState(false);
  function gridLayoutOnChange(e) {
    if (e.target.checked === true) {
      setIsCardView(true);
    } else {
      setIsCardView(false);
    }
  }
  return (
    <div className="emoc-container">
      <div className="plannie-graph-analysis mt-3">
        <div className="card card-box-shadow ">
          <div className="emoc-tab-wrapper px-3 pb-3 pt-1">
            <ul className="nav nav-tabs" id="emocTabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="activityCount-tab"
                  data-toggle="tab"
                  href="#activityCountGraph"
                  role="tab"
                  aria-controls="activityCountGraph"
                  aria-selected="true"
                >
                  Duration
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="floatAnalysis-tab"
                  data-toggle="tab"
                  href="#floatAnalysisGraph"
                  role="tab"
                  aria-controls="floatAnalysisGraph"
                  aria-selected="false"
                >
                  Float Analysis
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="mostCommon-tab"
                  data-toggle="tab"
                  href="#mostCommonGraph"
                  role="tab"
                  aria-controls="mostCommonGraph"
                  aria-selected="false"
                >
                  Predecessor & Successor
                </a>
              </li>
            </ul>
            <div className="tab-content border-bottom pb-3" id="emocTabContent">
              {filterLoader("filter-loader-riskplot")}
              <div
                className="tab-pane fade graph show active"
                id="activityCountGraph"
                role="tabpanel"
                aria-labelledby="activityCount-tab"
              >
                <div className="f-screen bg-white">
                  <PlotMenu
                    showRefreshBtn={true}
                    title="Clear Selection"
                    customClass="cost-c-btn"
                    fullscreenSelector={"activityCountGraph"}
                    // info={'<ol class="px-3"><li>Planned median duration = median of original duration of all "Not Started" and "In Progress" activities.</li><li>In instances where the actual duration is available, the original duration is omitted to prioritize reliance on the actuals, which offer greater accuracy. For  schedule predictability, please refer the planned versus actuals line graphs.</li><li>Please note that the count of actual activities within a specific duration bucket may not necessarily correspond to the planned activities within the same duration bucket.</li></ol>'}
                    info={'<ol class="px-3"><li>Planned median duration = median of original duration of all "Not Started" and "In Progress" activities.</li><li>In instances where the actual duration is available, the original duration is omitted to prioritize reliance on the actuals, which offer greater accuracy. For  schedule predictability, please refer the planned versus actuals line graphs.</li></ol>'}
                    onClick={plotRefreshOnclick}
                    downloadSelectorClass={'plot-wrapper'}
                  />
                  {/* <StackedBarchart
                    key={"activeduration"}
                    data={actualDurationGraphData}
                    stackedBarChartFIlterCallback={stackedBarChartFilterOnCall}
                    type={"plannie-actvityduration"}
                    dataType="primavera"
                  /> */}
                  <div className="d-flex plot-wrapper">
                      <GroupedStatckedBarPlot data={actualDurationGraphData} groupedStackedBarPlotFilerCallback={stackedBarChartFilterOnCall} dataType="primavera" />
                      <BoxPlot data={durationboxPlotData} boxPlotFilerCallback={stackedBarChartFilterOnCall} yLabelText={"Duration (in Days)"} drawmedian={true} boxPlotName={'duration-boxplot'}/>
                  </div>
                </div>
                {loading ? <RaceSpinner /> : null}
              </div>
              <div
                className="tab-pane fade graph show"
                id="floatAnalysisGraph"
                role="tabpanel"
                aria-labelledby="floatAnalysis-tab"
              >
                <div className="f-screen bg-white">
                  <PlotMenu
                    showRefreshBtn={true}
                    title="Clear Selection"
                    customClass="cost-c-btn"
                    fullscreenSelector={"floatAnalysisGraph"}
                    info={"Float Analysis"}
                    onClick={plotRefreshOnclick}
                    clearOnClick={clearBtnOnClick}
                    downloadSelectorClass={'plot-wrapper'}
                  />
                  <div className="d-flex plot-wrapper">
                    <div>
                      <StackedBarchart
                        key={"floatGraph"}
                        data={floatGraphData}
                        stackedBarChartFIlterCallback={stackedBarChartFilterOnCall}
                        type={"plannie-float"}
                        dataType="primavera"
                      />
                    </div>
                    <BoxPlot data={floatboxPlotData} boxPlotFilerCallback={stackedBarChartFilterOnCall} yLabelText={"Total Float"} drawmedian={false} boxPlotName={'float-boxplot'}/>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade graph show"
                id="mostCommonGraph"
                role="tabpanel"
                aria-labelledby="mostCommon-tab"
              >
                <div className="p-2 f-screen bg-white">
                  <PlannieList data={mostCommonData}></PlannieList>
                  {/* <h3>Most Common Predecessors and Successors</h3> */}
                </div>
              </div>
              {/* <div className="tab-pane fade graph" id="moccost" role="tabpanel" aria-labelledby="moccost-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="moc-cost-bar-c-btn" fullscreenSelector={"moccost"}
                                        clearOnClick={null} info={plotInformation.eMoC.moccost}/>
                                    <StackedBarchartNegative key={'moccost'} data={plotdata["cost"]} stackedBarChartFIlterCallback={stackedBarChartFilterOnCall} dataType={'emoc'} type={"moccost"} />
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="quality-results-container p-2">
        {/* {alertMessage(planniedata.n_exact_results,  planniedata.Plannie_id.length, "Quality")} */}
        <div className="d-flex justify-content-between mt-3 mb-2 small">
          <div className="text-monospace count-info">
            Showing top&nbsp;
            <span className="quality-result-count">
              {resultCount}
              {/* {planniedata.primavera.Activity_ID.length} */}
            </span>{" "}
            result(s) from {uniqueProjectCount} Project(s)
          </div>
          <div className="d-flex">
            {/* <GenerateReport data={[]} type={'quality'} /> */}
            <div className="mr-3">
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="cardToGrid" onChange={gridLayoutOnChange} />
                <label className="custom-control-label" htmlFor="cardToGrid">{isCardView ? "Card View": "Grid View"}</label>
              </div>
            </div>
            <div className='icon-wrap download'>
              <button className='btn btn-red rounded-0 p-1' onClick={downloadSearchResultOnClick}>
                <img src={download} alt='Download'></img>
                &nbsp;Download Search Results
              </button>
            </div>
          </div>
        </div>
        <div className="search-results-inner">
          {planniedata.primavera !== null &&
            planniedata.primavera.Activity_ID.length > 0
            ? planniedata.plannieFormatted.map((plannieRowData) => {
              return (
                <PlannieResultCard isCardView={isCardView}
                  key={plannieRowData.Index}
                  dataType={"plannie"}
                  dataId={plannieRowData.Index}
                  dataActivityId={plannieRowData.Activity_ID}
                  dataActivityName={plannieRowData.Activity_Name}
                  dataActivityStatus={plannieRowData.Activity_Status}
                  dataActivityType={plannieRowData.Activity_Type}
                  dataCalender={plannieRowData.Calender}
                  dataCritical={plannieRowData.Critical}
                  dataPredecessors={plannieRowData.Predecessors}
                  dataSuccessors={plannieRowData.dataSuccessors}
                  dataWbs={plannieRowData.Wbs}
                  dataWbsName={plannieRowData.Wbs_Name}
                  dataWbsPath={plannieRowData.Wbs_Path}
                  dataDesc={"Dummy"}
                  dataSummary={"Summary"}
                  // dataCharacterstics={quality.characteristics}
                  // dataDisciplineFunc={quality.discipline_function}
                  dataRegion={plannieRowData.Region}
                  dataLoB={plannieRowData.LOB}
                  dataCountry={"India"}
                  dataProjectName={plannieRowData.Project_Name}
                  dataProjectCode={plannieRowData.Project_Code}
                  dataprojectdate={plannieRowData.Project_Data_Date}
                  dataIs_Exact_Match={plannieRowData.Is_Exact_Match}
                  dataPrimaryKey={plannieRowData.PrimaryKey}
                  dataTags={[
                    {
                      labelName: "Project Name",
                      value: plannieRowData.Project_Name,
                    },
                    {
                      labelName: "Activity Name",
                      value: plannieRowData.Activity_Name,
                    },
                    {
                      labelName: "Activity Type",
                      value: plannieRowData.Activity_Type,
                    },
                    {
                      labelName: "Activity Status",
                      value: plannieRowData.Activity_Status,
                    },
                    // { labelName: "WBS ID", value: plannieRowData.Wbs },
                    { labelName: "WBS Name", value: plannieRowData.Wbs_Name },
                    { labelName: "Calendar", value: plannieRowData.Calender },
                    { labelName: "Critical", value: plannieRowData.Critical },
                    // { labelName: "Predecessors Count", value: plannieRowData.Predecessors_Count },
                    // { labelName: "Successors Count", value: plannieRowData.Successors_Count },
                    // { labelName: "Total Float", value: "-" },
                    // {
                    //   labelName: "Activity Duration variance",
                    //   value: plannieRowData.Activity_Duration_Variance === "" ? "-" : plannieRowData.Activity_Duration_Variance,
                    // },

                    {
                      labelName: "Original Duration",
                      value: plannieRowData.Activity_Status === "Completed"? "-" : plannieRowData.Original_Duration,
                    },
                    {
                      labelName: "Actual Duration",
                      value: plannieRowData.Actual_Duration === "" ? "-" : plannieRowData.Actual_Duration,
                    },
                    {
                      labelName: "Free Float",
                      value: plannieRowData.Free_Float === "" ? "-" : plannieRowData.Free_Float,
                    },
                    {
                      labelName: "Total Float",
                      value: plannieRowData.Total_Float === "" ? "-" : plannieRowData.Total_Float,
                    },
                    {
                      labelName: "Predecessor Name",
                      value: plannieRowData.Predecessor_Names
                    },
                    {
                      labelName: "Successor Name",
                      value: plannieRowData.Successor_Names
                    }
                  ]}
                setModalData={setPromptmodal}
                // addToReportOnChange={()=>{}}
                // mostRelevantOnChange={()=>{}}
                // notRelevantOnChange={()=>{}}
                // associationsOnClick={()=>{}}
                mostRelevantList={planniedata.primavera.most_relevants}
                notRelevantList={planniedata.primavera.not_relevants}
                // globalMostRelevantList={[]}
                // globalNotRelevantList={[]}
                // constkey={valueConst}
                />
              );
            })
            : null}
        </div>

        <div className="mt-4 mx-2">
          {/* <UserDataGrid /> */}
          {/* <PlannieResultGrid   planniedata={planniedata} /> */}
        </div>
        <div>
          {/* <DataGrid
            columns={gridColumns}
            rows={createRows}
            onRowsChange={onGridRowUpdate}
            noRowsFallback={<EmptyRowsRenderer />}
            defaultColumnOptions={{
              sortable: false,
              resizable: true,
            }}
          /> */}
        </div>
      </div>
      <PromptModal open={promptmodal.open} modalSize="modal-lg" onClose={onPromptModalClose} showContinue={false} showHeader={true} showCloseButton={true}>{promptmodal.message}</PromptModal>
    </div>
  );
}
export default Plannie;
