import React from 'react';
import UserProfile from '../user-profile';
import LogoBranding from '../logo-branding';

function PlannieAccessDenied(props) {
    const state = props.location.state;
    console.log(state)
    return (
        <div>
            <div style={{ position: 'absolute', left: '25px', top: '20px', zIndex: 1 }}>

                <LogoBranding />
            </div>

            <div style={{ position: 'absolute', right: '25px', top: '20px', zIndex: 1 }}>

                <UserProfile isSuperUser={false} otheruser={false} />
            </div>
            <div className="container-fluid">
                <div className="plannie-container">
                    {
                        'accessUnderProcess' in state && state.accessUnderProcess ?
                            <p>
                                Your request for access has been received. Please wait till the permission granted.
                            </p>
                            :
                            <>
                                <p className="plannie-message">You do not have access to this Application.</p>
                                <p>Please send an email to the admin to request access.</p>
                                <a href="mailto:GXSTC-BProActSupport@shell.com?subject=Request for Plannie User Access" className="plannie-email">GXSTC-BProActSupport@shell.com</a>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default PlannieAccessDenied
