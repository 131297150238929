import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from 'react-data-grid';
import { EmptyRowsRenderer } from './user-data-grid';
import RaceSpinner from '../spinner';
import utils from '../app-util';
import { toast } from 'react-toastify';
const HEADER_CLASSNAME = "filter-cell";

function ApproveRejectButton(props) {
    const [approveinfo, setApproveinfo] = useState({ inProgress: false, isApproved: props.value.row.permission_given });
    const [rejectinfo, setRejectinfo] = useState({ inProgress: false, isRejected: false });
    function onClick(e) {
        props.onApprove({ event: e, approveinfo: approveinfo, setApproveinfo: setApproveinfo });
    }
    function onRejectClick(e) {
        props.onReject({ event: e, rejectinfo: rejectinfo, setRejectinfo: setRejectinfo });
    }
    return (
        <div className='react-grid-action-btn'>
            {
                !approveinfo.isApproved ?
                    <button type='button' className='btn btn-sm btn-red mr-1' title={props.title || "Approve"} onClick={onClick}>
                        {
                            approveinfo.inProgress ?
                                <div className='Toastify__spinner position-relative' style={{ width: "10px", height: "10px" }}></div>
                                : (props.approveTextContent || "")
                        }
                    </button>
                    :
                    <div className='success-white-tickmark'>&#x2714;</div>
            }
            {
                !rejectinfo.isRejected &&  !approveinfo.isApproved ?
                <button type='button' className='btn btn-sm btn-red' title={props.title || "Reject"} onClick={onRejectClick}>
                    {
                        rejectinfo.inProgress ?
                            <div className='Toastify__spinner position-relative' style={{ width: "10px", height: "10px" }}></div>
                            : (props.rejectTextContent || "")
                    }
                </button> : null
            }
        </div>
    )
}

const isacceptedFormatter = ({ row }) => {
    return (
        <>{row.isaccepted ? 'Yes' : 'No'}</>
    );
}
const bizJustificationFormatter = ({ row }) => {
    return (
        <span title={row.bizjustification}>{row.bizjustification}</span>
    );
}
function accessRequestColumnFormat(rows, setRows, approveUsers) {
    return [
        { key: 'email', name: 'Email', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 'linemanager', name: 'Line Manager', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 'staff', name: 'Staff', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 'isaccepted', name: 'Is Accepted?', headerCellClass: HEADER_CLASSNAME, renderCell: isacceptedFormatter, editable: false },
        { key: 'business', name: 'Business', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 'project_support', name: 'Project Supports', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 'bizjustification', name: 'Business Justification', headerCellClass: HEADER_CLASSNAME, renderCell: bizJustificationFormatter, editable: false },
        { key: 'requested_on', name: 'Requested Date', headerCellClass: HEADER_CLASSNAME, editable: false },
        {
            key: 'action', name: 'Actions', sortable: false, editable: false,
            renderCell(objProp) {
                return (
                    <ApproveRejectButton approveTextContent="Approve" rejectTextContent="Reject" value={objProp}
                        onApprove={(args) => {
                            approveUsers(rows, setRows, objProp, args);
                        }}
                        onReject={(args) => {
                            rejectUser(rows, setRows, objProp, args);
                        }}
                    />
                );
            }
        }
    ];
}
function rejectUser(rows, setRows, objProp, args) {
    const selectedIndex = rows.findIndex(function (item) {
        return item.email === objProp.row.email;
    });
    const selectedRow = rows[selectedIndex]
    const dataSelected = {
        email: selectedRow.email
    }
    args.setRejectinfo({ ...args.rejectinfo, inProgress: true });
    fetch('/plannie-reject', {
        method: "DELETE",
        headers: { "X-CSRFToken": utils.csrf(), 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: JSON.stringify(dataSelected)
    })
        .then(function (response) {
            if (response.status !== 200) {
                args.setRejectinfo({ isRejected: false, inProgress: false });
                utils.checkSessionStatus(response);
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    args.setRejectinfo({ isRejected: true, inProgress: false });
                    setRows([...rows.slice(0, selectedIndex), ...rows.slice(selectedIndex + 1)]);
                } else {
                    args.setRejectinfo({ isRejected: false, inProgress: false });
                }
            });
        }).catch(function (err) {
            args.setRejectinfo({ isRejected: false, inProgress: false });
            console.log(err);
        });
}
function approveUser(rows, _setRows, objProp, args) {
    const selectedIndexForApprove = rows.findIndex(function (item) {
        return item.email === objProp.row.email;
    });
    const selectedRow = rows[selectedIndexForApprove]
    const dataPL = {
        email: selectedRow.email,
    }
    args.setApproveinfo({ ...args.approveinfo, inProgress: true });
    fetch('/plannie-approve', {
        method: "POST",
        headers: { "X-CSRFToken": utils.csrf(), 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: JSON.stringify(dataPL)
    })
        .then(function (response) {
            if (response.status !== 200) {
                args.setApproveinfo({ isApproved: false, inProgress: false });
                utils.checkSessionStatus(response);
                toast.error("Failed to approve");
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    args.setApproveinfo({ isApproved: true, inProgress: false });
                    //Refresh user table to populate new user
                    document.querySelector('.user-table-refresh').click();
                } else {
                    args.setApproveinfo({ isApproved: false, inProgress: false });
                }
            });
        }).catch(function (err) {
            args.setApproveinfo({ isApproved: false, inProgress: false });
        });
}
export default function AccessRequestGrid() {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        setLoading(true);
        fetch('/plannie-access-requests-detail', {
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    setLoading(false);
                    utils.checkSessionStatus(response);
                    console.log('Error Status Code: ' + response.status);
                    return;
                }
                response.json().then(function (data) {
                    // Sort the data to be grid ready by displaying the not approved users at first
                    data = data.sort((a, b) => a['permission_given'] - b['permission_given']);
                    setRows(data);
                    setLoading(false);
                });
            }).catch(function (err) {
                setLoading(false);
                console.log(err);
            });
    }, []);
    const gridColumns = useMemo(() => {
        return accessRequestColumnFormat(rows, setRows, approveUser);
    }, [rows]);

    function onGridRowUpdate() {

    }
    return (
        <div className='p-2 access-request-grid' key={"a-r-g"}>
            <DataGrid rowKeyGetter={(row) => row.email} columns={gridColumns} rows={rows} onRowsChange={onGridRowUpdate} noRowsFallback={<EmptyRowsRenderer />}
            />
            {loading ? <RaceSpinner /> : null}
        </div>
    );
}
