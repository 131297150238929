import { useState, useEffect } from "react";

export default function PromptModal({
    open,
    title = '',
    showHeader = false,
    showCloseButton = false,
    showContinue = true,
    onClose,
    modalSize='modal-sm',
    actionButtnonClick,
    children
}) {
    const [display, setDisplay] = useState(open);
    useEffect(()=>{
        setDisplay(open);
    }, [open])
    function handleBtnClick(e) {
        actionButtnonClick(e);
    }
    function onPromptClose(e){
        onClose(e);
        setDisplay(false);
    }
    return (
        <>
            <div className={display ? 'modal show fade eog-modal d-block' : 'modal fade eog-modal'} tabIndex="-1">
                <div className={`modal-dialog ${modalSize}`}>
                    <div className="modal-content">
                        {
                            showHeader ?
                                <div className="modal-header">
                                    <h5 className="modal-title">{title}</h5>
                                    <button type="button" className="btn-close" aria-label="Close" onClick={onPromptClose}>x</button>
                                </div>
                                :
                                null
                        }
                        <div className="modal-body">
                            {
                                children
                            }
                        </div>
                        <div className="modal-footer">
                            {
                                showCloseButton ?
                                    <button type="button" className="btn btn-sm btn-secondary" onClick={onPromptClose}>Close</button>
                                    :
                                    null
                            }
                            {
                                showContinue ? <button type="button" className="btn btn-sm btn-red" onClick={handleBtnClick}>Continue</button> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={display ? 'modal-backdrop fade show' : 'modal-backdrop fade d-none'}></div>
        </>
    );
}

export function Message({
    remaining,
    seconds
}) {
    return (
        <p>Your session is idle. Logging out in {remaining} {seconds}</p>
    )
}
