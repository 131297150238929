import { LoginUtils } from "./login";
import { toast } from "react-toastify"; 

const utils = (function () {
    const __csrf_token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const logo = '/shell_pecten.png'
    const elementSelectors = {
        SR_CARD_SELECTOR: ".search-results .card", FILTER_LIST_INP: ".risk-sidebar .filter-list input", SEARCH_FIELD_SELECTOR: '.search-input-field',
        CHIP_LIST_SELECTOR: '.advanced-search-wrapper .dropdown-filtering.active .chiplist .chip', MUST_INCLUDE_SELECTOR: ".must-include"
    }
    // Removes deplicate of an array
    function removeDuplicate(arr) {
        return arr.filter(function (item, pos) {
            return arr.indexOf(item) === pos;
        });
    }

    const getUinqueRandomNumberList = function (count, max) {
        const list = new Set();
        while (list.size < count) {
            list.add(Math.floor(Math.random() * max) + 1)
        }
        return [...list];
    }
    /**
     * It create a static key by taking user enteresd search keyword for the result rendering for the purpose of key integration, 
     * this helps rerendering of rsults where checkboxes not getting checked if similar result comes on the next search result.
     * @returns - A static string made from search inputs
     */
    function createKeyFromSearchKeyWord(){
        const mustIncl = document.querySelector('.must-include');
        let query = sessionStorage.getItem('query');
        query = query !== null ? query : "";
        return `#${query.replace(/\s|,|\//g, '_')}${mustIncl !== null ? mustIncl.value : ""}${Date.now()}`
    }
    function parentContainsChild(parent, child) {
        return (parent !== child && parent.contains(child));
    }
    // Check an element contains the passed class
    function hasClass(element, param) {
        return element.classList.contains(param.split(' ').join('_'));
    }

    // Clear the filter status of heatmap
    function clearHeatMapFilter() {
        const highlightedRect = document.querySelector("#heatmap rect.rect.clicked");
        if (highlightedRect !== null) {
            highlightedRect.classList.remove("clicked");
        }
    }

    function formatData(result, getCountryFlag) {
        const newFormat = [];
        if (result.risk !== undefined && Object.keys(result.risk).length > 1) {
            for (let i = 0; i < result.risk.risk_id.length; i++) {
                newFormat.push({
                    risk_id: result.risk.risk_id[i],
                    country: result.risk.risk_loc[i],
                    project: result.risk.risk_org[i],
                    title: result.risk.risk_title[i],
                    desc: result.risk.risk_desc[i],
                    subtitle: result.risk.risk_desc[i].slice(0, 150),
                    category: result.risk.risk_L1[i],
                    lob: result.risk.lob[i],
                    risk_L0: result.risk.risk_L0[i],
                    risk_L1: result.risk.risk_L1[i],
                    risk_L2: result.risk.risk_L2[i],
                    phy_L2: result.risk.phy_L2[i],
                    phy_L3: result.risk.phy_L3[i],
                    phy_L4: result.risk.phy_L4[i],
                    severity: result.risk.risk_severity[i],
                    flag: `${getCountryFlag(result.risk.risk_loc[i])}`,
                    risk_modified: result.risk.risk_created_modified_date[i] !== "" ?
                        Date.parse(result.risk.risk_created_modified_date[i]) : result.risk.risk_created_modified_date[i]
                })
            }
            result.riskFormatted = newFormat;
            return result;
        } else {
            return [];
        }
    }
    function getSelectedAdvanced() {
        const filterItems = { projects: [], countries: [], lobs: [], selectedDropdown: '' };
        const filterkeys = document.querySelectorAll(elementSelectors.CHIP_LIST_SELECTOR);
        filterkeys.forEach(function (chip) {
            if (chip.dataset.type === "projects") {
                filterItems.projects.push(chip.dataset.item);
            }
            if (chip.dataset.type === "countries") {
                filterItems.countries.push(chip.dataset.item);
            }
            if (chip.dataset.type === "lobs") {
                filterItems.lobs.push(chip.dataset.item);
            }
        });
        return filterItems;
    }
    function checkSessionStatus(response, xhr = false) {
        if (response.status !== 200 && (response.status === 401 || response.status === 302 )) {
                window.location.href = '/timeout';
                window.sessionStorage.clear();
        } else if(response.status === 408 || response.status === 502){
            const msg = {
                "502": "Bad gateway, please try again",
                "408": "Request timed out"
            }
            toast.warn(msg[response.status]);
        }
        if (!xhr) {
            return response.json();
        } else {
            return response;
        }
    }
    function showCBoxLoader(element) {
        element.classList.add("activate-loader");
    }
    function hideCBoxLoader(element) {
        element.classList.remove("activate-loader");
    }
    /**
     *
     * @param {Array} dateList The date list to be processed to get the min and max date
     * @returns {Array} returns the min and max date as list [minDate, maxDate]
     */
    function getMinMaxFromDateList(dateList) {
        const temp = dateList.filter(function (value) {
            return (value !== "");
        }).map((value) => {
            return new Date(value)
        });
        const min = new Date(Math.min.apply(null, temp)), max = new Date(Math.max.apply(null, temp));
        return [min, max];
    }
    function isBetweenDate(date, startDate, endDate) {
        return (date >= startDate && date <= endDate);
    }
    function clearBarChartHighlight() {
        document.querySelector('svg#plannie-actvityduration-plot').classList.remove('clickactive');
        document.querySelector('svg#plannie-float-plot').classList.remove('clickactive');
    }
    const statess = (function (states) {
        states.state = {};
        let _value = null, _et = null;
        states.state.getState = function () {
            return _value;
        }
        states.state.setState = function (val) {
            _value = val;
        }
        states.state.getET = function () {
            return _et;
        }
        states.state.setET = function (val) {
            _et = val;
        }
        return states;
    })({})
    function enable(btnDownload, btnDownloadText) {
        if (btnDownload !== null) {
            btnDownload.disabled = false;
            btnDownload.textContent = btnDownloadText;
        }
    }
    function disable(btnDownload, _btnDownloadText) {
        if (btnDownload !== null) {
            btnDownload.disabled = true;
            btnDownload.textContent = "Downloading...";
        }
    }
    function makeDownloadRequest(url, reportName, btnDownload = null, btnDownloadText = "") {
        disable(btnDownload, btnDownloadText);
        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    checkSessionStatus(response);
                    enable(btnDownload, btnDownloadText);
                    console.log('Error Status Code: ' + response.status);
                    return;
                }
                response.blob().then(function (blob) {
                    const blobO = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    const objUrl = window.URL.createObjectURL(blobO);
                    const a = document.createElement("a");
                    a.setAttribute('href', `${objUrl}`);
                    a.setAttribute('download', `${reportName}.xlsx`);
                    a.setAttribute('target', '_blank');
                    a.style.display = 'none';
                    document.body.append(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(objUrl);
                    enable(btnDownload, btnDownloadText);
                });
            }).catch(function (err) {
                enable(btnDownload, btnDownloadText);
                console.log('Fetch Error :-S', err);
            });
    }
    function getFixedPlotDimension() {
        const element = document.querySelector("#activityCountGraph");
        return { width: element.clientWidth, height: element.clientHeight };
    }
    function formatEmocData(result) {
        const newFormat = [];
        if (result !== undefined && Object.keys(result).length > 1) {
            for (let i = 0; i < result.emoc_id.length; i++) {
                newFormat.push({
                    emoc_id: result.emoc_id[i],
                    emoc_Functional_L0: result.emoc_Functional_L0[i],
                    emoc_title: result.emoc_title[i],
                    emoc_desc: result.emoc_desc[i],
                    emoc_mode_of_change: result.emoc_mode_of_change[i],
                    emoc_desc_summary: result.emoc_desc_summary[i],
                    emoc_org: result.emoc_org[i],
                    emoc_loc: result.emoc_loc[i],
                    lob: result.lob[i],
                    emoc_created_modified_date: result.emoc_created_modified_date[i] !== "" ?
                        Date.parse(result.emoc_created_modified_date[i]) : result.emoc_created_modified_date[i]
                })
            }
            result.emocFormatted = newFormat;
            result.moc_big_rule.isNew = true;
            result.pie_changes_plot.isNew = true;
            result.pie_trends_plot.isNew = true;
            result.cost_graph.isNew = true;
            return result;
        } else {
            return [];
        }
    }
    function getVisibleResultIDs(storageName, visibleSelector, hiddenSelector, datasetName='id') {
        const visibleElement = document.querySelectorAll(visibleSelector),
            hiddenElement = document.querySelectorAll(hiddenSelector);
        if (visibleElement.length === 0 && hiddenElement.length === 0) {
            return sessionStorage.getItem(storageName);
        } else {
            const Ids = [];
            visibleElement.forEach(function (elem) {
                Ids.push(elem.dataset[datasetName]);
            });
            return JSON.stringify(Ids);
        }
    }
    function getVisibleRiskResultIDs() {
        const visibleRiskElement = document.querySelectorAll("#risk .search-results-inner .search-item.d-block"),
            hiddenRiskElement = document.querySelectorAll("#risk .search-results-inner .search-item.d-none");
        if (visibleRiskElement.length === 0 && hiddenRiskElement.length === 0) {
            return sessionStorage.getItem('indices');
        } else {
            const riskids = [];
            visibleRiskElement.forEach(function (elem) {
                riskids.push(elem.dataset.id);
            });
            return JSON.stringify(riskids);
        }
    }
    function getVisibleEMoCResultIDs() {
        const visibleEMOCElement = document.querySelectorAll(".emoc-item.d-block"),
        hiddenEMOCElement = document.querySelectorAll(".emoc-item.d-none");
        if(visibleEMOCElement.length === 0 && hiddenEMOCElement.length === 0){
            return sessionStorage.getItem('indicesemoc');
        }else{
            const emocids = [];
            visibleEMOCElement.forEach(function(elem){
                emocids.push(elem.dataset.id);
            });
            return JSON.stringify(emocids);
        }
    }
    function formatGraphData(gData, groups=["Technical", "Economic", "Commercial", "Organisational", "Political"], dataType="risk") {
        const data = gData.data;
        if (Object.keys(data).length > 0) {
            const formatted = [], newFormat = {};
            for (let i = 0; i < data[groups[0]].length; ++i) {
                const temp = {};
                temp['label'] = gData.x_tick_labels[i];
                for (const val of groups) {
                    temp[val] = data[val][i]
                }
                temp[`${dataType}_ids`] = gData[`data_${dataType}_id`]
                formatted.push(temp);
            }
            newFormat.data = formatted;
            newFormat.groups = groups;
            newFormat.x_label = gData.x_label
            newFormat.y_label = gData.y_label
            newFormat.isNew = true;
            return newFormat;
        }else {
            return {};
        }
    }

    function pageView(url) {
        fetch(url, {
            headers: { "X-CSRFToken": __csrf_token, 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    console.log('Error Status Code: ' + response.status);
                    return;
                }
                response.json().then(function (_data) {
                    console.log('Counted');
                });
            }).catch(function (err) {
                console.log('Fetch Error :', err);
            });
    }
    function formatQualityData(result, getCountryFlag) {
        const newFormat = [];
        if (result !== undefined && Object.keys(result).length > 1) {
            for (let i = 0; i < result.quality_id.length; i++) {
                newFormat.push({
                    quality_id: result.quality_id[i],
                    country: result.quality_loc[i],
                    quality_org: result.quality_org[i],
                    quality_title: result.quality_title[i],
                    quality_desc: result.quality_desc[i],
                    quality_summary: result.quality_summary[i],
                    subtitle: result.quality_desc[i].slice(0, 150),
                    lob: result.quality_lob[i],
                    characteristics: result.quality_characteristics[i],
                    equipment_type: result.quality_equipment_type[i],
                    discipline_function: result.quality_discipline_function[i],
                    supplier_parent: result.quality_supplier_parent[i],
                    supplier_location: result.quality_supplier_location[i],
                    flag: `${getCountryFlag(result.quality_loc[i])}`,
                    quality_modified: result.quality_created_modified_date[i] !== "" ?
                        Date.parse(result.quality_created_modified_date[i]) : result.quality_created_modified_date[i],
                    quality_severity: result.quality_severity[i]
                })
            }
            result.qualityFormatted = newFormat;
            result.heat_map_graph.isNew = true;
            result.process_safety_graph.isNew = true;
            result.root_cause_graph.isNew = true;
            result.mrf_graph.isNew = true;
            result.cost_graph.isNew = true;
            return result;
        } else {
            return [];
        }
    }
    return {
        csrf: function () {
            return __csrf_token;
        },
        logo: logo,
        parentContainsChild: parentContainsChild,
        removeDuplicate: removeDuplicate,
        elementSelectors: elementSelectors,
        hasClass: hasClass,
        clearHeatMapFilter: clearHeatMapFilter,
        formatData: formatData,
        getSelectedAdvanced: getSelectedAdvanced,
        checkSessionStatus: checkSessionStatus,
        showCBoxLoader: showCBoxLoader,
        hideCBoxLoader: hideCBoxLoader,
        getMinMaxFromDateList: getMinMaxFromDateList,
        isBetweenDate: isBetweenDate,
        clearBarChartHighlight: clearBarChartHighlight,
        states: statess,
        makeDownloadRequest: makeDownloadRequest,
        getFixedPlotDimension: getFixedPlotDimension,
        formatEmocData: formatEmocData,
        getVisibleRiskResultIDs: getVisibleRiskResultIDs,
        getVisibleEMoCResultIDs: getVisibleEMoCResultIDs,
        getVisibleResultIDs: getVisibleResultIDs,
        formatGraphData: formatGraphData,
        pageView: pageView,
        formatQualityData:formatQualityData,
        createKeyFromSearchKeyWord: createKeyFromSearchKeyWord,
        getUinqueRandomNumberList: getUinqueRandomNumberList
    };
})();
export default utils;
