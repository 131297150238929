import React from 'react';
import LogoBranding from '../logo-branding';

function PlannieLogout() {
  
    return (
        <div>
        <div style={{ position: 'absolute', left: '25px', top: '20px', zIndex: 1 }}>
        
        <LogoBranding />
    </div>
           
        <div style={{ position: 'absolute', right: '25px', top: '20px', zIndex: 1 }}>
        

        </div>
        <div className="container-fluid">
        <div className="plannie-container">
        <p className="plannie-message">You have been logged out !!</p>
        <p>Please click the below link to login back.</p>
        <a className="link" href="/">Login</a>
    </div> 
    </div>
    </div>
    );



}

export default PlannieLogout
