import React from 'react';
import chevronDown from '../../assets/chevron_down.svg';
import filter from '../../assets/filter.svg';
import utils from '../app-util';
import DateFilter from '../date-filter';
// import QulityFilters from '../qualityComponent/QualityFilters';
import PlannieFilters from './plannie-filters';
import { clearFilterSelection } from './plannie-FilterUtils';

function clearBarChartHighlight() {
    // document.querySelector('svg#plannie-actvityduration-plot').classList.remove('clickactive');
    document.querySelector('svg#plannie-float-plot').classList.remove('clickactive');
}

function PlannieSidebarMenu(props) {
    const items = props.items;
    const count = props.count;
    function clearAllOnClick() {
        if (props.dataType === 'plannie') {
            clearBarChartHighlight();
            // const plannieDropdownClear = document.querySelectorAll('.MuiAutocomplete-clearIndicator');
            // if (plannieDropdownClear !== null) {
            //     plannieDropdownClear.forEach((el) => {
            //         el.click();
            //     });
            // }
            clearFilterSelection()
            props.clearTopNFilters();
            document.querySelector("#fullmatch").checked = false;
        }
    }

    return (
        <div className={`app-bg-color plannie-sidebar ${props.dataType}-sidebar`}>
            <div>
                <div className="d-flex justify-content-between p-1 component-title">
                    <span className="filter c-title"><img alt="" src={filter} />&nbsp;Filter By</span>
                    <span className={`clearall tour-clearall clearall-${props.dataType}`} onClick={clearAllOnClick}
                        style={{ cursor: "pointer" }} title="Clear Filters">Clear All</span>
                </div>


                {

                    <PlannieFilters dropdowns={props.data.dropdowns} dates={props.data.dates}
                        filterOnChange={props.filterOnChange}
                        // LOB={props.data.LOB}
                        // Region={props.data.Region}
                        setResultCount={props.setResultCount}
                        isClearAllClicked={props.isClearAllClicked}
                        data={props.dataMainFormatted}
                    />

                }
            </div>
        </div>
    );
}

export default PlannieSidebarMenu
