import { useState } from "react";
import { TablePagination } from "@mui/material";

export default function Pagination({ paginationData, loadData }) {
    const [rowsPerPage, setRowsPerPage] = useState(paginationData ?? 20);
    const initialPage = paginationData ?? 0;
    const [page, setPage] = useState(initialPage);
    function handleChangePage(event, newPage) {
        loadData(event, newPage + 1, rowsPerPage);
        setPage(newPage);
    }
    function handleChangeRowsPerPage(event) {
        const val = parseInt(event.target.value, 10)
        setRowsPerPage(val);
        setPage(0);
        // Page is 1 because TablePagination component consider 0-based page for indexing
        loadData(event, 1, val);
    }
    return (
        <div className="pagination-wrapper">
            {
                paginationData !== null && paginationData !== undefined ?
                    <TablePagination
                        component="div"
                        count={paginationData.total}
                        page={page}
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : null
            }
        </div>
    );
}
