import React, { useState, useEffect } from 'react';
import utils from '../app-util';
import addUser from '../../assets/addUser.svg';
import { toast } from 'react-toastify';
let checkedlists = null;
function autoCheckLob(_e, excludelist, checked, disabled) {
    for (const elem of checkedlists) {
        if (!excludelist.includes(elem.name)) {
            elem.checked = checked;
            elem.disabled = disabled;
        }
    }
}
function enableCheckBoxAfterSubmit() {
    for (const elem of checkedlists) {
        if (elem.disabled) {
            elem.disabled = false;
        }
    }
}

function superUserOnChange(e) {
    if (e.target.checked) {
        autoCheckLob(e, ["super_user"], true, false);
    } else {
        autoCheckLob(e, ["super_user"], false, false);
    }
}
function lobDSNEOnChange(e) {
    // As per the latest addition in the feature, user has requested for Downstream should have also access to New Energies and vice-versa
    if (e.target.checked) {
        autoCheckLob(e, [ "super_user"], true, false);
    }else{
        autoCheckLob(e, ["app_owner"], false, false);
    }
}

function lobOnChange(_e, lobOn = false) {
    if (lobOn) {
        //Auto select SU if all Lobs selected(This feature is deprected due to the requirement changes of user role, i.e. user has access to all LoB may not be a super user)
        const lobs = document.querySelectorAll('.lob');
        const checkedLobsLength = document.querySelectorAll('.lob:checked').length;
        if (lobs.length === checkedLobsLength) {
            document.querySelector('#superUser').checked = true;
            lobs.forEach(function (elem) {
                elem.disabled = true
            });
        }
    }
}

function adminOnChange(e) {
    if (e.target.checked) {
        autoCheckLob(e, ["admin", "app_owner"], true, true);
    } else {
        autoCheckLob(e, ["admin", "app_owner"], false, false);
    }
}
function ownerOnChange(e) {
    if (e.target.checked) {
        autoCheckLob(e, ["app_owner"], true, true);
    } else {
        autoCheckLob(e, ["app_owner"], false, false);
    }
}
function validateEmailAddress(emailAddress) {
    const atSymbol = emailAddress.indexOf("@");
    const dotSymbol = emailAddress.lastIndexOf(".");
    const spaceSymbol = emailAddress.indexOf(" ");
    const isAtDotSymbol = (atSymbol !== -1) && (atSymbol !== 0) && (dotSymbol !== -1) && (dotSymbol !== 0) ;
    return (isAtDotSymbol && (dotSymbol > atSymbol + 1) && (emailAddress.length > dotSymbol + 1) && (spaceSymbol === -1));
}
function isValidMail(value) {
    let isValid = false;
    
    if (validateEmailAddress(value) && value.endsWith("@shell.com")) {
        isValid = true
    }
    
    return isValid;
}
function openPopup(value) {
    return `modal user-form-modal ${value ? "show" : ""}`
}
function payload(checkedlistsElem) {
    const values = {};
    const uemail = document.getElementById("userEmail").value.toLowerCase();
    values["email"] = uemail.toLowerCase();
    let atleastOneCheked = false;
    for (let i = 0; i < checkedlistsElem.length; i++) {
        values[checkedlistsElem[i].name] = checkedlistsElem[i].checked;
        if (checkedlistsElem[i].checked) {
            atleastOneCheked = true;
        }
    }
    return [uemail, values, atleastOneCheked];
}
function PlannieUserFormPopup(props) {
    const [open, setOpen] = useState(props.show);

    const [errmsgemail, setErrmsgemail] = useState("");
    useEffect(() => {
        setOpen(props.show)
    }, [props.show])
    checkedlists = document.querySelectorAll("#userPopup input[type='checkbox']")
    
    function requestCreateUser(values) {
        setErrmsgemail("");
        fetch("/create_user_plannie", {
            method: "POST",
            headers: { "X-CSRFToken": utils.csrf(), 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
            body: JSON.stringify(values)
        }).then(res => {
            return utils.checkSessionStatus(res);
        }).then((results) => {
            if (results.success) {
                enableCheckBoxAfterSubmit();
                props.afterSubmit(values);
                toast.success(results.message);
                document.querySelector('#userPopup').reset();
                setOpen(_open => false);
            } else {
                toast.warn(results.message);
                enableCheckBoxAfterSubmit();
                document.querySelector('#userPopup').reset();
                setOpen(_open => false);
            }
        }, (_err) => {
            toast.warn("Failed to add user");
        });
    }

    function formOnSubmit(e) {
        e.preventDefault();
        const [uemail, values, atleastOneCheked] = payload(checkedlists);
        if (uemail !== "") {
            setErrmsgemail("");
            if (isValidMail(uemail)) {
                requestCreateUser(values);
            } else {
                setErrmsgemail("Use correct official email address");
            }
        } else {
            setErrmsgemail("Fill the empty field");
        }
    }

    function onClose(_e) {
        setOpen(_open => false);
        props.afterClose(false);
    }
    function onKeyDown(e) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
        return false;
    }
    return (
        <>
            <div className={openPopup(open)} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div><img className='add-user mr-1' src={addUser} alt="Add User" /></div>
                            <h5 className="modal-title ml-2">Add new user</h5>
                            <div className='ml-auto align-self-center'>
                                <button type="button" className="close userpopup-close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button></div>
                        </div>
                        <div className="modal-body">
                            <form id="userPopup">
                                <div className="form-group mb-4">
                                    <label htmlFor="userEmail">Email ID</label>
                                    <input type="email" name="email" className="form-control" id="userEmail" placeholder="Enter email address" onKeyDown={onKeyDown} required />
                                    <div className="text-danger">{errmsgemail}</div>
                                </div>
                                <label className="d-block">Select user special access:</label>
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" name="super_user" className="custom-control-input" id="superUser" value="super_user" onChange={superUserOnChange} />
                                    <label className="custom-control-label" htmlFor="superUser">Super User</label>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer d-flex justify-content-between px-5 pb-4">
                            <button type="button" className="btn btn-sm btn-close rounded-0" data-dismiss="modal" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-sm btn-ceate-user rounded-0"
                                style={{ color: "#ffffff" }} onClick={formOnSubmit}>Create User</button>
                        </div>
                    </div>
                </div>
            </div>
            {open ? <div className="modal-backdrop fade show" style={{ zIndex: 1040, backgroundColor: "rgba(3,3,3,0.6)" }}></div> : null}
        </>
    );
}
export default PlannieUserFormPopup;
