import { useState } from "react";
import NavHeader from "../nav-header";
import PlannieSearchQueriesGrid from './plannie-searchqueries-grid';
import PlannieMostFrequentlySearchedQueries from "./plannie-most-frequently-searched-queries";

export default function PlannieSearchQuries(props) {
    const [adminInfo] = useState({otheruser: props.location.state.otheruser, isSuperUser:props.location.state.isSuperUser});
    return (
        <div>
            <NavHeader isSuperUser={adminInfo.isSuperUser} otheruser={adminInfo.otheruser} />
            <div className="mt-4 mx-2">
                <div className="card mb-3">
                    <h6 className="card-header font-weight-bold">Top 30 most commonly searched queries</h6>
                    <div className="card-body p-1">
                        <PlannieMostFrequentlySearchedQueries />
                    </div>
                </div>
                <div className="card">
                    <h6 className="card-header font-weight-bold">All searched queries</h6>
                    <div className="card-body p-1">
                        <PlannieSearchQueriesGrid />
                    </div>
                </div>
            </div>
        </div>
    );
}