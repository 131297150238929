import { createContext, useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import Filter from 'react-data-grid';
import { FilterRenderer } from './plannie-user-data-grid';
import utils from '../app-util';
import RaceSpinner from '../spinner';
import Pagination from './pagination';


function loadSearchQueries(url, setRows, setDataPagination, setLoading) {
    setLoading(true);
    fetch(url, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
        .then(function (response) {
            if (response.status !== 200) {
                setLoading(false);
                utils.checkSessionStatus(response);
                console.log('Error Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                // Format the data to be grid ready
                const parsedData = JSON.parse(data.search_quries)
                setRows(parsedData);
                setDataPagination(data)
                setLoading(false);
            });
        }).catch(function (err) {
            setLoading(false);
            console.log('Fetch Error :', err);
        });
}
const inputHeaderFilterRenderer = (key) => {
    return (p) => (
        <>
            <FilterRenderer {...p}>
                {({ filters, ...rest }) => (
                    <input
                        {...rest}
                        className='filter-inputclass-name'
                        value={filters[key]}
                        onChange={(e) =>{
                            let obj = {};
                            obj = { ...filters }
                            obj[key] = e.target.value
                            // setFilters(obj)
                        }}
                        onKeyDown={(e)=>{e.stopPropagation()}}
                    />
                )}
            </FilterRenderer>
        </>
    );
}
export default function PlannieSearchQueriesGrid() {
    const FilterContext = createContext(Filter);
    const columns = [
        {
            key: 'id', name: "ID"
        },
        {
            key: 'userid', name: "Email"
        },
        {
            key: 'search_query', name: "Search Queries"
        },
        {
            key: 'advance_search_query', name: "Advance Search Queries"
        }
    ]
    const filterObj = {
        userid: '', search_query: '', advance_search_query: ''
    };
    const [filters, setFilters] = useState(filterObj);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [dataPagination, setDataPagination] = useState(null)
    useEffect(() => {
        loadSearchQueries('/get-search-queries-list', setRows, setDataPagination, setLoading);
    }, [])
    function handlePagination(_e, pageNum, rowsPerPage) {
        loadSearchQueries(`/get-search-queries-list/${pageNum}/${rowsPerPage}`, setRows, setDataPagination, setLoading);
    }
    return (
        <div>
            <FilterContext.Provider value={filters}>
                <DataGrid className=''
                    columns={columns}
                    rows={rows}

                />
            </FilterContext.Provider>
            <Pagination paginationData={dataPagination} loadData={handlePagination} />
            {loading ? <RaceSpinner /> : null}
        </div>
    );
}
