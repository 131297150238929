import React, { useState } from 'react';
import LogoBranding from './logo-branding';
import UserProfile from './user-profile';

export default function NavHeader(props) {
    const [isadmin] = useState(props.admin);
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light py-1">
                <LogoBranding />
                <div className="collapse navbar-collapse ml-5" id="navbarCollapse">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            {/* <a className="nav-link" href="/#" onClick={onClick}>Home<span className="sr-only">Home</span></a> */}
                        </li>

                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item text-nowrap dropdown">
                            <UserProfile isSuperUser={props.isSuperUser} otheruser={props.otheruser}/>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}
