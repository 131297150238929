import { intersection } from 'lodash';

let dataFormat = {
    LOB: [],
    Region: [],
    Project_Name: [],
    Wbs_Name: [],
    Critical: [],
    Activity_Type: [],
    Activity_Status: [],
    Index: []
}
function emptyDataFormat() {
    dataFormat = {
        LOB: [],
        Region: [],
        Project_Name: [],
        Wbs_Name: [],
        Critical: [],
        Activity_Type: [],
        Activity_Status: [],
        Index: []
    }
}
function displyCard(element) {
    element.classList.remove('d-none');
    element.classList.add('d-block');
}
function hideCard(element) {
    element.classList.add('d-none');
    element.classList.remove('d-block');
}

function getSelectedValues(selection, processParameters=['region', 'lob', 'projectname', 'wbsname', 'activitytype', 'activitystatus', 'critical']) {
    const allSelectedValues = { region: [], lob: [], projectname: [], wbsname: [], activitytype: [], activitystatus: [], critical: [] };
    const mapKey = { 'region': 'Region', 'lob': 'LOB', 'projectname': 'Project_Name', 'wbsname': 'Wbs_Name', 'activitytype': 'Activity_Type', 'activitystatus': 'Activity_Status', 'critical': 'Critical' };

    processParameters.forEach((processParam) => {
        if (selection.allSelected[processParam].length > 0) {
            selection.mainData.forEach((item) => {
                if (selection.allSelected[processParam].includes(item[mapKey[processParam]])) {
                    allSelectedValues[processParam].push(item.Index);
                }
            });
        }
    })

    return allSelectedValues;
}

function filteredData(selection, type, tobeFiltered) {
    if (selection.allSelected[type].length > 0) {
        const filtered = tobeFiltered.filter((item) => {
            return selection.allSelected[type].includes(item.dataset[type]);
        })
        filtered.forEach((item) => {
            dataFormat.Index.push(item.dataset.id);
            dataFormat.LOB.push(item.dataset.lob);
            dataFormat.Region.push(item.dataset.region);
            dataFormat.Wbs_Name.push(item.dataset.wbsname);
            dataFormat.Activity_Type.push(item.dataset.activitytype);
            dataFormat.Activity_Status.push(item.dataset.activitystatus);
            dataFormat.Project_Name.push(item.dataset.projectname);
            dataFormat.Critical.push(item.dataset.critical);
        })
    } else {
        tobeFiltered.forEach((item) => {
            dataFormat.Index.push(item.dataset.id);
            dataFormat.LOB.push(item.dataset.lob);
            dataFormat.Region.push(item.dataset.region);
            dataFormat.Wbs_Name.push(item.dataset.wbsname);
            dataFormat.Activity_Type.push(item.dataset.activitytype);
            dataFormat.Activity_Status.push(item.dataset.activitystatus);
            dataFormat.Project_Name.push(item.dataset.projectname);
            dataFormat.Critical.push(item.dataset.critical);
        })
    }
}

export const filterReducer = (state, action) => {
    const { type, payload, selection } = action;
    const allList = document.querySelectorAll('.plannie-item');
    switch (type) {
        case 'topN': {
            emptyDataFormat();
            const topNIndices = [];
            const dates = [];
            // Get top N indices
            selection.mainData.forEach((item, index)=>{
                if(index < parseInt(selection.allSelected, 10)){
                    topNIndices.push(item.Index);
                    dates.push(item.Project_Data_Date);
                }
            });
            // Get the filtered result cards
            const filtered = [...allList].filter((item) => {
                return topNIndices.includes(parseInt(item.dataset.id, 10))
            });
            
            filtered.forEach((item) => {
                dataFormat.Index.push(item.dataset.id);
                dataFormat.LOB.push(item.dataset.lob);
                dataFormat.Region.push(item.dataset.region);
                dataFormat.Wbs_Name.push(item.dataset.wbsname);
                dataFormat.Activity_Type.push(item.dataset.activitytype);
                dataFormat.Activity_Status.push(item.dataset.activitystatus);
                dataFormat.Project_Name.push(item.dataset.projectname);
                dataFormat.Critical.push(item.dataset.critical);
            })
            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });

            selection.setResultCount(count);
            return {
                ...state,
                updateDateSlicer: true,
                dateList: dates,
                filterValues: {
                    Region: dataFormat.Region,
                    LOB: dataFormat.LOB,
                    Wbs_Name: dataFormat.Wbs_Name,
                    Activity_Type: dataFormat.Activity_Type,
                    Activity_Status: dataFormat.Activity_Status,
                    Project_Name: dataFormat.Project_Name,
                    Critical: dataFormat.Critical
                },
                activeSelectedFilters: {
                    wbsname: [],
                    activitytype: [],
                    activitystatus: [],
                    region: [],
                    lob: [],
                    projectname: [],
                    critical: []
                }
            };
        }

        case 'fullmatch': {
            emptyDataFormat();
            let tobeFiltered = [];
            const filteredIndex = [];
            if (selection.allSelected) {
                selection.mainData.forEach((item) => {
                    if ('Yes' === item.Is_Exact_Match) {
                        filteredIndex.push(item.Index);
                    }
                });
            } else {
                selection.mainData.forEach((item) => {
                    filteredIndex.push(item.Index);
                });
            }
            
            tobeFiltered = [...allList].filter((item) => {
                return filteredIndex.includes(parseInt(item.dataset.id, 10))
            });
            tobeFiltered.forEach((item) => {
                dataFormat.Index.push(item.dataset.id);
                dataFormat.LOB.push(item.dataset.lob);
                dataFormat.Region.push(item.dataset.region);
                dataFormat.Wbs_Name.push(item.dataset.wbsname);
                dataFormat.Activity_Type.push(item.dataset.activitytype);
                dataFormat.Activity_Status.push(item.dataset.activitystatus);
                dataFormat.Project_Name.push(item.dataset.projectname);
                dataFormat.Critical.push(item.dataset.critical);
            })
            let count = 0;
            allList.forEach((item) => {
                if (filteredIndex.includes(parseInt(item.dataset.id, 10))) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });

            selection.setResultCount(count);
            return {
                ...state,
                updateDateSlicer: false,
                filterValues: {
                    Region: dataFormat.Region,
                    LOB: dataFormat.LOB,
                    Wbs_Name: dataFormat.Wbs_Name,
                    Activity_Type: dataFormat.Activity_Type,
                    Activity_Status: dataFormat.Activity_Status,
                    Project_Name: dataFormat.Project_Name,
                    Critical: dataFormat.Critical
                },
                activeSelectedFilters: {
                    wbsname: [],
                    activitytype: [],
                    activitystatus: [],
                    region: [],
                    lob: [],
                    projectname: [],
                    critical: []
                }
            };
        }

        case 'date': {
            emptyDataFormat();
            let tobeFiltered = [];
            const filteredIndex = [];
            selection.mainData.forEach((item) => {
                const dateToCompare = parseInt(item.Project_Data_Date, 10);
                if(dateToCompare >= selection.allSelected[0] && dateToCompare <= selection.allSelected[1]){
                    filteredIndex.push(item.Index);
                }
            });
            tobeFiltered = [...allList].filter((item) => {
                return filteredIndex.includes(parseInt(item.dataset.id, 10))
            });
            tobeFiltered.forEach((item) => {
                dataFormat.Index.push(item.dataset.id);
                dataFormat.LOB.push(item.dataset.lob);
                dataFormat.Region.push(item.dataset.region);
                dataFormat.Wbs_Name.push(item.dataset.wbsname);
                dataFormat.Activity_Type.push(item.dataset.activitytype);
                dataFormat.Activity_Status.push(item.dataset.activitystatus);
                dataFormat.Project_Name.push(item.dataset.projectname);
                dataFormat.Critical.push(item.dataset.critical);
            })
            let count = 0;
            allList.forEach((item) => {
                if (filteredIndex.includes(parseInt(item.dataset.id, 10))) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });

            selection.setResultCount(count);
            return {
                ...state,
                updateDateSlicer: false,
                filterValues: {
                    Region: dataFormat.Region,
                    LOB: dataFormat.LOB,
                    Wbs_Name: dataFormat.Wbs_Name,
                    Activity_Type: dataFormat.Activity_Type,
                    Activity_Status: dataFormat.Activity_Status,
                    Project_Name: dataFormat.Project_Name,
                    Critical: dataFormat.Critical
                },
                activeSelectedFilters: {
                    wbsname: [],
                    activitytype: [],
                    activitystatus: [],
                    region: [],
                    lob: [],
                    projectname: [],
                    critical: []
                }
            };
        }
        case 'region': {
            emptyDataFormat()

            const { region, lob, projectname, wbsname, activitytype, activitystatus, critical } = getSelectedValues(selection, ['lob', 'projectname', 'wbsname', 'activitytype', 'activitystatus', 'critical']);
            // const region = [], lob = [], wbsname = [], activitytype = [], activitystatus = [];
            let tobeFiltered = [];


            // if (selection.allSelected['activitystatus'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitystatus'].includes(item.Activity_Status)) {
            //             activitystatus.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['lob'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['lob'].includes(item.LOB)) {
            //             lob.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['wbsname'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['wbsname'].includes(item.Wbs_Name)) {
            //             wbsname.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['activitytype'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitytype'].includes(item.Activity_Type)) {
            //             activitytype.push(item.Index);
            //         }
            //     });
            // }

            // let intersectionIDs = intersectionRegion(selection, region, lob, projectname, wbsname, activitytype, activitystatus, critical);
            
            const mapParam = { paramA: lob, paramB: projectname, paramC: wbsname, paramD: activitytype, paramE: activitystatus, paramF: critical, paramG: region };
            const paramNames = ['LOB', 'Project_Name', 'Wbs_Name', 'Activity_Type', 'Activity_Status', 'Critical', 'Region'];
            // {lob, projectname, wbsname, activitytype, activitystatus, critical, region}
            let intersectionIDs = intersectionOfCombinaion(selection, mapParam);

            tobeFiltered = [...allList].filter((item) => {
                return intersectionIDs.includes(parseInt(item.dataset.id, 10))
            });


            if (selection.allSelected[type].length > 0) {
                const filtered = tobeFiltered.filter((item) => {
                    return selection.allSelected[type].includes(item.dataset[type]);
                })
                filtered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            } else {
                tobeFiltered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            }
            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });

            selection.setResultCount(count);
            // {lob, wbsname, activitytype, activitystatus, projectname, critical, region}
            return {
                ...state,
                // filterValues: {
                //     Region: state.filterValues.Region,
                //     LOB: dataFormat.LOB,
                //     Wbs_Name: dataFormat.Wbs_Name,
                //     Activity_Type: dataFormat.Activity_Type,
                //     Activity_Status: dataFormat.Activity_Status,
                // }
                // filterValues: regionRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat),
                filterValues: returnValues(mapParam, paramNames, state, dataFormat, selection),
                activeSelectedFilters: selection.allSelected
            };
        }
        case 'lob': {
            emptyDataFormat()
            const { region, lob, projectname, wbsname, activitytype, activitystatus, critical } = getSelectedValues(selection, ['region', 'projectname', 'wbsname', 'activitytype', 'activitystatus', 'critical']);
            // const lob = [], region = [], wbsname = [], activitytype = [], activitystatus = [];
            let tobeFiltered = [];


            // if (selection.allSelected['region'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['region'].includes(item.Region)) {
            //             region.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['wbsname'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['wbsname'].includes(item.Wbs_Name)) {
            //             wbsname.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['activitytype'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitytype'].includes(item.Activity_Type)) {
            //             activitytype.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['activitystatus'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitystatus'].includes(item.Activity_Status)) {
            //             activitystatus.push(item.Index);
            //         }
            //     });
            // }


            // let intersectionIDs = intersectionLoB(selection, region, lob, wbsname, activitytype, activitystatus);
            
            
            // {region, projectname, wbsname, activitytype, activitystatus, critical, lob}
            
            const mapParam = { paramA: region, paramB: projectname, paramC: wbsname, paramD: activitytype, paramE: activitystatus, paramF: critical, paramG: lob };
            const paramNames = ['Region', 'Project_Name', 'Wbs_Name', 'Activity_Type', 'Activity_Status', 'Critical', 'LOB'];
            let intersectionIDs = intersectionOfCombinaion(selection, mapParam);
            tobeFiltered = [...allList].filter((item) => {
                return intersectionIDs.includes(parseInt(item.dataset.id, 10))
            })


            if (selection.allSelected[type].length > 0) {
                const filtered = tobeFiltered.filter((item) => {
                    return selection.allSelected[type].includes(item.dataset[type]);
                })
                filtered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            } else {
                tobeFiltered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            }
            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });

            // const count = displayFilteredCard(tobeFiltered, dataFormat);
            selection.setResultCount(count);
            return {
                ...state,
                // filterValues: lobRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat),
                filterValues: returnValues(mapParam, paramNames, state, dataFormat, selection),
                activeSelectedFilters: selection.allSelected
            };
        }

        case 'wbsname': {
            emptyDataFormat()

            // const region = [], lob = [], wbsname = [], activitytype = [], activitystatus = [];
            const { region, lob, projectname, wbsname, activitytype, activitystatus, critical } = getSelectedValues(selection, ['region', 'lob', 'projectname', 'activitytype', 'activitystatus', 'critical']);
            let tobeFiltered = [];

            // if (selection.allSelected['region'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['region'].includes(item.Region)) {
            //             region.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['lob'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['lob'].includes(item.LOB)) {
            //             lob.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['activitytype'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitytype'].includes(item.Activity_Type)) {
            //             activitytype.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['activitystatus'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitystatus'].includes(item.Activity_Status)) {
            //             activitystatus.push(item.Index);
            //         }
            //     });
            // }
            
            // let intersectionIDs = intersectionWbsName(selection, region, lob, wbsname, activitytype, activitystatus);

            const mapParam = { paramA: region, paramB: lob, paramC: projectname, paramD: activitytype, paramE: activitystatus, paramF: critical, paramG: wbsname };
            const paramNames = ['Region', 'LOB', 'Project_Name', 'Activity_Type', 'Activity_Status', 'Critical', 'Wbs_Name'];
            let intersectionIDs = intersectionOfCombinaion(selection, mapParam);
            tobeFiltered = [...allList].filter((item) => {
                return intersectionIDs.includes(parseInt(item.dataset.id, 10))
            })

            if (selection.allSelected[type].length > 0) {
                const filtered = tobeFiltered.filter((item) => {
                    return selection.allSelected[type].includes(item.dataset[type]);
                })
                filtered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            } else {
                tobeFiltered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            }
            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });


            selection.setResultCount(count);
            return {
                ...state,
                // filterValues: {
                //     Region: state.filterValues.Region,
                //     LOB: state.filterValues.LOB,
                //     Wbs_Name: state.filterValues.Wbs_Name,
                //     Activity_Type: dataFormat.Activity_Type,
                //     Activity_Status: dataFormat.Activity_Status,
                // }
                // filterValues: wbsnameRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat),
                filterValues: returnValues(mapParam, paramNames, state, dataFormat, selection),
                activeSelectedFilters: selection.allSelected
            };
        }
        case 'activitytype': {
            emptyDataFormat()

            // const region = [], lob = [], wbsname = [], activitytype = [], activitystatus = [];
            const { region, lob, projectname, wbsname, activitytype, activitystatus, critical } = getSelectedValues(selection, ['region', 'lob', 'projectname', 'wbsname', 'activitystatus', 'critical']);
            let tobeFiltered = [];


            // if (selection.allSelected['region'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['region'].includes(item.Region)) {
            //             region.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['lob'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['lob'].includes(item.LOB)) {
            //             lob.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['wbsname'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['wbsname'].includes(item.Wbs_Name)) {
            //             wbsname.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['activitystatus'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitystatus'].includes(item.Activity_Status)) {
            //             activitystatus.push(item.Index);
            //         }
            //     });
            // }

            // let intersectionIDs = intersectionActivityType(selection, region, lob, wbsname, activitytype, activitystatus );

            const mapParam = { paramA: region, paramB: lob, paramC: projectname, paramD: wbsname, paramE: activitystatus, paramF: critical, paramG: activitytype };
            const paramNames = ['Region', 'LOB', 'Project_Name', 'Wbs_Name', 'Activity_Status', 'Critical', 'Activity_Type'];
            let intersectionIDs = intersectionOfCombinaion(selection, mapParam);

            tobeFiltered = [...allList].filter((item) => {
                return intersectionIDs.includes(parseInt(item.dataset.id, 10))
            })
            if (selection.allSelected[type].length > 0) {

                const filtered = tobeFiltered.filter((item) => {
                    return selection.allSelected[type].includes(item.dataset[type]);
                })
                filtered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            } else {
                tobeFiltered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            }
            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });


            selection.setResultCount(count);
            return {
                ...state,
                // filterValues: {
                //     Region: state.filterValues.Region,
                //     LOB: state.filterValues.LOB,
                //     Wbs_Name: state.filterValues.Wbs_Name,
                //     Activity_Type: state.filterValues.Activity_Type,
                //     Activity_Status: dataFormat.Activity_Status,
                // }
                // filterValues: activitytypeRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat),
                filterValues: returnValues(mapParam, paramNames, state, dataFormat, selection),
                activeSelectedFilters: selection.allSelected
            };
        }
        case 'activitystatus': {
            emptyDataFormat()

            // const region = [], lob = [], wbsname = [], activitytype = [], activitystatus = [];
            const { region, lob, projectname, wbsname, activitytype, activitystatus, critical } = getSelectedValues(selection, ['region', 'lob', 'projectname', 'wbsname', 'activitytype', 'critical']);
            let tobeFiltered = [];


            // if (selection.allSelected['region'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['region'].includes(item.Region)) {
            //             region.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['lob'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['lob'].includes(item.LOB)) {
            //             lob.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['wbsname'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['wbsname'].includes(item.Wbs_Name)) {
            //             wbsname.push(item.Index);
            //         }
            //     });
            // }
            // if (selection.allSelected['activitytype'].length > 0) {
            //     selection.mainData.forEach((item) => {
            //         if (selection.allSelected['activitytype'].includes(item.Activity_Type)) {
            //             activitytype.push(item.Index);
            //         }
            //     });
            // }

            // let intersectionIDs = intersectionActivityStatus(selection, region, lob, wbsname, activitytype, activitystatus);

            const mapParam = { paramA: region, paramB: lob, paramC: projectname, paramD: wbsname, paramE: activitytype, paramF: critical, paramG: activitystatus }
            const paramNames = ['Region', 'LOB', 'Project_Name', 'Wbs_Name', 'Activity_Type', 'Critical', 'Activity_Status'];
            let intersectionIDs = intersectionOfCombinaion(selection, mapParam);
            tobeFiltered = [...allList].filter((item) => {
                return intersectionIDs.includes(parseInt(item.dataset.id, 10))
            });


            if (selection.allSelected[type].length > 0) {
                const filtered = tobeFiltered.filter((item) => {
                    return selection.allSelected[type].includes(item.dataset[type]);
                })
                filtered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            } else {
                tobeFiltered.forEach((item) => {
                    dataFormat.Index.push(item.dataset.id);
                    dataFormat.LOB.push(item.dataset.lob);
                    dataFormat.Region.push(item.dataset.region);
                    dataFormat.Wbs_Name.push(item.dataset.wbsname);
                    dataFormat.Activity_Type.push(item.dataset.activitytype);
                    dataFormat.Activity_Status.push(item.dataset.activitystatus);
                    dataFormat.Project_Name.push(item.dataset.projectname);
                    dataFormat.Critical.push(item.dataset.critical);
                })
            }
            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });
            selection.setResultCount(count);

            return {
                ...state,
                // filterValues: {
                //     Region: state.filterValues.Region,
                //     LOB: state.filterValues.LOB,
                //     Wbs_Name: state.filterValues.Wbs_Name,
                //     Activity_Type: state.filterValues.Activity_Type,
                //     Activity_Status: state.filterValues.Activity_Status,
                // }
                // filterValues: activityStatusRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat),
                filterValues: returnValues(mapParam, paramNames, state, dataFormat, selection),
                activeSelectedFilters: selection.allSelected
            };
        }

        case 'projectname': {
            emptyDataFormat()
            const { region, lob, projectname, wbsname, activitytype, activitystatus, critical } = getSelectedValues(selection, ['region', 'lob', 'wbsname', 'activitytype', 'activitystatus', 'critical']);
            let tobeFiltered = [];

            const mapParam = { paramA: region, paramB: lob, paramC: wbsname, paramD: activitytype, paramE: activitystatus, paramF: critical, paramG: projectname }
            const paramNames = ['Region', 'LOB', 'Wbs_Name', 'Activity_Type', 'Activity_Status', 'Critical', 'Project_Name'];
            let intersectionIDs = intersectionOfCombinaion(selection, mapParam);
            tobeFiltered = [...allList].filter((item) => {
                return intersectionIDs.includes(parseInt(item.dataset.id, 10))
            });


            filteredData(selection, type, tobeFiltered);

            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });
            selection.setResultCount(count);

            return {
                ...state,
                filterValues: returnValues(mapParam, paramNames, state, dataFormat, selection),
                activeSelectedFilters: selection.allSelected
            };
        }

        case 'critical': {
            emptyDataFormat()
            const { region, lob, projectname, wbsname, activitytype, activitystatus, critical } = getSelectedValues(selection, ['region', 'lob', 'projectname', 'wbsname', 'activitytype', 'activitystatus']);
            let tobeFiltered = [];

            // Keep the mapParam and paramNames values in same order like region->Region, lob->LOB, projectname->Project_Name like this.
            const mapParam = { paramA: region, paramB: lob, paramC: projectname, paramD: wbsname, paramE: activitytype, paramF: activitystatus, paramG: critical }
            const paramNames = ['Region', 'LOB', 'Project_Name', 'Wbs_Name', 'Activity_Type', 'Activity_Status', 'Critical']
            let intersectionIDs = intersectionOfCombinaion(selection, mapParam);
            tobeFiltered = [...allList].filter((item) => {
                return intersectionIDs.includes(parseInt(item.dataset.id, 10))
            });

            filteredData(selection, type, tobeFiltered);
            
            let count = 0;
            allList.forEach((item) => {
                if (dataFormat.Index.includes(item.dataset.id)) {
                    displyCard(item)
                    count += 1;
                } else {
                    hideCard(item);
                }
            });
            selection.setResultCount(count);

            return {
                ...state,
                filterValues: returnValues(mapParam, paramNames, state, dataFormat, selection),
                activeSelectedFilters: selection.allSelected
            };
        }
        case 'initial': {
            let count = 0;
            allList.forEach((item) => {
                count += 1;
                displyCard(item);
            });
            const countAny = selection.mainData.map((item) => item.Region)
            selection.setResultCount(countAny.length);
            return {
                ...state,
                updateDateSlicer: true,
                searchResult: selection.mainData,
                dateList: selection.mainData.map((item) => item.Project_Data_Date),
                filterValues: {
                    Region: selection.mainData.map((item) => item.Region),
                    LOB: selection.mainData.map((item) => item.LOB),
                    Project_Name: selection.mainData.map((item) => item.Project_Name),
                    Wbs_Name: selection.mainData.map((item) => item.Wbs_Name),
                    Activity_Type: selection.mainData.map((item) => item.Activity_Type),
                    Activity_Status: selection.mainData.map((item) => item.Activity_Status),
                    Critical: selection.mainData.map((item) => item.Critical),
                },
                activeSelectedFilters: {
                    wbsname: [],
                    activitytype: [],
                    activitystatus: [],
                    region: [],
                    lob: [],
                    projectname: [],
                    critical: []
                }
            }
        }
        default:
            allList.forEach((item) => {
                displyCard(item);
            })
            return {
                ...state,
                updateDateSlicer: true,
                dateList: selection.mainData.map((item) => item.Project_Data_Date),
                filterValues: {
                    Region: selection.mainData.map((item) => item.Region),
                    LOB: selection.mainData.map((item) => item.LOB),
                    Project_Name: selection.mainData.map((item) => item.Project_Name),
                    Wbs_Name: selection.mainData.map((item) => item.Wbs_Name),
                    Activity_Type: selection.mainData.map((item) => item.Activity_Type),
                    Activity_Status: selection.mainData.map((item) => item.Activity_Status),
                    Critical: selection.mainData.map((item) => item.Critical),
                },
                activeSelectedFilters: {
                    wbsname: [],
                    activitytype: [],
                    activitystatus: [],
                    region: [],
                    lob: [],
                    projectname: [],
                    critical: []
                }
            };
    }
};

function intersectionOfCombinaion(selection, mappedparams) {
    const { paramA, paramB, paramC, paramD, paramE, paramF, paramG } = mappedparams; //{ paramA: lob, paramB: projectname, paramC: wbsname, paramD: activitytype, paramE: activitystatus, paramF: critical, paramG: region };
    
    if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 &&  paramD.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramB, paramC, paramD, paramE, paramF);
    } // combination of five
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) {
        return intersection(paramA, paramB, paramC, paramD, paramE);

    } else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramB, paramC, paramD, paramF);

    } else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramB, paramC, paramE, paramF);

    } else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramB, paramD, paramE, paramF);

    } else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramC, paramD, paramE, paramF);

    } else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramB, paramC, paramD, paramE, paramF);

    }  // combination of four
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0) {
        return intersection(paramA, paramB, paramC, paramD);
    }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0) {
        return intersection(paramA, paramB, paramC, paramE);
    }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramB, paramC, paramF);
    }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0) {
        return intersection(paramA, paramB, paramD, paramE);
    }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramB, paramD, paramF);
    }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramB, paramE, paramF);
    }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) {
        return intersection(paramA, paramC, paramD, paramE);
    }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramC, paramD, paramF);
    }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramC, paramE, paramF);
    }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramA, paramD, paramE, paramF);
    }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) {
        return intersection(paramB, paramC, paramD, paramE);
    }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) {
        return intersection(paramB, paramC, paramD, paramF);
    }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramB, paramC, paramE, paramF);
    }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramB, paramD, paramE, paramF);
    }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) {
        return intersection(paramC, paramD, paramE, paramF);
    }
    
    // combination of three
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0) { return intersection(paramA, paramB, paramC); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0) { return intersection(paramA, paramB, paramD); }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0) { return intersection(paramA, paramB, paramE); }
    else if (paramA.length > 0 && paramB.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramF); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0) { return intersection(paramA, paramC, paramD); }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0) { return intersection(paramA, paramC, paramE); }
    else if (paramA.length > 0 && paramC.length > 0 && paramF.length > 0) { return intersection(paramA, paramC, paramF); }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramA, paramD, paramE); }
    else if (paramA.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramA, paramD, paramF); }
    else if (paramA.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramE, paramF); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0) { return intersection(paramB, paramC, paramD); }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0) { return intersection(paramB, paramC, paramE); }
    else if (paramB.length > 0 && paramC.length > 0 && paramF.length > 0) { return intersection(paramB, paramC, paramF); }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramB, paramD, paramE); }
    else if (paramB.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramB, paramD, paramF); }
    else if (paramB.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramB, paramE, paramF); }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramC, paramD, paramE); }
    else if (paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramC, paramD, paramF); }
    else if (paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramC, paramE, paramF); }
    else if (paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramD, paramE, paramF); }
    // combination of two
    else if (paramA.length > 0 && paramB.length > 0) { return intersection(paramA, paramB); }
    else if (paramA.length > 0 && paramC.length > 0) { return intersection(paramA, paramC); }
    else if (paramA.length > 0 && paramD.length > 0) { return intersection(paramA, paramD); }
    else if (paramA.length > 0 && paramE.length > 0) { return intersection(paramA, paramE); }
    else if (paramA.length > 0 && paramF.length > 0) { return intersection(paramA, paramF); }
    else if (paramB.length > 0 && paramC.length > 0) { return intersection(paramB, paramC); }
    else if (paramB.length > 0 && paramD.length > 0) { return intersection(paramB, paramD); }
    else if (paramB.length > 0 && paramE.length > 0) { return intersection(paramB, paramE); }
    else if (paramB.length > 0 && paramF.length > 0) { return intersection(paramB, paramF); }
    else if (paramC.length > 0 && paramD.length > 0) { return intersection(paramC, paramD); }
    else if (paramC.length > 0 && paramE.length > 0) { return intersection(paramC, paramE); }
    else if (paramC.length > 0 && paramF.length > 0) { return intersection(paramC, paramF); }
    else if (paramD.length > 0 && paramE.length > 0) { return intersection(paramD, paramE); }
    else if (paramD.length > 0 && paramF.length > 0) { return intersection(paramD, paramF); }
    else if (paramE.length > 0 && paramF.length > 0) { return intersection(paramE, paramF); }

    // One param
    else if (paramA.length > 0) { return paramA; }
    else if (paramB.length > 0) { return paramB; }
    else if (paramC.length > 0) { return paramC; }
    else if (paramD.length > 0) { return paramD; }
    else if (paramE.length > 0) { return paramE; }
    else if (paramF.length > 0) { return paramF; }
    else {
        // If no filter selected get all ids
        return selection.mainData.map((item) => item.Index);
    }
}

function intersectionRegion(selection, region, lob, wbsname, activitytype, activitystatus) {
    if (activitystatus.length > 0 && lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, lob, wbsname, activitytype);
    } // combination of three
    else if (activitystatus.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return intersection(activitystatus, lob, wbsname);
    } else if (activitystatus.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, lob, activitytype);
    } else if (activitystatus.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, wbsname, activitytype);
    } else if (lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(lob, wbsname, activitytype);
    } // combination of two
    else if (activitystatus.length > 0 && lob.length > 0) {
        return intersection(activitystatus, lob);
    } else if (activitystatus.length > 0 && wbsname.length > 0) {
        return intersection(activitystatus, wbsname);
    } else if (activitystatus.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, activitytype);
    } else if (lob.length > 0 && wbsname.length > 0) {
        return intersection(lob, wbsname);
    } else if (lob.length > 0 && activitytype.length > 0) {
        return intersection(lob, activitytype);
    } else if (activitystatus.length > 0) {
        return activitystatus;
    } else if (lob.length > 0) {
        return lob;
    } else if (wbsname.length > 0) {
        return wbsname;
    } else if (activitytype.length > 0) {
        return activitytype;
    } else {
        // If no filter selected get all ids
        return selection.mainData.map((item) => item.Index);
    }
}

function intersectionLoB(selection, region, lob, wbsname, activitytype, activitystatus) {
    if (region.length > 0 && activitystatus.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(region, activitystatus, wbsname, activitytype);
    } // combination of three
    else if (region.length > 0 && activitystatus.length > 0 && wbsname.length > 0) {
        return intersection(region, activitystatus, wbsname);
    } else if (region.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
        return intersection(region, activitystatus, activitytype);
    } else if (region.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(region, wbsname, activitytype);
    } else if (activitystatus.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, wbsname, activitytype);
    } // combination of two
    else if (region.length > 0 && activitystatus.length > 0) {
        return intersection(region, activitystatus);
    } else if (region.length > 0 && wbsname.length > 0) {
        return intersection(region, wbsname);
    } else if (region.length > 0 && activitytype.length > 0) {
        return intersection(region, activitytype);
    } else if (activitystatus.length > 0 && wbsname.length > 0) {
        return intersection(activitystatus, wbsname);
    } else if (activitystatus.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, activitytype);
    } else if (region.length > 0) {
        return region;
    } else if (activitystatus.length > 0) {
        return activitystatus;
    } else if (wbsname.length > 0) {
        return wbsname;
    } else if (activitytype.length > 0) {
        return activitytype;
    } else {
        // If no filter selected get all ids
        return selection.mainData.map((item) => item.Index);
    }
}

function intersectionWbsName(selection, region, lob, wbsname, activitytype, activitystatus) {
    if (region.length > 0 && activitystatus.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return intersection(region, activitystatus, lob, activitytype);
    } // combination of three
    else if (region.length > 0 && activitystatus.length > 0 && lob.length > 0) {
        return intersection(region, activitystatus, lob);
    } else if (region.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
        return intersection(region, activitystatus, activitytype);
    } else if (region.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return intersection(region, lob, activitytype);
    } else if (activitystatus.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, lob, activitytype);
    } // combination of two
    else if (region.length > 0 && activitystatus.length > 0) {
        return intersection(region, activitystatus);
    } else if (region.length > 0 && lob.length > 0) {
        return intersection(region, lob);
    } else if (region.length > 0 && activitytype.length > 0) {
        return intersection(region, activitytype);
    } else if (activitystatus.length > 0 && lob.length > 0) {
        return intersection(activitystatus, lob);
    } else if (activitystatus.length > 0 && activitytype.length > 0) {
        return intersection(activitystatus, activitytype);
    } else if (region.length > 0) {
        return region;
    } else if (activitystatus.length > 0) {
        return activitystatus;
    } else if (lob.length > 0) {
        return lob;
    } else if (activitytype.length > 0) {
        return activitytype;
    } else {
        // If no filter selected get all ids
        return selection.mainData.map((item) => item.Index);
    }
}

function intersectionActivityType(selection, region, lob, wbsname, activitytype, activitystatus) {
    if (region.length > 0 && activitystatus.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return intersection(region, activitystatus, lob, wbsname);
    } // combination of three
    else if (region.length > 0 && activitystatus.length > 0 && lob.length > 0) {
        return intersection(region, activitystatus, lob);
    } else if (region.length > 0 && activitystatus.length > 0 && wbsname.length > 0) {
        return intersection(region, activitystatus, wbsname);
    } else if (region.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return intersection(region, lob, wbsname);
    } else if (activitystatus.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return intersection(activitystatus, lob, wbsname);
    } // combination of two
    else if (region.length > 0 && activitystatus.length > 0) {
        return intersection(region, activitystatus);
    } else if (region.length > 0 && lob.length > 0) {
        return intersection(region, lob);
    } else if (region.length > 0 && wbsname.length > 0) {
        return intersection(region, wbsname);
    } else if (activitystatus.length > 0 && lob.length > 0) {
        return intersection(activitystatus, lob);
    } else if (activitystatus.length > 0 && wbsname.length > 0) {
        return intersection(activitystatus, wbsname);
    } else if (region.length > 0) {
        return region;
    } else if (activitystatus.length > 0) {
        return activitystatus;
    } else if (lob.length > 0) {
        return lob;
    } else if (wbsname.length > 0) {
        return wbsname;
    } else {
        // If no filter selected get all ids
        return selection.mainData.map((item) => item.Index);
    }
}

function intersectionActivityStatus(selection, region, lob, wbsname, activitytype, activitystatus) {
    if (selection.allSelected['region'].length > 0) {
        selection.mainData.forEach((item) => {
            if (selection.allSelected['region'].includes(item.Region)) {
                region.push(item.Index);
            }
        });
    }
    if (selection.allSelected['lob'].length > 0) {
        selection.mainData.forEach((item) => {
            if (selection.allSelected['lob'].includes(item.LOB)) {
                lob.push(item.Index);
            }
        });
    }
    if (selection.allSelected['wbsname'].length > 0) {
        selection.mainData.forEach((item) => {
            if (selection.allSelected['wbsname'].includes(item.Wbs_Name)) {
                wbsname.push(item.Index);
            }
        });
    }
    if (selection.allSelected['activitytype'].length > 0) {
        selection.mainData.forEach((item) => {
            if (selection.allSelected['activitytype'].includes(item.Activity_Type)) {
                activitytype.push(item.Index);
            }
        });
    }

    if (region.length > 0 && lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(region, lob, wbsname, activitytype);
    } // combination of three
    else if (region.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return intersection(region, lob, wbsname);
    } else if (region.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return intersection(region, lob, activitytype);
    } else if (region.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(region, wbsname, activitytype);
    } else if (lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return intersection(lob, wbsname, activitytype);
    } // combination of two
    else if (region.length > 0 && lob.length > 0) {
        return intersection(region, lob);
    } else if (region.length > 0 && wbsname.length > 0) {
        return intersection(region, wbsname);
    } else if (region.length > 0 && activitytype.length > 0) {
        return intersection(region, activitytype);
    } else if (lob.length > 0 && wbsname.length > 0) {
        return intersection(lob, wbsname);
    } else if (lob.length > 0 && activitytype.length > 0) {
        return intersection(lob, activitytype);
    } else if (region.length > 0) {
        return region;
    } else if (lob.length > 0) {
        return lob;
    } else if (wbsname.length > 0) {
        return wbsname;
    } else if (activitytype.length > 0) {
        return activitytype;
    } else {
        // If no filter selected get all ids
        return selection.mainData.map((item) => item.Index);
    }
}

function activityStatusRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat) {
    if (region.length > 0 && lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of three
    else if (region.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of two
    else if (region.length > 0 && lob.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && wbsname.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (wbsname.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    }
}

function activitytypeRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat) {
    if (region.length > 0 && lob.length > 0 && wbsname.length > 0 && activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of three
    else if (region.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (region.length > 0 && lob.length > 0 && activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && wbsname.length > 0 && activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && wbsname.length > 0 && activitystatus.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of two
    else if (region.length > 0 && lob.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (region.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (region.length > 0 && activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && wbsname.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (lob.length > 0 && activitystatus.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (lob.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (wbsname.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitystatus.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    }
}

function wbsnameRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat) {
    if (region.length > 0 && lob.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of three
    else if (region.length > 0 && lob.length > 0 && activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (region.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of two
    else if (region.length > 0 && lob.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (region.length > 0 && activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (lob.length > 0 && activitystatus.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (region.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (lob.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitystatus.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else {
        return {
            Region: dataFormat.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    }
}

function lobRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat) {
    if (region.length > 0 && activitystatus.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of three
    else if (region.length > 0 && activitystatus.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitystatus.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of two
    else if (region.length > 0 && activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (region.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitystatus.length > 0 && wbsname.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (activitystatus.length > 0 && activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (region.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitystatus.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (wbsname.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitytype.length > 0) {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else {
        return {
            Region: dataFormat.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    }
}

function regionRV(region, lob, wbsname, activitytype, activitystatus, selection, state, dataFormat) {
    if (activitystatus.length > 0 && lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } // combination of three
    else if (activitystatus.length > 0 && lob.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (activitystatus.length > 0 && lob.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (activitystatus.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } // combination of two
    else if (activitystatus.length > 0 && lob.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (activitystatus.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (activitystatus.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0 && wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (lob.length > 0 && activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitystatus.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: state.filterValues.Activity_Status,
        }
    } else if (lob.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: state.filterValues.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (wbsname.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: state.filterValues.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else if (activitytype.length > 0) {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: state.filterValues.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    } else {
        return {
            Region: state.filterValues.Region,
            LOB: dataFormat.LOB,
            Wbs_Name: dataFormat.Wbs_Name,
            Activity_Type: dataFormat.Activity_Type,
            Activity_Status: dataFormat.Activity_Status,
        }
    }
}


function createReturnData(stateParams, state, dataFormat) {
    const params = ['Region', 'LOB', 'Project_Name', 'Wbs_Name', 'Activity_Type', 'Activity_Status', 'Critical'];
    const formattedReturnVal = {};
    params.forEach((param) => {
        if (stateParams.includes(param)) {
            formattedReturnVal[param] = state.filterValues[param];
        } else {
            formattedReturnVal[param] = dataFormat[param];
        }
    });
    console.log(formattedReturnVal)
    return formattedReturnVal;
}

// function returnValues({region, lob, wbsname, activitytype, activitystatus, projectname, critical}, paramNames, state, dataFormat, selection) {
function returnValues(mappedParams, paramNames, state, dataFormat, selection) {
    // const { paramA, paramB, paramC, paramD, paramE, paramF, paramG } = { paramA: lob, paramB: projectname, paramC: wbsname, paramD: activitytype, paramE: activitystatus, paramF: critical, paramG: region };
    const { paramA, paramB, paramC, paramD, paramE, paramF, paramG } = mappedParams;
    const [pA, pB, pC, pD, pE, pF, pG] = paramNames;
    if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pC, pD, pE, pF, pG], state, dataFormat); } 
    // combination of five
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return createReturnData([pA, pB, pC, pD, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pC, pD, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pC, pE, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pD, pE, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pC, pD, pE, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pB, pC, pD, pE, pF, pG], state, dataFormat); }
    // combination of four
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0) { return createReturnData([pA, pB, pC, pD, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0) { return createReturnData([pA, pB, pC, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pC, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0) { return createReturnData([pA, pB, pD, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pD, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pE, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return createReturnData([pA, pC, pD, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return createReturnData([pA, pC, pD, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pC, pE, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pD, pE, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return createReturnData([pB, pC, pD, pE, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return createReturnData([pB, pC, pD, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pB, pC, pE, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pB, pD, pE, pF, pG], state, dataFormat); }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pC, pD, pE, pF, pG], state, dataFormat); }
    
    // combination of three
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0) { return createReturnData([pA, pB, pC, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0) { return createReturnData([pA, pB, pD, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0) { return createReturnData([pA, pB, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramB.length > 0 && paramF.length > 0) { return createReturnData([pA, pB, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0) { return createReturnData([pA, pC, pD, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0) { return createReturnData([pA, pC, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0 && paramF.length > 0) { return createReturnData([pA, pC, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0) { return createReturnData([pA, pD, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramD.length > 0 && paramF.length > 0) { return createReturnData([pA, pD, pF, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pA, pE, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0) { return createReturnData([pB, pC, pD, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0) { return createReturnData([pB, pC, pE, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0 && paramF.length > 0) { return createReturnData([pB, pC, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0) { return createReturnData([pB, pD, pE, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramD.length > 0 && paramF.length > 0) { return createReturnData([pB, pD, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pB, pE, pF, pG], state, dataFormat); }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return createReturnData([pC, pD, pE, pG], state, dataFormat); }
    else if (paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return createReturnData([pC, pD, pF, pG], state, dataFormat); }
    else if (paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pC, pE, pF, pG], state, dataFormat); }
    else if (paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return createReturnData([pD, pE, pF, pG], state, dataFormat); }
    // combination of two
    else if (paramA.length > 0 && paramB.length > 0) { return createReturnData([pA, pB, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramC.length > 0) { return createReturnData([pA, pC, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramD.length > 0) { return createReturnData([pA, pD, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramE.length > 0) { return createReturnData([pA, pE, pG], state, dataFormat); }
    else if (paramA.length > 0 && paramF.length > 0) { return createReturnData([pA, pF, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramC.length > 0) { return createReturnData([pB, pC, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramD.length > 0) { return createReturnData([pB, pD, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramE.length > 0) { return createReturnData([pB, pE, pG], state, dataFormat); }
    else if (paramB.length > 0 && paramF.length > 0) { return createReturnData([pB, pF, pG], state, dataFormat); }
    else if (paramC.length > 0 && paramD.length > 0) { return createReturnData([pC, pD, pG], state, dataFormat); }
    else if (paramC.length > 0 && paramE.length > 0) { return createReturnData([pC, pE, pG], state, dataFormat); }
    else if (paramC.length > 0 && paramF.length > 0) { return createReturnData([pC, pF, pG], state, dataFormat); }
    else if (paramD.length > 0 && paramE.length > 0) { return createReturnData([pD, pE, pG], state, dataFormat); }
    else if (paramD.length > 0 && paramF.length > 0) { return createReturnData([pD, pF, pG], state, dataFormat); }
    else if (paramE.length > 0 && paramF.length > 0) { return createReturnData([pE, pF, pG], state, dataFormat); }

    // One param
    else if (paramA.length > 0) { return createReturnData([pA, pG], state, dataFormat); }
    else if (paramB.length > 0) { return createReturnData([pB, pG], state, dataFormat); }
    else if (paramC.length > 0) { return createReturnData([pC, pG], state, dataFormat); }
    else if (paramD.length > 0) { return createReturnData([pD, pG], state, dataFormat); }
    else if (paramE.length > 0) { return createReturnData([pE, pG], state, dataFormat); }
    else if (paramF.length > 0) { return createReturnData([pF, pG], state, dataFormat); }
    else {
        return createReturnData([pG], state, dataFormat);
    }
}