import React from 'react';
import AccessRequestGrid from '../grid/access-request-grid';
import NavHeader from '../nav-header';
import PlannieUserDataGrid from './plannie-user-data-grid';
class PlannieUserControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isSuperUser: props.location.state.isSuperUser, otheruser: props.location.state.otheruser}
    }
    render() {
        return (
            <div>
                <NavHeader isSuperUser={this.state.isSuperUser} otheruser={this.state.otheruser}/>
                <div className="mt-4 mx-2">
                    <div className='accordion request-access-accordion'>
                        <div className='card border-0'>
                            <div className="card-header">
                                <button type='button' className='btn btn-sm btn-block btn-link btn-accordion ra-header-btn text-left font-weight-bold px-1' data-toggle="collapse"
                                    data-target="#requestAccessDetail" aria-expanded="true" aria-controls="requestAccessDetail">
                                    Request access details
                                </button>
                            </div>
                            <div className='collapse show' id="requestAccessDetail">
                                <AccessRequestGrid />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 mx-2">
                    <PlannieUserDataGrid />
                </div>
            </div>
        );
    }
}
export default PlannieUserControl
