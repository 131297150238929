import React, { useEffect } from 'react';
import refresh from '../../assets/refresh.svg';
import download from '../../assets/download.svg';
import fullscreen from '../../assets/fullscreen.svg';
import * as d3 from "d3";
import canvg from 'canvg'
import { toast } from 'react-toastify';
import $ from 'jquery';
import * as htmlToImage from 'html-to-image';

export default function PlotMenu(props) {
    useEffect(()=>{
        $('[data-toggle="popover"]').popover();
    },[])
    function toggleFullScreen() {
        const fullscreenElem = document.querySelector(`.plannie-graph-analysis .tab-pane.active`);//document.querySelector(`#${props.fullscreenSelector}`);
        if (!document.fullscreenElement) {
            fullscreenElem.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }

    function fullscreenBtnOnClick(_e) {
        toggleFullScreen();
    }
    /**
     * Create an anchor tag on the fly to download a Data URI
     * @param {String} dataURL Base64 data uri
     * @param {String} fileName Download file name
     */
    function invokeAnchorToDownload(dataURL, fileName) {
        const fullFileName = `${fileName}`;
        const a = document.createElement("a");
        a.setAttribute('href', `${dataURL}`);
        a.setAttribute('download', fullFileName);
        a.setAttribute('target', '_blank');
        a.style.display = 'none';
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(dataURL);
    }
    function downloadBtnOnClick(e) {
        const defaultFileName = sessionStorage.getItem('query') + " " + new Date().toDateString() + "_plot";
        const fileName = window.prompt("Name the file", defaultFileName);
        if (fileName !== null && fileName !== undefined) {
            if (fileName.search(/[/:\\*?"<>|]/g) === -1) {
                var node = document.querySelector(`.plannie-graph-analysis .tab-pane.active .${props.downloadSelectorClass}`);
                htmlToImage.toPng(node)
                    .then(function (dataUrl) {
                        invokeAnchorToDownload(dataUrl, fileName);
                    })
                    .catch(function (error) {
                        console.error('oops, something went wrong!', error);
                    });
                
            } else {
                toast.warn("A file name can't contain any of these characters: \\:/*?\"<>|")
            }
        }
    }
    function refreshOnClick(e) {
        // if (props.clearOnClick) {
            props.clearOnClick(e);
        // }
    }
    function onClick(e)
    {
        if (props.onClick) {
            props.onClick(e);
        }
    }
    return (
        <div className='plot-menu-wrapper position-relative d-flex justify-content-end'>
        <div className="plot-menu">
            <div className='refresh-plots-for-filter-cost'></div>
            <div className='refresh-plots-for-filter-schedule'></div>
            <div className='refresh-plots-for-filter-probability'></div>
            <div className='refresh-plots-for-filter-emoc'></div>
            <div className='refresh-plots-for-filter-quality'></div>
            <div className='refresh-plots-for-filter-plannie' onClick={onClick}></div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="icon-wrap" tabIndex="0" title="Plot Information" data-trigger="focus" data-html="true" data-content={props.info} style={{color: '#ffffff'}} data-toggle="popover">i</div>
                {props.showRefreshBtn ?
                    <div className={`icon-wrap refresh ${props.customClass}`} data-classname={props.customClass} title={props.title} onClick={refreshOnClick}><img src={refresh} alt="Refresh" /></div> : null}
                <div className="icon-wrap download" title="Download Visual">
                    <a className="text-decoration-none" onClick={downloadBtnOnClick}><img src={download} alt="Download Visual" /></a></div>
                <div className="icon-wrap fullscreen" title="Fullscreen"><img src={fullscreen} alt="Fullscreen" onClick={fullscreenBtnOnClick} /></div>
            </div>
        </div>
        </div>
    );
}
