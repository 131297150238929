import KeyTopics from "./keytopic";
export default function alternateKeyword(tabName, keyWordsQueryCallback, keyWordsListRisk = [], keyWordsListLearning = [], keyWordsListEmoc = []) {
    if (tabName === "risks") {
        return <div className="risk-kw"><KeyTopics keywords={keyWordsListRisk} keyWordsQueryCallback={keyWordsQueryCallback} /></div>
    }
    else if (tabName === "learnings") {
        return <div className="learning-kw"><KeyTopics keywords={keyWordsListLearning} keyWordsQueryCallback={keyWordsQueryCallback} /></div>
    }
    else if (tabName === "plannie") {
        return <div className="plannie-kw"><KeyTopics keywords={keyWordsListRisk} keyWordsQueryCallback={keyWordsQueryCallback} /></div>
    }
    else {
        return <div className="emoc-kw"><KeyTopics keywords={keyWordsListEmoc} keyWordsQueryCallback={keyWordsQueryCallback} /></div>
    }
}
