import React, { useState, } from 'react';
import { useHistory } from "react-router-dom";
import utils from '../app-util';
import { toast } from 'react-toastify';

function PlannieRequestAccessForm() {
    const history = useHistory();
    const [displaySubmitBtn, setDisplaySubmitBtn] = useState(false);

    function onCheck(e) {
        if (e.target.checked) {
            setDisplaySubmitBtn(true);
        } else {
            setDisplaySubmitBtn(false);
        }
    }
    function requestToAccept(url, payload) {
        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json", "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
            body: JSON.stringify(payload)
        })
            .then(res => {
                return utils.checkSessionStatus(res);
            })
            .then(
                (results) => {
                    if (results.success) {
                        toast.success(`Thank You for accepting the "Access Request & Data Confidentiality Acceptance Form". 
                    The "Planie-Support" team will provide you the access shortly.`, { autoClose: false })
                        history.push({ pathname: "/accessdenied", state: { accessUnderProcess: true } })
                    } else {
                        toast.error(results.message)
                    }
                },
                (_err) => {
                    toast.error("Error occured on fetching the query");
                }
            ).catch(function (error) {
                console.error(error);
            })
    }
    function isEmpty(payloadData) {
        let isempty = false;
        for (const key in payloadData) {
            if ((Object.hasOwnProperty(payloadData, key)) && (payloadData[key] === "")) {
                isempty = true;
                break;
            }

        }

        return isempty;
    }
    function isValid(payloadData) {
        let isValid = true;
        if (!isEmpty(payloadData)) {
            if (!payloadData["lmemail"].toLowerCase().includes("@shell.com")) {
                isValid = false;
                toast.error("Please use Shell official email ID of your LM");
            }
        } else {
            toast.error("Please select/enter the required field.");
            isValid = false;
        }
        return isValid;
    }
    function getDataAccessSelection(elements) {
        const selectedData = [];
        elements.forEach(function (cbox) {
            if (cbox.checked) {
                selectedData.push(cbox.value)
            }
        });
        return selectedData;
    }
    function acceptOnSubmit(e) {
        e.preventDefault();
        const payload = {
            isaccepted: e.target.elements.isaccepted.checked,
            lmemail: e.target.elements.lmemail.value.toLowerCase(),
            staffoption: e.target.elements.staffoption.value,
            belongto: e.target.elements.belongto.value,
            projectsupport: getDataAccessSelection(e.target.elements['projectsupport']).join(),
            bizjustification: e.target.elements.bizjustification.value
        }

        if (isValid(payload)) {
            requestToAccept(`/plannie-request-access`, payload)
        }
    }

    return (
        <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content" tabIndex="-1">
                    <div className="modal-header justify-content-start">
                        <img className='mr-2' width={30} src={utils.logo} alt="Shell Pecten" />
                        <h5 className="modal-title">Plannie Access Request &#38; Data Confidentiality Acceptance Form</h5>
                    </div>
                    <div className="modal-body" id='tc-scroll-body'>
                        <div id='tc-content-scroll-body'>
                            <section>
                                <h5 className='text-danger mb-4'>Welcome to Plannie</h5>
                                <p className='font-weight-bold'>Purpose:</p>
                                <p>
                                    Plannie serves as an Artificial Intelligence (AI) tool within project services, functioning as an intent-based textual search engine. It's designed to navigate through Primavera data using keywords, extracting relevant insights from both historical and current schedule information, along with associated learnings. These insights empower projects with data-driven, high-quality decision-making capabilities.
                                </p>
                                <p className='font-weight-bold'>User Base:</p>
                                <p>
                                    Access to Plannie is restricted, primarily intended for planners and project controls professionals.
                                </p>
                                <p className='font-weight-bold'>Data Source and Sharing:</p>
                                <p>
                                    Plannie's primary data source is the Primavera Projects PDP database. Prior to sharing any data, it's essential to consult with the Primavera Team to understand any restrictions on data sharing. Please note that Primavera data is classified as CONFIDENTIAL, and detailed information cannot be disseminated outside of Shell. If there are uncertainties regarding what Primavera information can be shared internally and under what conditions, please reach out to the Project Service leadership for the relevant project or country. As a Shell employee or contractor, you are obligated to adhere to all relevant standards and maintain data confidentiality.
                                </p>
                                <p className='font-weight-bold'>Trade Controls/Antitrust/Disclaimer:</p>
                                <p>
                                    Sharing project schedule data may potentially violate antitrust laws if not done correctly. Therefore, any data exchange must comply with legal and antitrust regulations. It's crucial to exercise caution when sharing information or data. While efforts are made to facilitate data accessibility, users must be mindful of and adhere to Shell's anti-trust compliance rules. Additionally, users must comply with Shell's Trade Controls and Export Administration Regulations (EAR) when exchanging technology or technical information. Plannie should not be used to exchange Competitive Sensitive Information (CSI) with competitors without prior approval from Shell Legal. If there's uncertainty about whether information is competitively sensitive or whether a counterpart is considered a competitor, users should consult Shell Legal before sharing information.
                                </p>
                                <p>
                                    
                                </p>
                            </section>
                            <form id="acceptanceForm" onSubmit={acceptOnSubmit}>
                                <p>Please acknowledge your acceptance of the above statements</p>
                                <div className='form-group'>
                                    <div className="form-check">
                                        <input className='tc-checkbox form-check-input' name="isaccepted" id="iAccept" type='checkbox'
                                            onChange={onCheck} />
                                        <label className="form-check-label" htmlFor='iAccept'>I Accept*</label>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    Are you*
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="staffoption" value={"Shell Staff"} id="staffoption1" />
                                        <label className="form-check-label" htmlFor="staffoption1">
                                            Shell Staff
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="staffoption" value={"Shell Secondee"} id="staffoption2" />
                                        <label className="form-check-label" htmlFor="staffoption2">
                                            Shell Secondee
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="staffoption" value={"Contractor"} id="staffoption3" />
                                        <label className="form-check-label" htmlFor="staffoption3">
                                            Contractor
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lmEmail">Line Manager Email ID*</label>
                                    <input type="email" className="form-control" name="lmemail" id="lmEmail" placeholder="Enter your line manager email" />
                                </div>
                                <div className='form-group'>
                                    Organization/Function/Business you belong to*
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="belongto" value={"Upstream"} id="belongto1" />
                                        <label className="form-check-label" htmlFor="belongto1">
                                            Upstream
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="belongto" value={"Downstream"} id="belongto2" />
                                        <label className="form-check-label" htmlFor="belongto2">
                                            Downstream
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="belongto" value={"Integrated Gas & New Energies"} id="belongto3" />
                                        <label className="form-check-label" htmlFor="belongto3">
                                            Integrated Gas &#38; New Energies
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="belongto" value={"Projects & Technology"} id="belongto4" />
                                        <label className="form-check-label" htmlFor="belongto4">
                                            Projects &#38; Technology
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="belongto" value={"Other"} id="belongto5" />
                                        <label className="form-check-label" htmlFor="belongto5">
                                            Other
                                        </label>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    Types of project(s) you are supporting?*
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="projectsupport" value={"SOV"} id="projectsupport1" />
                                        <label className="form-check-label" htmlFor="projectsupport1">
                                            SOV
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="projectsupport" value={"NOV"} id="projectsupport2" />
                                        <label className="form-check-label" htmlFor="projectsupport2">
                                            NOV
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="projectsupport" value={"JV"} id="projectsupport3" />
                                        <label className="form-check-label" htmlFor="projectsupport3">
                                            JV
                                        </label>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor="bizjustification">Business Justification*</label>
                                    <textarea className='form-control' name='bizjustification'
                                        id="bizjustification" placeholder='Provide the business justification here' maxLength="600"></textarea>
                                </div>
                                {
                                    displaySubmitBtn ?
                                        <div className="border-top d-flex justify-content-between align-items-center">
                                            <div></div>
                                            <div><button type="submit" className="btn accept-btn btn-red rounded-0 my-2">Submit</button></div>
                                        </div>
                                        :
                                        null
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PlannieRequestAccessForm;
