import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { plannieUtils } from './plannie-utils';


function checkIfAllvalueZero(data){
 return (data.P0 === 0 && data.P10 === 0 && data.P25 === 0 && data.P50 === 0 && data.P75 === 0 && data.P90 === 0 && data.P100 === 0 );
}

export default function BoxPlot({ data, yLabelText, boxPlotFilerCallback, drawmedian, boxPlotName }) {
    const svgRef = useRef(null);
    const gRef = useRef(null);
    const xAxisRef = useRef(null);
    const yAxisRef = useRef(null), yLabel = useRef(null);
    const foreignObjRef = useRef(null);
    let zooms = useRef(null);
    useEffect(() => {
        if (svgRef.current !== null && data !== null && data !== undefined && data.P10 !== undefined && data.isNew) {
        if(!checkIfAllvalueZero(data)){
            
            data.isNew = false;
            const margin = { top: 50, right: 20, bottom: 20, left: 50 };
            // const width = 680, height = 360;
            let width = (plannieUtils.getFixedPlotDimension().width - margin.left - margin.right - 20) * 40 / 100;
            let height = 400; //plannieUtils.getFixedPlotDimension().height - margin.left - margin.right;

            const zoom = d3.zoom()
                .on('zoom', handleZoom)
            zooms.current = zoom;
            // .translateExtent([[0, 0], [width - (margin.left + margin.right), height - (margin.top + margin.bottom)]])
            const svg = d3.select(svgRef.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                .attr("preserveAspectRatio", "xMidYMid meet")
                .call(zoom)
                .on('mouseover', function () {
                    d3.select(this).classed('show-menu', true)
                }).on('mouseout', function () {
                    d3.select(this).classed('show-menu', false)
                });
            d3.select(gRef.current).attr('class', 'box-plot')
                .attr("transform", `translate(${margin.left},${margin.top})`);
            d3.select(foreignObjRef.current)
                .attr("transform", `translate(${margin.left}, ${0})`);

            const P0 = parseInt(data.P0, 10);
            const P10 = parseInt(data.P10, 10);
            const P90 = parseInt(data.P90, 10);
            const P100 = parseInt(data.P100, 10);
            const q1 = parseInt(data.P25, 10);
            const median = parseInt(data.P50, 10);
            const q3 = parseInt(data.P75, 10);

            // Not used the IQR because duration cannot be negative so considering P0 and P100 as min and max respectively.
            const interQuantileRange = q3 - q1;
            const min = q1 - 1.5 * interQuantileRange;
            const max = q3 + 1.5 * interQuantileRange;

            const yScale = d3.scaleLinear().domain([P0, P100 + 10]).range([height, 0]);
            const yAxis = d3.axisLeft(yScale);

            // =========== Set xScal domain and yScale domain ==========
            d3.select(yAxisRef.current).attr('class', 'y axis yAxis-box-plot')
                .call(yAxis);
            d3.select(yLabel.current)
                .attr('class', "ylabel")
                .attr("x", -height / 2)
                .attr("y", -(margin.left - 10))
                .attr("transform", "rotate(-90)")
                .style("font-size", "0.75rem")
                .attr('text-anchor', "middle")
                .text(yLabelText);
            // ==================Domain end=======================
            const center = width * 65 / 100, boxWidth = center / 2 > 80 ? 80 : center / 2;
            d3.select(gRef.current).selectAll(".whisker").remove();
            d3.select(gRef.current).selectAll(".box").remove();
            d3.select(gRef.current).selectAll(".lineboxplot").remove();
            //===============Whisker line========================
            d3.select(gRef.current)
                .append('line')
                .attr('class', 'whisker')
                .attr('x1', center)
                .attr('x2', center)
                .attr('y1', yScale(P0))
                .attr('y2', yScale(P100))
                .attr('stroke', 'black');
            //===============Rectangular Box========================
            d3.select(gRef.current)
                .append('rect')
                .attr('class', 'box')
                .attr('x', center - boxWidth / 2)
                .attr('y', yScale(q3))
                .attr('height', (yScale(q1) - yScale(q3)))
                .attr('width', boxWidth)
                .attr('stroke', 'black')
                .attr("data-ids", data.box_plot_indexes)
                .style('fill', '#8bb3f9')
                .style("cursor", "pointer")
                .on("click", function (_d) {
                    const activeFilterElement = document.querySelector(`.svg-box-plot.${boxPlotName} .outlier-points.active`);
                    if(activeFilterElement !== null){
                        activeFilterElement.classList.remove('active');
                    }
                    let filter = false;
                    if (d3.select(this).classed("active")) {
                        d3.select(this).classed("active", false);
                    } else {
                        d3.select(this).classed("active", true);
                        filter = true;
                    }
                    const ids = d3.select(this).attr("data-ids");
                    boxPlotFilerCallback({ id: ids, filter: filter }, "box", true, boxPlotName);
                });
            //===============lines========================
            d3.select(gRef.current)
                .selectAll('lineboxplot')
                .data([P0, P10, median, P90, P100])
                .enter()
                .append('line')
                .attr('class', (d) => `lineboxplot line-${d}`)
                .attr('x1', center - boxWidth / 2)
                .attr('x2', center + boxWidth / 2)
                .attr('y1', function (d) { return (yScale(d)) })
                .attr('y2', function (d) { return (yScale(d)) })
                .attr('stroke', 'black')
                .style('stroke-dasharray', (_d, i) => {
                    if (i === 1 || i === 3) {
                        return 2;
                    }
                });
            //===============Labels========================    
            const labels = ['Min', 'P10', 'Median', 'P90', 'Max'];
            d3.select(gRef.current).selectAll('.Qlabel').remove();
            d3.select(gRef.current)
                .selectAll('Qlabel')
                .data([P0, P10, median, P90, P100])
                .enter()
                .append('text')
                .attr('class', (_d, i) => `Qlabel ${labels[i]}`)
                .attr('x', (_d, i) => {
                    if (i === 1 || i === 2) {
                        return center - boxWidth / 2 - 2;
                    } else {
                        return center + boxWidth / 2 + 2;
                    }
                })
                .attr('y', (d) => yScale(d) + 9)
                .attr('dy', (_d, i)=>{
                    if (i === 2) {
                        return -12;
                    } else if(i === 1) {
                        return -3;
                    } else {
                        return -5;
                    }
                })
                .text((d, i) => {
                    if ((P90 === P100) && (i === 3 || i === 4)) {
                        return `${labels[3]}/${labels[4]} (${d})`;
                    } else if ((P10 === median) && (i === 1 || i === 2)) {
                        document.querySelector('.Qlabel.P10').classList.add('d-none');
                        return `${labels[1]}/${labels[2]} (${d})`;
                    } else {
                        return `${labels[i]} (${d})`;
                    }
                })
                .style('font-size', '0.75rem')
                .style('text-anchor', (_d, i) => {
                    if (i === 1 || i === 2) {
                        return 'end';
                    }
                });
            //===============Outlier========================
            let isOutlierHighlighted = null;
            const jitterWidth = 60
            d3.select(gRef.current)
                .selectAll(".outlier-points").remove();
            d3.select(gRef.current)
                .selectAll(".outlier-points")
                .data(data.outlier_values)
                .enter()
                .append("circle")
                .attr('class', "outlier-points")
                .attr("cx", function (d) { return (center - jitterWidth / 2 + Math.random() * jitterWidth) })
                .attr("cy", function (d) { return (yScale(d)) })
                .attr("r", 4)
                .attr('data-ids', (_d, i) => {
                    return data.outlier_indexes[i];
                })
                .on('mouseover', (_d) => {
                    d3.select(this).attr("class", "hovered");
                    d3.select(this).style("transform", 'scale(0.5)')
                })
                .on('mouseout', (_d) => {
                    d3.select(this).attr("class", "hoverout");
                    d3.select(this).attr("r", 4)
                }).on("click", function (_d) {
                    const activeFilterElement = document.querySelector(`.svg-box-plot.${boxPlotName} .box.active`);
                    if(activeFilterElement !== null){
                        activeFilterElement.classList.remove('active');
                    }
                    let filter = false;
                    if (d3.select(this).classed("active")) {
                        d3.select(this).classed("active", false);
                    } else {
                        d3.select(this).classed("active", true);
                        filter = true;
                    }
                    if ((isOutlierHighlighted !== null) && !isOutlierHighlighted.isSameNode(this)) {
                        isOutlierHighlighted.classList.remove('active');
                    }

                    isOutlierHighlighted = this;
                    const ids = d3.select(this).attr("data-ids");
                    boxPlotFilerCallback({ id: ids, filter: filter }, "box", true, boxPlotName);
                })
                .style("fill", "#8bb3f9")
                .attr("stroke", "black")
            //===============Median Line========================
            function getSelectedActivityStatus() {
                const selectedActivityStatus = [];
                document.querySelectorAll(".select-activitystatus .MuiChip-label.MuiChip-labelSmall").forEach((e) => {
                    selectedActivityStatus.push(e.textContent);
                });
                return selectedActivityStatus;
            }
            function displayBothMedian(){
                d3.select(gRef.current).selectAll('.Actual_Median_Duration').style('display', 'block');
                d3.select(gRef.current).selectAll('.Planned_Median_Duration').style('display', 'block');
            }
            if (drawmedian) {
                const medianLabel = ["Actual Median Duration", "Planned Median Duration"];
                const medianData = [data.actual_duration_median, data.original_duration_median];
                d3.select(gRef.current).selectAll('.median-line').remove();
                d3.select(gRef.current).selectAll('.box-plot-median-label').remove();
                d3.select(gRef.current)
                    .selectAll('.median-line')
                    .data(medianData)
                    .enter()
                    .append('line')
                    .attr('class', (d, i) => `median-line line-${d} ${medianLabel[i].split(' ').join('_')}`)
                    .attr('x1', 0)
                    .attr('x2', center + boxWidth / 2)
                    .attr('y1', function (d) { return (yScale(d)) })
                    .attr('y2', function (d) { return (yScale(d)) })
                    .attr('stroke', 'black')
                    .attr('stroke-width', '0.5')
                    .style('shape-rendering', 'crispedges')
                    .style('stroke-dasharray', 4);
                d3.select(gRef.current)
                    .selectAll('.box-plot-median-label')
                    .data(medianData)
                    .enter()
                    .append('text')
                    .attr('class', (_d, i)=> `box-plot-median-label ${medianLabel[i].split(' ').join('_')}`)
                    .attr('x', 0)
                    .attr('y', (d) => yScale(d))
                    .attr('dy', (_d, i) => {
                        if (i=== 0 && (data.actual_duration_median > data.original_duration_median)) {
                            return -3;
                        } else if (i=== 0 && (data.actual_duration_median < data.original_duration_median)) {
                            return 10;
                        } else if (i=== 1 && (data.original_duration_median > data.actual_duration_median)){
                            return -3;
                        } else if (i=== 0 && (data.original_duration_median === data.actual_duration_median)){
                            return -3;
                        } else {
                            return 10;
                        }
                    })
                    .text((d, i) => {
                        return `${medianLabel[i]}(${d})`;
                    })
                    .style('font-size', '0.75rem')
                    .style('text-anchor', 'start');
                // To remove overlapping label text
                if (document.body.clientWidth <= 1440) {
                    if (data.original_duration_median === median) {
                        document.querySelector('.Qlabel.Median').classList.add('d-none');
                        document.querySelector('.box-plot-median-label.Planned_Median_Duration').textContent = `Planned Median Duration/Median (${median})`;
                    }
                    if (data.actual_duration_median === median) {
                        document.querySelector('.Qlabel.Median').classList.add('d-none');
                        document.querySelector('.box-plot-median-label.Actual_Median_Duration').textContent = `Actual Median Duration/Median (${median})`;
                    }
                    if (data.actual_duration_median === median && P10 === median) {
                        document.querySelector('.Qlabel.Median').classList.add('d-none');
                        document.querySelector('.Qlabel.P10').classList.add('d-none');
                        document.querySelector('.box-plot-median-label.Actual_Median_Duration').textContent = `Actual Median Duration/Median/P10 (${median})`;
                    }
                    if (data.original_duration_median === data.actual_duration_median && data.actual_duration_median === median) {
                        document.querySelector('.box-plot-median-label.Planned_Median_Duration').classList.add('d-none');
                        document.querySelector('.box-plot-median-label.Actual_Median_Duration').textContent = `Planned/Actual Median Duration/Median (${data.actual_duration_median})`;
                    }
                }
                const selectedActivityStatus = getSelectedActivityStatus();

                if (selectedActivityStatus.includes('Completed') && selectedActivityStatus.length === 1) {
                    d3.select(gRef.current).selectAll('.Planned_Median_Duration').style('display', 'none');
                } else if ((selectedActivityStatus.includes('In Progress') && selectedActivityStatus.includes('Not Started'))) {
                    d3.select(gRef.current).selectAll('.Actual_Median_Duration').style('display', 'none');
                } else if (selectedActivityStatus.includes('Completed') && selectedActivityStatus.includes('In Progress')) {
                    displayBothMedian()
                } else if (selectedActivityStatus.includes('Completed') && selectedActivityStatus.includes('Not Started')) {
                    displayBothMedian();
                }else if (selectedActivityStatus.includes('In Progress') || selectedActivityStatus.includes('Not Started')) {
                    d3.select(gRef.current).selectAll('.Actual_Median_Duration').style('display', 'none');
                } else {
                    displayBothMedian();
                }
            }

            //===============Zoom In/Out/Reset========================
            function handleZoom(e) {
                const transform = e.transform;
                const newYScale = transform.rescaleY(yScale);
                reDrawBoxPlot(newYScale);
            }

            function reDrawBoxPlot(newYScale) {
                yAxis.scale(newYScale)
                svg.select('.yAxis-box-plot').call(yAxis);
                d3.select(gRef.current)
                    .select('.whisker')
                    .attr('y1', newYScale(P0))
                    .attr('y2', newYScale(P100))
                d3.select(gRef.current)
                    .select('.box')
                    .attr('y', newYScale(q3))
                    .attr('height', (newYScale(q1) - newYScale(q3)))

                d3.select(gRef.current)
                    .selectAll('.lineboxplot')
                    .attr('x1', center - boxWidth / 2)
                    .attr('x2', center + boxWidth / 2)
                    .attr('y1', function (d) { return (newYScale(d)) })
                    .attr('y2', function (d) { return (newYScale(d)) })
                d3.select(gRef.current)
                    .selectAll('.Qlabel')
                    .attr('y', (d) => newYScale(d) + 9)
                d3.select(gRef.current)
                    .selectAll(".outlier-points")
                    .attr("cy", function (d) { return (newYScale(d)) })
                if (drawmedian) {
                    d3.select(gRef.current)
                        .selectAll('.median-line')
                        .attr('x1', 0)
                        .attr('x2', center + boxWidth / 2)
                        .attr('y1', function (d) { return (newYScale(d)) })
                        .attr('y2', function (d) { return (newYScale(d)) });
                    d3.select(gRef.current)
                        .selectAll('.box-plot-median-label')
                        .attr('y', (d) => newYScale(d))
                }
            }

            svgRef.current.parentElement.parentElement.parentElement.parentElement.addEventListener('fullscreenchange', (_e) => {
                if (document.fullscreenElement) {
                    width = window.innerWidth * 40 / 100;
                    height = window.innerHeight - 100;
                    updateOnFS()
                } else {
                    width = (plannieUtils.getFixedPlotDimension().width - margin.left - margin.right - 20) * 40 / 100;;
                    height = 400;
                    updateOnFS()
                }
            });

            function updateOnFS() {
                d3.select(svgRef.current)
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                yScale.range([height, 0]);
                reDrawBoxPlot(yScale);
            }
        }
        }//End of main if condition
    });
    function zoomReset() {
        d3.select(svgRef.current).transition().duration(500).call(zooms.current.transform, d3.zoomIdentity);
    }
    function zoomOut() {
        d3.select(svgRef.current).transition().duration(500).call(zooms.current.scaleBy, 0.5);
    }
    function zoomIn() {
        d3.select(svgRef.current).transition().duration(500).call(zooms.current.scaleBy, 2);
    }
    return (
        <div>
            {
                data.P10 !== undefined && !checkIfAllvalueZero(data) ? 
                <>
                    <svg className={`svg-box-plot ${boxPlotName}`} ref={svgRef} width={'100%'} height={400}>
                        <foreignObject ref={foreignObjRef} width={100} height={30}>
                            <div className='zoom-menu'>
                                <div className='d-flex'>
                                    <button type="button" onClick={zoomIn} className="btn btn-sm icon-link icon-link-hover" title={"Zoom In"} style={{ '--bs-icon-link-transform': "translate3d(0, -.125rem, 0)" }}>
                                        <svg width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zoom-in"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line><line x1="11" y1="8" x2="11" y2="14"></line><line x1="8" y1="11" x2="14" y2="11"></line></svg>
                                    </button>
                                    <button type="button" onClick={zoomOut} className="btn btn-sm icon-link icon-link-hover" title={"Zoom Out"} style={{ '--bs-icon-link-transform': "translate3d(0, -.125rem, 0)" }}>
                                        <svg width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zoom-out"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line><line x1="8" y1="11" x2="14" y2="11"></line></svg>
                                    </button>
                                    <button type="button" onClick={zoomReset} className="btn btn-sm icon-link icon-link-hover" title={"Zoom Reset"} style={{ '--bs-icon-link-transform': "translate3d(0, -.125rem, 0)" }}>
                                        <svg width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-cw">
                                            <polyline points="23 4 23 10 17 10"></polyline>
                                            <polyline points="1 20 1 14 7 14"></polyline>
                                            <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </foreignObject>
                        <g ref={gRef}>
                            <g ref={xAxisRef}></g>
                            <g ref={yAxisRef}></g>
                            <text ref={yLabel}></text>
                        </g>
                    </svg>
                
                    <div className='table-responsive px-2 mt-2'>
                        <table className="table table-sm boxplot-table mb-0">
                            <thead>
                                <tr><th></th><th className='font-weight-bold'>Key Statistics (in days)</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>P10</td><td>{data.P10}</td></tr>
                                <tr><td>Median</td><td>{data.P50}</td></tr>
                                <tr><td>P90</td><td>{data.P90}</td></tr>
                                <tr><td>Outliers</td><td>{data.outlier_values.length}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </>
                
                :
                <div className='small text-warning font-italic my-4 ml-2'>No data found</div>
            }
        </div>
    );
}

BoxPlot.propTypes = {
    data: PropTypes.object,
    yLabelText: PropTypes.string,
    boxPlotFilerCallback: PropTypes.func,
    drawmedian: PropTypes.bool,
    boxPlotName: PropTypes.string
}
