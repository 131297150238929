import React, { useEffect, useRef } from 'react';
import utils from '../app-util';


function PlannieSearchAdvanced({  advanceSearchCallback, mustIncludeExcludeCallback }) {
    const elemRef = useRef(null);
    const mInclRef = useRef(null);
    const mExclRef = useRef(null);
    useEffect(() => {

    });
    function clearBarChartHighlight() {
        document.querySelector('svg#plannie-actvityduration-plot').classList.remove('clickactive');
        document.querySelector('svg#plannie-float-plot').classList.remove('clickactive');
    }
    function parentCallbackFunc(data) {
        advanceSearchCallback(data);
       
    }
    function clearAllOnClick() {
        advanceSearchCallback();
        const lists = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR)
        lists.forEach(function (element) {
            element.remove();
        });
        // If searched with must include, after clearing all it should give the result of prev query
        if (mInclRef.current.classList.contains("active") || mExclRef.current.classList.contains("active")) {
            mInclRef.current.classList.remove("active");
            mExclRef.current.classList.remove("active");
            mInclRef.current.value = "";
            mExclRef.current.value = "";
            document.querySelector('.search-input-field').value = window.sessionStorage.getItem('_prevquery');
            document.querySelector('#search-icon-btn').click();
        }

    }
    function onkeyPress(e) {//Advanced Search

        if (e.currentTarget.value !== "" && e.key === 'Enter') {
            clearBarChartHighlight();
            /** 
             * if mustincude query searched then mark it as "active" that current search has been made using must include keyword, 
             * so that we can implement clear all button functionality
             */
            e.currentTarget.classList.add('active');
            window.sessionStorage.setItem('_prevquery', window.sessionStorage.getItem('query'));
            mustIncludeExcludeCallback({ mIncl: mInclRef.current.value, mExcl: mExclRef.current.value });
        }
    }

    function onCloseClick() {
        if(!mInclRef.current.classList.contains('active')){
            mInclRef.current.value = "";
        }
        if(!mExclRef.current.classList.contains('active')){
            mExclRef.current.value = "";
        }
        const elem = document.querySelector('.advanced-search-wrapper');
        if (elem.classList.contains("active")) {
            elem.classList.remove("active");
        }
    }

    return (
        <div className="mb-3 advanced-search-wrapper tour-advancedsearch-mustinclude" ref={elemRef}>
            <div className="component-title mb-2 d-flex justify-content-between"><span className='c-title'>Advanced Search</span>
                <button className="advance-search-clear-btn btn" style={{ cursor: "pointer" }} onClick={clearAllOnClick} title="Clear Advanced Search">Clear Must Include/Exclude</button>
            </div>
            <div className="card card-box-shadow">
                <div className="">
                    <button className="adv-search-close btn btn-sm" onClick={onCloseClick} title="Close advanced search panel">&times;</button>
                    <div className="form-group material-form-group mb-2">
                        <div className='p-2'>
                            <label className="mb-0 small" htmlFor='mustInclude'>Must Include</label>
                            <input className="form-control material-form-control must-include" id='mustInclude' ref={mInclRef} onKeyDown={onkeyPress} placeholder="Type here" />
                        </div>

                    </div>
                    <div className="form-group material-form-group mb-2">
                        <div className='p-2'>
                            <label className="mb-0 small" htmlFor='mustExclude'>Must Exclude</label>
                            <input className="form-control material-form-control must-exclude" id='mustExclude' ref={mExclRef} onKeyDown={onkeyPress} placeholder="Type here" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default PlannieSearchAdvanced;
