import React, { useEffect } from 'react';
import { FormControlLabel, Divider, Checkbox, Paper, Box, colors } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const CustomPaperSelectAllComponent = (paperProps, handleToggleSelectAll, selectAll) => {
    const { children, ...restPaperProps } = paperProps;
    return (
        <Paper {...restPaperProps}>
            <Box
                onMouseDown={(e) => e.preventDefault()} // prevent blur
                pl={1.5}
                py={0.5}
            >
                <FormControlLabel
                    style={{ fontFamily: "Futura" }}
                    onClick={(e) => {
                        e.preventDefault(); // prevent blur
                        handleToggleSelectAll();
                    }}
                    label="Select all"
                    control={
                        <Checkbox
                            id="select-all-checkbox"
                            size='small'
                            checked={selectAll}
                            sx={{
                                color: colors.red[800],
                                '&.Mui-checked': {
                                    color: colors.red[600],
                                },
                            }}
                        />
                    }
                />
            </Box>
            <Divider />
            {children}
        </Paper>
    );
}

export default function PlannieDropdown(props) {
    const labels = {"region": "Region", "lob":"LoB", "wbsname": "WBS Name", "activitytype": "Activity Type", "activitystatus": "Activity Status", "projectname": "Project Name", "critical": "Criticality Status" }

    const [selected, setSelected] = React.useState([]);
    

    const [selectAll, setSelectAll] = React.useState(false);
    const [allOptions, setAllOptions] = React.useState(props.data);
    const handleToggleSelectAll = () => {
        setSelectAll((prev) => {
            if (!prev) {
                setSelected(allOptions)
            }
            else {
                setSelected([]);
            }
            return !prev;
        });
    };

    useEffect(() => {
        setAllOptions(props.data);
        if (props.isClear) {
            setSelected([]);
        }
    }, [props]);

    const handleChange = (_event, newValue, reason) => {
        if (reason === "selectOption") {
            setSelected(newValue);
            setSelectAll(false);
        }
        if (reason === "clear" || reason === "removeOption") {
            setSelectAll(false);
            setSelected(newValue);
        }
        if (reason === "selectOption" && newValue.length === allOptions.length) {
            setSelectAll(true);
            setSelected(newValue);
        }
        props.onChange(newValue, props.filterType, reason);
    }
    function clearFilter() {
        setSelected([]);
    }
    return (
        <div className={`select-container my-2 select-${props.filterType}`}>
            <button className={`clear-${props.filterType} sr-only`} onClick={clearFilter}></button>
            <Autocomplete
                multiple
                limitTags={2}
                size="small"
                id="checkboxes-tags-demo"
                options={allOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => {
                    return option === "" ? "-NA-" : option;
                }}
                value={selected}
                onChange={handleChange}
                renderOption={(_props, option, { selected }) => (
                    <li {..._props}>
                        <Checkbox
                            size='small'
                            checked={selected}
                            sx={{
                                color: colors.red[800],
                                '&.Mui-checked': {
                                    color: colors.red[600],
                                },
                            }}
                        />
                        <div className='d-flex justify-content-between' style={{ fontSize: '12px' }}>
                            <div className=''>{option === "" ? "Legacy data(Not Mandatory)" : option}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            <div className=''>({props.count[option]})</div>
                        </div>
                    </li>
                )}
                style={{ width: 205, marginLeft: '2px' }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={labels[props.filterType]} placeholder="Please Select" />
                )}
                PaperComponent={(paperProps)=>{
                    return CustomPaperSelectAllComponent(paperProps, handleToggleSelectAll, selectAll)
                }}
            />
        </div>
    );
}
