import utils from "../app-util";
import { intersection } from 'lodash';

const PLANNIE_ITEM_SELECTOR = '.plannie-item';

function getPlannieResultCardItem(PLANNIE_ITEM_SELECTOR) {
    return document.querySelectorAll(PLANNIE_ITEM_SELECTOR);
}

function displyCard(element) {
    element.classList.remove('d-none');
    element.classList.add('d-block');
}
function hideCard(element) {
    element.classList.add('d-none');
    element.classList.remove('d-block');
}
function ifTopNFilterActive() {
    const topNVal = document.querySelector("#topNFilter").value;
    return [["10", "20", "30"].includes(topNVal), topNVal];
}
function isDatRangeSliderActive(value=[]) {
    const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
    const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
    const input = inputs[0];
    const min = parseInt(input.getAttribute("min"), 10), max = parseInt(input.getAttribute("max"), 10);
    if(value.length > 0){
        return (min !== value[0] && max !== value[1]) || (min === value[0] && max !== value[1]) || (min !== value[0] && max === value[1]);
    } else {
        return ((min !== selectedMinVal && max !== selectedMaxVal) || (min === selectedMinVal && max !== selectedMaxVal) || (min !== selectedMinVal && max === selectedMaxVal));
    }
}
function getListBetweenDateRange(dateRange, cardList) {
    let cardElementLists = [];
    if(cardList === undefined) {
        cardElementLists = getPlannieResultCardItem(PLANNIE_ITEM_SELECTOR)
    } else {
        cardElementLists = cardList;
    }
    const filteredList = []
    cardElementLists.forEach(function (element) {
        const dateToCompare = parseInt(element.dataset.projectdate, 10);
        if (dateToCompare >= dateRange[0] && dateToCompare <= dateRange[1]) {
            filteredList.push(element);
        }
    });
    return filteredList;
}

function isFilterSelected(tagNames) {
    let containsTag = false;
    for (const key in tagNames) {
        if (tagNames[key].length > 0) {
            containsTag = true;
            break;
        }
    }
    return containsTag;
}


/**
 * The function filters the search results based on the tagname passed as argument
 * @param {String} tagName 
 * @param {String} filterType 
 * @param {Array} sliderDateRange -
 * @param {NodeList} returnResultElement
 * @returns return the count of filtered results
 */
// function getPlannieFilterResult(tagNames, filterType, cardElementLists) {
function getPlannieFilterResult(tagNames, filterType, sliderDateRange, returnResultElement=false) {
    let count = 0;
    let cardElementLists = null;
    // if (cardElementLists === undefined) {
        let isTopNactive, topNVal;
        [isTopNactive, topNVal] = ifTopNFilterActive();

        // If topN filter active select the list of cards to be filtered
        if (sliderDateRange === undefined && (isTopNactive && Array.isArray(tagNames)) && (tagNames.length > 0 || tagNames.length === 0)) {
            console.log("Only TopN")
            cardElementLists = getTheListIfTopNActive(topNVal);
        } else if(sliderDateRange !== undefined && isDatRangeSliderActive(sliderDateRange) && isTopNactive){
            console.log("Only TopN and date")
            cardElementLists = getTheListIfTopNActive(topNVal);
            cardElementLists = getListBetweenDateRange(sliderDateRange, cardElementLists)
        } else if(sliderDateRange !== undefined && isDatRangeSliderActive(sliderDateRange) ){
            console.log("Only date")
            cardElementLists = getListBetweenDateRange(sliderDateRange);
        } else {
            let isMultipleOPtionSelected = 0
            for (const key in tagNames) {
                if (tagNames[key].length > 0) {
                    isMultipleOPtionSelected = isMultipleOPtionSelected + 1;
                }
            }
            if(isMultipleOPtionSelected > 1){
                cardElementLists = document.querySelectorAll(PLANNIE_ITEM_SELECTOR + '.d-block');
            }else {
                cardElementLists = document.querySelectorAll(PLANNIE_ITEM_SELECTOR);
            }
        }
    // }
    // const filteredResultsIds = [], filteredResultElements = [];

    // const wbsName = [], activityType = [], activityStatus = [], dateFilterData = [], lob = [], region = [];
    // const dropdownFilterData = {
    //         // primavera:{
    //             Wbs_Name: wbsName,
    //             Activity_Type: activityType,
    //             Activity_Status: activityStatus,
    //             LOB: lob,
    //             Region: region
    //         // }
    // };

    // if (typeof (tagNames) === 'object') {
    //     if(isFilterSelected(tagNames)){
    //         for (const key in tagNames) {
    //             if (tagNames[key].length > 0) {
    //                 cardElementLists.forEach(function (element) {
    //                     tagNames[key].forEach(function (params) {
    //                         if (element.dataset[key].includes(params)) {
    //                             filteredResultsIds.push(element.dataset.id);
    //                             filteredResultElements.push(element);

    //                             wbsName.push(element.dataset.wbsname);
    //                             activityType.push(element.dataset.activitytype);
    //                             activityStatus.push(element.dataset.activitystatus);
    //                             lob.push(element.dataset.lob);
    //                             region.push(element.dataset.region);
    //                         }
    //                     });
    //                 });
    //             }
    //         }
    //         count = filterUsingID([...new Set(cardElementLists)], filteredResultsIds)
    //     } else {
        
    //         cardElementLists.forEach(function (element) {
    //             count += 1;
    //             displyCard(element);
    //             filteredResultElements.push(element);

    //             wbsName.push(element.dataset.wbsname);
    //             activityType.push(element.dataset.activitytype);
    //             activityStatus.push(element.dataset.activitystatus);
    //             lob.push(element.dataset.lob);
    //             region.push(element.dataset.region);
    //         });
    //     }
    // }

    // if (returnResultElement) {
    //     return [...new Set(filteredResultElements)];
    // } else {
    //     return [count, dropdownFilterData, dateFilterData];
        
    // }

    return cardElementLists;
}
function filterUsingID(allCards, ids) {
    let count = 0;
    const uniqueIDs = [...new Set(ids)];
    allCards.forEach(function (element) {
        if (uniqueIDs.includes(element.dataset.id)) {
            count += 1;
            displyCard(element);
        } else {
            hideCard(element);
        }
    });
    return count;
}
/**
 * Display all the result cards by removing the class 'd-none'
 * @returns total count of search results
 */
function displayAllPlannieCard() {
    let count = 0;
    document.querySelectorAll(PLANNIE_ITEM_SELECTOR).forEach(function (element) {
        displyCard(element);
        count += 1;
    });
    return count;
}
/**
 * Clear the filters on leftsidebar dropdown filters
 */
function plannieFilterOnClear() {
    document.querySelectorAll('.plannie-filter-combobox input').forEach(function (element) {
        element.value = '';
    });
    // document.querySelector(".date-filter-wrapper.plannie #sinceyearquality").value = '';
    // document.querySelector(".date-filter-wrapper.quality .react-daterange-picker__clear-button").click();
}


function getCardResultIfFullMatchActive(cardResult, fullMatchValue) {
    const filteredResult = [];
    if (fullMatchValue) {
        cardResult.forEach(function (card) {
            const datasetvalue = fullMatchValue ? 'Yes' : 'No';
            if (card.dataset.isexactmatch === datasetvalue) {
                filteredResult.push(card);
            }
        });
        return filteredResult;
    } else {
        return cardResult;
    }
}
function getTheListOfTopNIfFullMatchActive(fullMatchResult, topNValue){
    return fullMatchResult.slice(0, parseInt(topNValue, 10));
}
/**
 * 
 * @param {*} data Id list
 * @param {*} selectedActiveFilters Pass the selected filter value if Lob, Region, Wbs name, Activity Type and Activity status filter active
 * @returns 
 */
function stackedBarFilterOnCall(data, selectedActiveFilters, isBoxplot, whichBoxPlotCall) {
    // console.log(selectedActiveFilters);
    let plannieCards = document.querySelectorAll(PLANNIE_ITEM_SELECTOR), idList = data.id.split(',');
    let count = 0
    if (data.filter) {
        count = filterCards(plannieCards, idList)
    } else {
        const selector = whichBoxPlotCall === 'duration-boxplot'? 'svg#plannie-actvityduration-plot' : 'svg#plannie-float-plot';
        if (isBoxplot && document.querySelector(selector).classList.contains('clickactive')) {
            const idList = document.querySelector(`${selector} rect.active`).dataset.ids.split(',');
            count = filterCards(plannieCards, idList);
        } else {

        let isTopNactive, topNVal;
        [isTopNactive, topNVal] = ifTopNFilterActive();
        const fullMatchValue = document.querySelector("#fullmatch").checked;
        const isFullMatchActive = fullMatchValue; // to check if fullmatch selected
        if (isFullMatchActive && isTopNactive && isDatRangeSliderActive() && isFilterSelected(selectedActiveFilters)) {
            const fullmatchResultCard = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
            const topNList = getTheListOfTopNIfFullMatchActive(fullmatchResultCard, topNVal);

            // Get list if date range selected
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            const listFilteredByDateRange = getListBetweenDateRange([selectedMinVal, selectedMaxVal], topNList)


            let intersectionIDs = onPlotDeselection(listFilteredByDateRange, selectedActiveFilters);
            const filteredElement = [...listFilteredByDateRange].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;

        }
        else if (isFullMatchActive && isTopNactive && isDatRangeSliderActive()) {
            const fullmatchResultCard = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
            const topNList = getTheListOfTopNIfFullMatchActive(fullmatchResultCard, topNVal);

            // Get list if date range selected
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            plannieCards = getListBetweenDateRange([selectedMinVal, selectedMaxVal], topNList)
        } else if (isFullMatchActive && isTopNactive && isFilterSelected(selectedActiveFilters)) {
            const fullmatchResultCard = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
            const topNList = getTheListOfTopNIfFullMatchActive(fullmatchResultCard, topNVal);

            let intersectionIDs = onPlotDeselection(topNList, selectedActiveFilters);
            const filteredElement = [...topNList].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;
        } else if (isFullMatchActive && isDatRangeSliderActive() && isFilterSelected(selectedActiveFilters)) {
            const fullmatchResultCard = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
            
            // Get list if date range selected
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            const listFilteredByDateRange = getListBetweenDateRange([selectedMinVal, selectedMaxVal], fullmatchResultCard)


            let intersectionIDs = onPlotDeselection(listFilteredByDateRange, selectedActiveFilters);
            const filteredElement = [...listFilteredByDateRange].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;

        } else if (isTopNactive && isDatRangeSliderActive() && isFilterSelected(selectedActiveFilters)) {

            const topNList = getTheListIfTopNActive(topNVal);

            // Get list if date range selected
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            const listFilteredByDateRange = getListBetweenDateRange([selectedMinVal, selectedMaxVal], topNList)


            let intersectionIDs = onPlotDeselection(listFilteredByDateRange, selectedActiveFilters);
            const filteredElement = [...listFilteredByDateRange].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;

        } else if (isFullMatchActive && isTopNactive) {
            const fullmatchResultCard = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
            plannieCards = getTheListOfTopNIfFullMatchActive(fullmatchResultCard, topNVal);

        } else if (isFullMatchActive && isDatRangeSliderActive()) {
            const fullmatchResultCard = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
            
            // Get list if date range selected
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            plannieCards = getListBetweenDateRange([selectedMinVal, selectedMaxVal], fullmatchResultCard);

        }else if (isFullMatchActive && isFilterSelected(selectedActiveFilters)) {
            const fullmatchResultCard = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
            
            // Pass the list to be filtered based on dropdown filter selection
            let intersectionIDs = onPlotDeselection(fullmatchResultCard, selectedActiveFilters);
            const filteredElement = [...fullmatchResultCard].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;

        } else if (isTopNactive && isDatRangeSliderActive()) {
            const topNList = getTheListIfTopNActive(topNVal);

            // Get list if date range selected
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            plannieCards = getListBetweenDateRange([selectedMinVal, selectedMaxVal], topNList)

        } else if (isTopNactive && isFilterSelected(selectedActiveFilters)) {
            const topNList = getTheListIfTopNActive(topNVal);

            // Pass the list to be filtered based on dropdown filter selection
            let intersectionIDs = onPlotDeselection(topNList, selectedActiveFilters);
            const filteredElement = [...topNList].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;
        } else if (isDatRangeSliderActive() && isFilterSelected(selectedActiveFilters)) {
            // Get list if date range selected
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            const listFilteredByDateRange = getListBetweenDateRange([selectedMinVal, selectedMaxVal], plannieCards)
            
            // Pass the list to be filtered based on dropdown filter selection
            let intersectionIDs = onPlotDeselection(listFilteredByDateRange, selectedActiveFilters);
            const filteredElement = [...listFilteredByDateRange].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;

        } else if(isFullMatchActive){
            plannieCards = getCardResultIfFullMatchActive(plannieCards, fullMatchValue)
        } else if (isTopNactive) {
            plannieCards = getTheListIfTopNActive(topNVal);
        } else if(isDatRangeSliderActive()) {
            const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
            const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
            plannieCards = getListBetweenDateRange([selectedMinVal, selectedMaxVal], plannieCards)
        } else if(isFilterSelected(selectedActiveFilters)) {
            let intersectionIDs = onPlotDeselection(plannieCards, selectedActiveFilters);
            const filteredElement = [...plannieCards].filter((item) => {
                return intersectionIDs.includes(item.dataset.id)
            });
            plannieCards = filteredElement;
        }

        plannieCards.forEach(function (element) {
            displyCard(element)
            count += 1;
        });
        }
    }
    return count;
}

function onPlotDeselection(plannieCards, selectedActiveFilters) {
    const region = [], lob = [], wbsname = [], activitytype = [], activitystatus = [], projectname = [], critical = [];
    if (selectedActiveFilters['region'].length > 0) {
        plannieCards.forEach((item) => {
            if (selectedActiveFilters['region'].includes(item.dataset.region)) {
                region.push(item.dataset.id);
            }
        });
    }
    if (selectedActiveFilters['activitystatus'].length > 0) {
        plannieCards.forEach((item) => {
            if (selectedActiveFilters['activitystatus'].includes(item.dataset.activitystatus)) {
                activitystatus.push(item.dataset.id);
            }
        });
    }
    if (selectedActiveFilters['lob'].length > 0) {
        plannieCards.forEach((item) => {
            if (selectedActiveFilters['lob'].includes(item.dataset.lob)) {
                lob.push(item.dataset.id);
            }
        });
    }
    if (selectedActiveFilters['wbsname'].length > 0) {
        plannieCards.forEach((item) => {
            if (selectedActiveFilters['wbsname'].includes(item.dataset.wbsname)) {
                wbsname.push(item.dataset.id);
            }
        });
    }
    if (selectedActiveFilters['activitytype'].length > 0) {
        plannieCards.forEach((item) => {
            if (selectedActiveFilters['activitytype'].includes(item.dataset.activitytype)) {
                activitytype.push(item.dataset.id);
            }
        });
    }
    if (selectedActiveFilters['projectname'].length > 0) {
        plannieCards.forEach((item) => {
            if (selectedActiveFilters['projectname'].includes(item.dataset.projectname)) {
                projectname.push(item.dataset.id);
            }
        });
    }
    if (selectedActiveFilters['critical'].length > 0) {
        plannieCards.forEach((item) => {
            if (selectedActiveFilters['critical'].includes(item.dataset.critical)) {
                critical.push(item.dataset.id);
            }
        });
    }
    const mappedparams = { paramA: region, paramB: lob, paramC: projectname, paramD: wbsname, paramE: activitytype, paramF: activitystatus, paramG: critical }
    const { paramA, paramB, paramC, paramD, paramE, paramF, paramG } = mappedparams; //{ paramA: lob, paramB: projectname, paramC: wbsname, paramD: activitytype, paramE: activitystatus, paramF: critical, paramG: region };
    if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramD, paramE, paramF, paramG); }

    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramC, paramD, paramE, paramF); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramD, paramE, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramD, paramF, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramE, paramF, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramD, paramE, paramF, paramG); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramD, paramE, paramF, paramG); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramD, paramE, paramF, paramG); }

    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramA, paramB, paramC, paramD, paramE); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramC, paramD, paramF); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramD, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramC, paramE, paramF); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramE, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramF, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramD, paramE, paramF); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramD, paramE, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramD, paramF, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramE, paramF, paramG); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramC, paramD, paramE, paramF); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramD, paramE, paramG); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramD, paramF, paramG); }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramE, paramF, paramG); }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramD, paramE, paramF, paramG); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramB, paramC, paramD, paramE, paramF); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramD, paramE, paramG); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramD, paramF, paramG); }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramE, paramF, paramG); }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramD, paramE, paramF, paramG); }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramC, paramD, paramE, paramF, paramG); }

    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramD.length > 0) { return intersection(paramA, paramB, paramC, paramD); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramE.length > 0) { return intersection(paramA, paramB, paramC, paramE); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramC, paramF); }
    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramC, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramA, paramB, paramD, paramE); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramD, paramF); }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramD, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramE, paramF); }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramE, paramG); }
    else if (paramA.length > 0 && paramB.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramF, paramG); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramA, paramC, paramD, paramE); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramA, paramC, paramD, paramF); }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramD, paramG); }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramC, paramE, paramF); }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramE, paramG); }
    else if (paramA.length > 0 && paramC.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramF, paramG); }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramD, paramE, paramF); }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramD, paramE, paramG); }
    else if (paramA.length > 0 && paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramD, paramF, paramG); }
    else if (paramA.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramE, paramF, paramG); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramB, paramC, paramD, paramE); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramB, paramC, paramD, paramF); }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramD, paramG); }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramB, paramC, paramE, paramF); }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramE, paramG); }
    else if (paramB.length > 0 && paramC.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramF, paramG); }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramB, paramD, paramE, paramF); }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramB, paramD, paramE, paramG); }
    else if (paramB.length > 0 && paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramD, paramF, paramG); }
    else if (paramB.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramE, paramF, paramG); }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramC, paramD, paramE, paramF); }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramC, paramD, paramE, paramG); }
    else if (paramC.length > 0 && paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramC, paramD, paramF, paramG); }
    else if (paramC.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramC, paramE, paramF, paramG); }
    else if (paramD.length > 0 && paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramD, paramE, paramF, paramG); }

    else if (paramA.length > 0 && paramB.length > 0 && paramC.length > 0) { return intersection(paramA, paramB, paramC) }
    else if (paramA.length > 0 && paramB.length > 0 && paramD.length > 0) { return intersection(paramA, paramB, paramD) }
    else if (paramA.length > 0 && paramB.length > 0 && paramE.length > 0) { return intersection(paramA, paramB, paramE) }
    else if (paramA.length > 0 && paramB.length > 0 && paramF.length > 0) { return intersection(paramA, paramB, paramF) }
    else if (paramA.length > 0 && paramB.length > 0 && paramG.length > 0) { return intersection(paramA, paramB, paramG) }
    else if (paramA.length > 0 && paramC.length > 0 && paramD.length > 0) { return intersection(paramA, paramC, paramD) }
    else if (paramA.length > 0 && paramC.length > 0 && paramE.length > 0) { return intersection(paramA, paramC, paramE) }
    else if (paramA.length > 0 && paramC.length > 0 && paramF.length > 0) { return intersection(paramA, paramC, paramF) }
    else if (paramA.length > 0 && paramC.length > 0 && paramG.length > 0) { return intersection(paramA, paramC, paramG) }
    else if (paramA.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramA, paramD, paramE) }
    else if (paramA.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramA, paramD, paramF) }
    else if (paramA.length > 0 && paramD.length > 0 && paramG.length > 0) { return intersection(paramA, paramD, paramG) }
    else if (paramA.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramA, paramE, paramF) }
    else if (paramA.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramA, paramE, paramG) }
    else if (paramA.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramA, paramF, paramG) }
    else if (paramB.length > 0 && paramC.length > 0 && paramD.length > 0) { return intersection(paramB, paramC, paramD) }
    else if (paramB.length > 0 && paramC.length > 0 && paramE.length > 0) { return intersection(paramB, paramC, paramE) }
    else if (paramB.length > 0 && paramC.length > 0 && paramF.length > 0) { return intersection(paramB, paramC, paramF) }
    else if (paramB.length > 0 && paramC.length > 0 && paramG.length > 0) { return intersection(paramB, paramC, paramG) }
    else if (paramB.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramB, paramD, paramE) }
    else if (paramB.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramB, paramD, paramF) }
    else if (paramB.length > 0 && paramD.length > 0 && paramG.length > 0) { return intersection(paramB, paramD, paramG) }
    else if (paramB.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramB, paramE, paramF) }
    else if (paramB.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramB, paramE, paramG) }
    else if (paramB.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramB, paramF, paramG) }
    else if (paramC.length > 0 && paramD.length > 0 && paramE.length > 0) { return intersection(paramC, paramD, paramE) }
    else if (paramC.length > 0 && paramD.length > 0 && paramF.length > 0) { return intersection(paramC, paramD, paramF) }
    else if (paramC.length > 0 && paramD.length > 0 && paramG.length > 0) { return intersection(paramC, paramD, paramG) }
    else if (paramC.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramC, paramE, paramF) }
    else if (paramC.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramC, paramE, paramG) }
    else if (paramC.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramC, paramF, paramG) }
    else if (paramD.length > 0 && paramE.length > 0 && paramF.length > 0) { return intersection(paramD, paramE, paramF) }
    else if (paramD.length > 0 && paramE.length > 0 && paramG.length > 0) { return intersection(paramD, paramE, paramG) }
    else if (paramD.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramD, paramF, paramG) }
    else if (paramE.length > 0 && paramF.length > 0 && paramG.length > 0) { return intersection(paramE, paramF, paramG) }

    else if (paramA.length > 0 && paramB.length > 0) { return intersection(paramA, paramB) }
    else if (paramA.length > 0 && paramC.length > 0) { return intersection(paramA, paramC) }
    else if (paramA.length > 0 && paramD.length > 0) { return intersection(paramA, paramD) }
    else if (paramA.length > 0 && paramE.length > 0) { return intersection(paramA, paramE) }
    else if (paramA.length > 0 && paramF.length > 0) { return intersection(paramA, paramF) }
    else if (paramA.length > 0 && paramG.length > 0) { return intersection(paramA, paramG) }
    else if (paramB.length > 0 && paramC.length > 0) { return intersection(paramB, paramC) }
    else if (paramB.length > 0 && paramD.length > 0) { return intersection(paramB, paramD) }
    else if (paramB.length > 0 && paramE.length > 0) { return intersection(paramB, paramE) }
    else if (paramB.length > 0 && paramF.length > 0) { return intersection(paramB, paramF) }
    else if (paramB.length > 0 && paramG.length > 0) { return intersection(paramB, paramG) }
    else if (paramC.length > 0 && paramD.length > 0) { return intersection(paramC, paramD) }
    else if (paramC.length > 0 && paramE.length > 0) { return intersection(paramC, paramE) }
    else if (paramC.length > 0 && paramF.length > 0) { return intersection(paramC, paramF) }
    else if (paramC.length > 0 && paramG.length > 0) { return intersection(paramC, paramG) }
    else if (paramD.length > 0 && paramE.length > 0) { return intersection(paramD, paramE) }
    else if (paramD.length > 0 && paramF.length > 0) { return intersection(paramD, paramF) }
    else if (paramD.length > 0 && paramG.length > 0) { return intersection(paramD, paramG) }
    else if (paramE.length > 0 && paramF.length > 0) { return intersection(paramE, paramF) }
    else if (paramE.length > 0 && paramG.length > 0) { return intersection(paramE, paramG) }
    else if (paramF.length > 0 && paramG.length > 0) { return intersection(paramF, paramG) }

    else if (paramA.length > 0) { return paramA; }
    else if (paramB.length > 0) { return paramB; }
    else if (paramC.length > 0) { return paramC; }
    else if (paramD.length > 0) { return paramD; }
    else if (paramE.length > 0) { return paramE; }
    else if (paramF.length > 0) { return paramF; }
    else if (paramG.length > 0) { return paramG; }

    // if (region.length > 0 && activitystatus.length > 0 && lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
    //     return intersection(region, activitystatus, lob, wbsname, activitytype);
    // }
    // else if (region.length > 0 && lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
    //     return intersection(region, lob, wbsname, activitytype);
    // } else if (region.length > 0 && lob.length > 0 && wbsname.length > 0 && activitystatus.length > 0) {
    //     return intersection(region, lob, wbsname, activitystatus);
    // } else if (lob.length > 0 && wbsname.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
    //     return intersection(lob, wbsname, activitystatus, activitytype);
    // } // combination of three
    // else if (region.length > 0 && lob.length > 0 && wbsname.length > 0) {
    //     return intersection(region, lob, wbsname);
    // } else if (region.length > 0 && lob.length > 0 && activitytype.length > 0) {
    //     return intersection(region, lob, activitytype);
    // } else if (region.length > 0 && lob.length > 0 && activitystatus.length > 0) {
    //     return intersection(region, lob, activitystatus);
    // } else if (region.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
    //     return intersection(region, wbsname, activitytype);
    // } else if (region.length > 0 && wbsname.length > 0 && activitystatus.length > 0) {
    //     return intersection(region, wbsname, activitystatus);
    // } else if (region.length > 0 && activitytype.length > 0 && activitystatus.length > 0) {
    //     return intersection(region, activitytype, activitystatus);
    // }
    // else if (lob.length > 0 && wbsname.length > 0 && activitytype.length > 0) {
    //     return intersection(lob, wbsname, activitytype);
    // } else if (lob.length > 0 && wbsname.length > 0 && activitystatus.length > 0) {
    //     return intersection(lob, wbsname, activitystatus);
    // } else if (lob.length > 0 && activitytype.length > 0 && activitystatus.length > 0) {
    //     return intersection(lob, activitytype, activitystatus);
    // } else if (wbsname.length > 0 && activitystatus.length > 0 && activitytype.length > 0) {
    //     return intersection(wbsname, activitystatus, activitytype);
    // }  // combination of two
    // else if (activitytype.length > 0 && wbsname.length > 0) {
    //     return intersection(activitytype, wbsname);
    // } else if (activitystatus.length > 0 && wbsname.length > 0) {
    //     return intersection(activitystatus, wbsname);
    // } else if (activitystatus.length > 0 && activitytype.length > 0) {
    //     return intersection(activitystatus, activitytype);
    // } else if (lob.length > 0 && wbsname.length > 0) { // lob combination
    //     return intersection(lob, wbsname);
    // } else if (lob.length > 0 && activitytype.length > 0) {
    //     return intersection(lob, activitytype);
    // } else if (activitystatus.length > 0 && lob.length > 0) {
    //     return intersection(activitystatus, lob);
    // } else if (region.length > 0 && lob.length > 0) { // region combination
    //     return intersection(region, lob);
    // } else if (region.length > 0 && wbsname.length > 0) {
    //     return intersection(region, wbsname);
    // } else if (region.length > 0 && activitytype.length > 0) {
    //     return intersection(region, activitytype);
    // } else if (region.length > 0 && activitystatus.length > 0) {
    //     return intersection(region, activitystatus);
    // } else if (activitystatus.length > 0) { // One selection
    //     return activitystatus;
    // } else if (lob.length > 0) {
    //     return lob;
    // } else if (wbsname.length > 0) {
    //     return wbsname;
    // } else if (activitytype.length > 0) {
    //     return activitytype;
    // } else if (region.length > 0) {
    //     return region;
    // } 
    else {
        // If no filter selected get all ids
        return [...plannieCards].map((item) => item.dataset.id);
    }
}

function filterCards(emoccardList, idList) {
    let count = 0;
    emoccardList.forEach(function (element) {
        if (idList.includes(element.dataset.id)) {
            displyCard(element);
            count += 1;
        } else {
            hideCard(element);
        }
    });
    return count;
}

function loadFilteredDataForPlanPlots(url, getGraphData, setLoading, setUniqueProjectCount) {
    const filterdIds = utils.getVisibleResultIDs('indices', '.plannie-item.d-block', '.plannie-item.d-none');
    setLoading(true);
    fetch(`/${url}/${filterdIds}`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        setLoading(false);
        results.primavera.duration_box_graph.isNew = true;
        results.primavera.float_box_graph.isNew = true;
        // setUniqueProjectCount(results.primavera.unique_count_projects)
        getGraphData(
           results.primavera,
        );
    }, (err) => {
        setLoading(false);
        console.error("Error: " + err);
    }).catch((err) => {
        setLoading(false);
        console.error("Error: " + err);
    });
}

function getTopNList(value) {
    const cardElementLists = document.querySelectorAll(PLANNIE_ITEM_SELECTOR);
    const indices = JSON.parse(sessionStorage.getItem("indices"));
    const indicesToBeFiltered = indices.slice(0, parseInt(value, 10));
    const filteredIndices = [];
    cardElementLists.forEach(function (element) {
        if (indicesToBeFiltered.includes(parseInt(element.dataset.id, 10))) {
            filteredIndices.push(element.dataset.id);
        }
    });
    return filteredIndices;
}

function topNFilters(value, isClearAll = false) {
    const cardElementLists = document.querySelectorAll(PLANNIE_ITEM_SELECTOR);
    let count = 0;
    const wbsName = [], activityType = [], activityStatus = [], dateFilterData = [], lob = [], region = [];
    const dropdownFilterData = {
            // primavera:{
                Wbs_Name: wbsName,
                Activity_Type: activityType,
                Activity_Status: activityStatus,
                LOB: lob,
                Region: region
            // }
    };
    if (!isClearAll && value !== '100') {
        const indices = JSON.parse(sessionStorage.getItem("indices"));
        const indicesToBeFiltered = indices.slice(0, parseInt(value, 10));
        cardElementLists.forEach(function (element) {
            if (indicesToBeFiltered.includes(parseInt(element.dataset.id, 10))) {
                wbsName.push(element.dataset.wbsname);
                activityType.push(element.dataset.activitytype);
                activityStatus.push(element.dataset.activitystatus);
                dateFilterData.push(element.dataset.projectdate);
                lob.push(element.dataset.lob);
                region.push(element.dataset.region);
                displyCard(element);
                count += 1;
            } else {
                hideCard(element);
            }
        })
        return [count, dropdownFilterData, dateFilterData];
    } else {
        cardElementLists.forEach(function (element) {
            wbsName.push(element.dataset.wbsname);
            activityType.push(element.dataset.activitytype);
            activityStatus.push(element.dataset.activitystatus);
            dateFilterData.push(element.dataset.projectdate);
            lob.push(element.dataset.lob);
            region.push(element.dataset.region);
            displyCard(element);
            count += 1;
        });
        return [count, dropdownFilterData, dateFilterData];
    }
}
function getTheListIfTopNActive(topNVal) {
    const indices = getTopNList(topNVal);
    const allElements = document.querySelectorAll(PLANNIE_ITEM_SELECTOR);
    const listToBefiltered = []
    allElements.forEach(function (element) {
        if (indices.includes(element.dataset.id)) {
            listToBefiltered.push(element)
        }
    });
    return listToBefiltered;
}

function projectDateFilter(value) {
    const [isTopNactive, topNVal] = ifTopNFilterActive();
    let cardElementLists = document.querySelectorAll(PLANNIE_ITEM_SELECTOR);
    if (isTopNactive) {
        cardElementLists = getTheListIfTopNActive(topNVal);
    }
    let count = 0;
    const wbsName = [], activityType = [], activityStatus = [], dateFilterData = [], lob = [], region = [];
    const dropdownFilterData = {
            // primavera:{
                Wbs_Name: wbsName,
                Activity_Type: activityType,
                Activity_Status: activityStatus,
                LOB: lob,
                Region: region
            // }
    }
    
    // if the selection is not any time
    if (value !== "") {
        // dates should be always from the list to be filtered
        cardElementLists.forEach(function (element) {
            dateFilterData.push(element.dataset.projectdate);
        });
        cardElementLists.forEach(function (element) {
            const dateToCompare = parseInt(element.dataset.projectdate, 10);

            if(dateToCompare >= value[0] && dateToCompare <= value[1]){
                wbsName.push(element.dataset.wbsname);
                activityType.push(element.dataset.activitytype);
                activityStatus.push(element.dataset.activitystatus);
                lob.push(element.dataset.lob);
                region.push(element.dataset.region);
                displyCard(element);
                count += 1;
            } else {
                hideCard(element);
            }
        });
        return [count, dropdownFilterData, dateFilterData];
    } else {
        cardElementLists.forEach(function (element) {
            wbsName.push(element.dataset.wbsname);
            activityType.push(element.dataset.activitytype);
            activityStatus.push(element.dataset.activitystatus);
            dateFilterData.push(element.dataset.projectdate);
            lob.push(element.dataset.lob);
            region.push(element.dataset.region);
            displyCard(element);
            count += 1;
        });
        return [count, dropdownFilterData, dateFilterData];
    }
}
function clearFilterSelection() {
    const chipSelectors = [
        ".clear-region",
        ".clear-lob",
        ".clear-wbsname",
        ".clear-activitytype",
        ".clear-activitystatus",
        ".clear-projectname",
        ".clear-critical",
    ]
    chipSelectors.forEach((selector) => {
        document.querySelector(selector).click();
    });
}

function getResultForTopN(result, topN) {
    const filtered = result.filter((_item, index)=>{
        return index < parseInt(topN, 10)
    });
    return filtered;
}
function getResultForFullMatch(result, isFullMatchValue) {
    const valueSelected = isFullMatchValue ? 'Yes' : 'No';
    if (isFullMatchValue) {
        const filtered = result.filter((item, _index) => {
            return item.Is_Exact_Match === valueSelected;
        });
        return filtered;
    } else {
        return result;
    }
}
function getResultIfDateRangeSelected(result, value=[]) {
    const inputs = document.querySelectorAll(".date-range-slider-wrapper .date-range-slider-plannie input");
    const selectedMinVal = parseInt(inputs[0].value, 10), selectedMaxVal = parseInt(inputs[1].value, 10);
    let filtered = [];
    if (value.length > 0) {
        filtered = result.filter((item, index) => {
            const dateToCompare = parseInt(item.Project_Data_Date, 10);
            return (dateToCompare >= value[0] && dateToCompare <= value[1]);
        });
        return filtered;
    } else {
        filtered = result.filter((item, index) => {
            const dateToCompare = parseInt(item.Project_Data_Date, 10);
            return (dateToCompare >= selectedMinVal && dateToCompare <= selectedMaxVal);
        });
        return filtered;
    }
}
export { getPlannieFilterResult, displayAllPlannieCard, plannieFilterOnClear,stackedBarFilterOnCall,loadFilteredDataForPlanPlots, topNFilters, projectDateFilter, isFilterSelected, clearFilterSelection, 
    ifTopNFilterActive,
    isDatRangeSliderActive,
    getResultForTopN,
    getResultIfDateRangeSelected,
    getResultForFullMatch
}
