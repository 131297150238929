import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PlannieSearchpage from './app/plannieComponent/plannie-searchpage';
import Login from './app/login';
import UserControl from './app/user-control';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActivityDashboard from './app/userActivityComponent/activityDashboard';
import TourGuideBase from './app/tourGuide/tourGuideBase';
import { AppProvider, FilterProvider } from './app/context/context';
import PlannieMainLayout from './app/plannieComponent/plannie-mainLayout';
import PlannieUserControl from './app/plannieComponent/plannie-user-control';
import PlannieAccessDenied from './app/plannieComponent/plannie-access-denied';
import PlannieLogout from './app/plannieComponent/plannie-logout';
import { PlannieIdleTimer } from './app/plannieComponent/plannie-idle-timer';
import PlannieSearchQuries from './app/plannieComponent/plannie-search-queries';
import PlannieRequestAccessForm from './app/plannieComponent/plannie-request-access-form';
function App() {
  return (
    <div className="App">
      <FilterProvider>
      <AppProvider>
      <TourGuideBase />
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/plannie" component={PlannieSearchpage} />
          <Route exact path="/search" component={PlannieSearchpage} />
          <Route exact path="/dashboard" component={PlannieMainLayout} />
          <Route exact path="/grid" component={UserControl} />
          <Route exact path="/planniegrid" component={PlannieUserControl} />
          <Route exact path="/search-queries" component={PlannieSearchQuries} />
          <Route exact path="/acceptance-form" component={PlannieRequestAccessForm} />
          <Route exact path="/user-activity" component={ActivityDashboard} />
          {/* <Route exact path="/most-not-relevant" component={MostNotRelevant} /> */}
          <Route exact path="/accessdenied" component={PlannieAccessDenied} />
          <Route exact path="/timeout" component={PlannieLogout} />
        </Switch>
      </Router>
      <PlannieIdleTimer />
      <ToastContainer position='top-center' newestOnTop="true" />
      </AppProvider>
      </FilterProvider>
    </div>
  );
}

export default App;
