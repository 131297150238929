import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import usericon from '../assets/user.svg'
import utils from './app-util';
import iconbook from '../assets/book-open.svg';
import userManual from './data/Plannie - User Manual V2.0.pdf';
import { useAppContext } from './context/context';

function UserProfile(props) {
    const history = useHistory();
    const [notification, setNotification] = useState({ accessCount: 0, notificationSeen: 0 });
    useEffect(() => {
        if (!sessionStorage["_ns"]) {
            sessionStorage.setItem("_ns", 0)
        }
        // if (props.admin || props.appOwner) {
        //     fetch(`/access-requests`, {
        //         method: "GET",
        //         headers: { 'Content-Type': 'application/json', "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        //     }).then(res => {
        //         return utils.checkSessionStatus(res);
        //     }).then((results) => {
        //         if (results.success) {
        //             setNotification({ accessCount: parseInt(results.accesscount, 10), notificationSeen: parseInt(sessionStorage.getItem("_ns"), 10) });
        //         }
        //     }, (_err) => {
        //         console.error("Error occured");
        //     })
        // }

        setTimeout(() => {
            if (history.location.pathname === "/grid") {
                setNotification({ accessCount: 0, notificationSeen: 1 });
                sessionStorage.setItem("_ns", 1)
            }
        }, 4000);
    }, [props, history]);
    function onClick(e) {
        const url = e.currentTarget.dataset.url;
        history.push({ pathname: url, state: { isSuperUser: props.isSuperUser, otheruser: props.otheruser } })
    }
    function onLogoutClick(e) {
        e.preventDefault();
        history.replace('/dashboard', null)
        history.replace('/search', null)
        history.replace('/grid', null)
        history.replace('/acronyms', null)
        window.sessionStorage.clear();
        window.location.href = '/timeout';
    }
    const { setState } = useAppContext();
    function handleTourClick() {
        setState({ run: true, tourActive: true });
    }
    function renderAdminAndAcronymPageComponent() {
        if (props.isSuperUser) {
            return (
                <>
                    <button className="dropdown-item" type="button" data-url="/planniegrid" onClick={onClick}>Admin Portal</button>
                    <button className="dropdown-item" type="button" data-url="/search-queries" onClick={onClick}>Search Queries</button>
                    <button className="dropdown-item" type="button" data-url="/user-activity" onClick={onClick}>Activity Dashboard</button>
                    {/* <button className="dropdown-item" type="button" data-url="/most-not-relevant" onClick={onClick}>Most/Not Relevant</button> */}
                    <button className="dropdown-item" type="button" onClick={handleTourClick}>Plannie Site Tour</button>
                </>
            );
        } else {
            return (<button className="dropdown-item" type="button" onClick={handleTourClick}>Plannie Site Tour</button>);
        }
    }
    return (
        <div className='d-flex'>
            <div className='mr-3'><a className='tour-userguide' href='/plannie-user-manual' target='_blank' rel='noreferrer' title='User Manual'><img src={iconbook} alt="User Manual"/></a></div>
            <div className="dropdown tour-userprofile">
                {props.admin && notification.accessCount > 0 && !notification.notificationSeen ?
                    <span className='badge badge-danger position-absolute' style={{ top: '-2px', left: '-6px' }}>{notification.accessCount}</span> : null}
                <a className="dropdown-toggle" style={{ textDecoration: "none" }} href="/#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" >
                    <div className="d-inline-block">
                        <img className="ml-1 user-avatar" alt="" src={usericon} />
                    </div>
                    <span style={{ color: "#212529" }}>{sessionStorage.getItem("displayName")}</span>
                </a>

                <div className="dropdown-menu dropdown-user-profile" aria-labelledby="dropdownMenuLink">
                    {
                        renderAdminAndAcronymPageComponent()
                    }
                    {/* <button className="dropdown-item" type="button" onClick={handleTourClick}>Qurie Tour</button> */}
                    <button className="dropdown-item" type="button" id="btn-logout" onClick={onLogoutClick}>Log Out</button>
                </div>
            </div>
        </div>
    );
}
export default React.memo(UserProfile);
