import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { plannieUtils } from './plannie-utils';
import d3Tip from "d3-tip";

export function createLegend(groups, legendsRef, margin, transformProperty, colorScheme, isPattern, pattern = "url(#maskStripe0)") {
    d3.select(legendsRef.current).selectAll('rect, text').remove();
    const legendSize = 15
    d3.select(legendsRef.current)
        .attr('transform', transformProperty)
        .selectAll('.legend-rect').data(groups)
        .join("rect").attr('class', 'legend-rect')
        .attr("x", margin.left + 20)
        .attr("y", (_d, i) => { return 0 + i * (legendSize + 5) })
        .attr("width", legendSize)
        .attr("height", legendSize)
    if (isPattern) {
        d3.select(legendsRef.current).selectAll('.legend-rect').style('mask', (_d, i) => pattern).style('fill', colorScheme[0]);
    } else {
        d3.select(legendsRef.current).selectAll('.legend-rect').style("fill", (_d, i) => { return colorScheme[i] })
    }
    d3.select(legendsRef.current).selectAll("legendLabel")
        .data(groups)
        .enter()
        .append("text")
        .attr("x", ((margin.left + 10) + legendSize) * 1.2)
        .attr("y", function (_d, i) { return 0 + i * (legendSize + 5) + (legendSize / 2) })
        .text(function (d) { return d })
        .attr("text-anchor", "left")
        .style("alignment-baseline", "middle")
        .style("font-size", ".75rem")
}

export default function GroupedStatckedBarPlot({ data, groupedStackedBarPlotFilerCallback, dataType }) {
    const svgRef = useRef(null), gRef = useRef(null);
    const xAxisRef = useRef(null), yAxisRef = useRef(null), xLabel = useRef(null), yLabel = useRef(null), yAxisRightRef = useRef(null), yLabelRight = useRef(null);
    const legendsRef = useRef(null);
    const legendsGroupRef = useRef(null);
    useEffect(() => {
        if (svgRef.current !== null && data !== null && data !== undefined && Object.keys(data).length > 0 && data[0].isNew) {
            data = data.reverse();
            data[0].isNew = false;
            const margin = { top: 50, right: 30, bottom: 80, left: 50 };
            let width = (plannieUtils.getFixedPlotDimension().width - margin.left - margin.right - 20) * 60 / 100;
            let height = 400; //plannieUtils.getFixedPlotDimension().height - margin.left - margin.right;
            draw(data);
            svgRef.current.parentElement.parentElement.parentElement.parentElement.addEventListener('fullscreenchange', (_e) => {
                if (document.fullscreenElement) {
                    width = window.innerWidth * 60 / 100;
                    height = window.innerHeight - 100;
                    updateOnFS()
                } else {
                    width = (plannieUtils.getFixedPlotDimension().width - margin.left - margin.right - 20) * 60 / 100;;
                    height = 400;
                    updateOnFS()
                }
            });

            function updateOnFS() {
                draw(data)
            }
            function draw(data) {
                d3.select(svgRef.current).classed('clickactive', false);
                d3.select(svgRef.current)
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                    .attr("preserveAspectRatio", "xMidYMid meet")
                d3.select(gRef.current).attr('class', 'grouped-stack-chart')
                    .attr("transform", `translate(${margin.left},${margin.top})`);


                const xScale = d3.scaleBand().range([0, width]).padding(0.1);
                const yScale = d3.scaleLinear().range([height, 0]);
                // const yScaleRight = d3.scaleLinear().range([height, 0]);
                const xAxis = d3.axisBottom(xScale);
                const yAxis = d3.axisLeft(yScale);
                // const yAxisRight = d3.axisRight(yScaleRight);

                const groupedPlotData = data;
                const dataPlot = [groupedPlotData[0].data, groupedPlotData[1].data].flat();
                const groups = groupedPlotData[0].groups;
                const colorScheme = ['#8bb3f9', '#97edb0'];
                const groupColor = ["#dd1d21"];
                const stack = d3.stack().keys(groups).order(d3.stackOrderNone).offset(d3.stackOffsetDiverging);

                // =========== Set xScal domain and yScale domain ==========
                xScale.domain(groupedPlotData[0].data.map(function (d) {
                    return d.label;
                }));
                const stackMax = (stacks) => {
                    return d3.max(stacks, function (d) {
                        return d[1];
                    });
                }
                const stackMin = (stacks) => {
                    return d3.min(stacks, function (d) {
                        return d[0];
                    });
                }
                yScale.domain([d3.min(stack(dataPlot), stackMin), plannieUtils.roundUpToNearestN(d3.max(stack(dataPlot), stackMax), 5)]);

                // const minmaxvals = [data[0].median, plannieUtils.roundUpToNearestN(data[1].median, 5)];
                // yScaleRight.domain([0, d3.max(minmaxvals)]);

                d3.select(xAxisRef.current).attr('class', 'x axis xAxisGSBP')
                    .attr("transform", `translate(0, ${height})`)
                    .call(xAxis);
                d3.select(yAxisRef.current).attr('class', 'y axis yAxisGSBP')
                    .call(yAxis);
                // d3.select(yAxisRightRef.current).attr('class', 'y axis yAxisRightGSBP')
                //     .attr("transform", `translate(${width}, 0)`)
                //     .call(yAxisRight);

                d3.select(xLabel.current)
                    .attr('class', "xlabel")
                    .attr('text-anchor', "end")
                    .attr("x", width / 2)
                    .attr("y", height + margin.bottom - 4)
                    .text(`Duration(in Days)`)
                    .style("font-size", "0.75rem")
                    .style("text-anchor", "middle");

                d3.select(xAxisRef.current).selectAll("text")
                    .attr("y", 3)
                    .attr("dy", ".5em")
                    .attr("x", "-.4em")
                    .style("text-anchor", "end")
                    .attr("transform", function (_d) {
                        return "translate(0,0) rotate(-45)";
                    });
                    
                d3.select(yLabel.current)
                    .attr('class', "ylabel")
                    .attr("x", -height / 2)
                    .attr("y", -(margin.left / 2) - 10)
                    .attr("transform", "rotate(-90)")
                    .style("font-size", "0.75rem")
                    .attr('text-anchor', "middle")
                    .text(data[0].y_label);
                // d3.select(yLabelRight.current)
                //     .attr('class', "ylabel")
                //     .attr("x", -height/2)
                //     .attr("transform", `translate(${width + margin.right}, 0) rotate(-90)`)
                //     .style("font-size", "12px")
                //     .attr('text-anchor', "middle")
                //     .text("");
                // ==================Domain end=======================
                // ============Tooltip===============
                const tip = d3Tip()
                    .attr("class", "d3-tooltip")
                    .offset([-10, 0])
                    .html(function (_d) {
                        const nodeData = d3.select(this).data()[0];
                        const key = d3.select(this).attr("data-key");
                        const barType = d3.select(this).attr("data-bartype");
                        // return `${key} <br/> ${nodeData.data[key]} no. of ${barType.split(' ')[0] === 'Actual' ? "Completed" : barType.split(' ')[0]} activities ${nodeData.data.label === '>200 days' ? '' : 'within'} ${nodeData.data.label} duration range`;
                        return `${key} <br/> ${nodeData.data[key]} no. of activities ${nodeData.data.label === '>300 days' ? '' : 'within'} ${nodeData.data.label} duration range`;
                    });
                d3.select(svgRef.current).call(tip)
                // ============Tooltip End===============
                let isRectHighlighted = null
                const xVar = 'label';
                const clusteredLabels = [`${data[0].x_label}`, `Actual ${data[1].x_label}`];
                d3.select(gRef.current).selectAll(".bar").remove();
                data.slice(0, 1).forEach(function (plotData, dataIndex) {
                    groups.forEach(function (key, keyIndex) {
                        const bar = d3.select(gRef.current).selectAll(".bar-" + key.replace(/\s|,|:|\(|\)|\//g, '_'))
                            .data(stack(plotData.data)[keyIndex], function (d) {
                                return d.data[xVar] + "-" + key;
                            });
                        bar.transition()
                            .attr("x", function (d) {
                                return xScale(d.data[xVar]) + (xScale.bandwidth() / 2) * dataIndex;
                            })
                            .attr("y", function (d) { return yScale(d[1]); })
                            .attr("height", function (d) { return yScale(d[0]) - yScale(d[1]); });
                        bar.enter().append("rect")
                            .attr("class", function (_d) { return `bar bar-${key.replace(/\s|,|:|\(|\)|\//g, '_')}_${data[dataIndex]['x_label'].replace(/\s|,|:|\(|\)|\//g, '_')}`; })
                            .attr("data", function (d) {
                                return d.data[key];
                            })
                            .attr("data-bartype", clusteredLabels[dataIndex])
                            .attr("data-key", key)
                            .attr("data-ids", function (d, i) {
                                return d.data[`${dataType}_ids`][key][i];
                            })
                            .attr("x", function (d) {
                                // return xScale(d.data[xVar]) + (xScale.bandwidth() / 2) * dataIndex;
                                return xScale(d.data[xVar]) + (xScale.bandwidth() / 4);
                            })
                            .attr("y", function (d) {
                                return yScale(d[1]);
                            })
                            .attr("height", function (d) { return yScale(d[0]) - yScale(d[1]); })
                            .attr("width", xScale.bandwidth())
                            .style("cursor", "pointer")
                            .attr("fill", function (_d) {
                                if (keyIndex === 0) {
                                    return groupColor[0];
                                } else {
                                    return colorScheme[dataIndex];
                                }
                            })
                            .style('mask', (d, i) => {
                                if (keyIndex === 0) {
                                    return `url(#maskStripe${keyIndex})`;
                                } else {
                                    return colorScheme[keyIndex];
                                }
                            })
                            .attr("width", xScale.bandwidth() / 2)
                            .on("mouseover", tip.show)
                            .on("mouseout", tip.hide)
                            .on("click", function (_d) {
                                let filter = false;
                                if (d3.select(this).classed("active") && d3.select(svgRef.current).classed('clickactive')) {
                                    d3.select(this).classed("active", false);
                                    d3.select(svgRef.current).classed('clickactive', false);

                                } else {
                                    d3.select(this).classed("active", true);
                                    d3.select(svgRef.current).classed('clickactive', true);
                                    filter = true;
                                }
                                // clear the highlighted rect on other rect clicking

                                if ((isRectHighlighted !== null) && !isRectHighlighted.isSameNode(this)) {
                                    isRectHighlighted.classList.remove('active');
                                }

                                isRectHighlighted = this;

                                const idsOrCategory = d3.select(this).attr("data-ids");
                                groupedStackedBarPlotFilerCallback({ id: idsOrCategory, filter: filter }, "duration", false);
                            });
                    });

                });

                // const medians = [data[0].median, data[1].median];
                // d3.select(gRef.current).selectAll('.median-line-grouped-plot').remove();
                // d3.select(gRef.current).selectAll('.median-line-grouped-plot')
                //     .data(medians)
                //     .enter()
                //     .append('line')
                //     .attr('class', (d)=> `median-line-grouped-plot median-line-${d}`)
                //     .attr('x1', 0)
                //     .attr('x2', width )
                //     .attr('y1', function (d) { return (yScaleRight(d)) })
                //     .attr('y2', function (d) { return (yScaleRight(d)) })
                //     .attr('stroke', 'black')
                //     .style('stroke-dasharray', 4);
                // d3.select(gRef.current).selectAll('.median-label').remove();
                // d3.select(gRef.current)
                //     .selectAll('.median-label')
                //     .data(medians)
                //     .enter()
                //     .append('text')
                //     .attr('class', "median-label")
                //     .attr('x', width - 10)
                //     .attr('y', (d) => yScaleRight(d) )
                //     .attr('dy', -2.5)
                //     .text((d, i) => {
                //         if(medians[0] === medians[1]) {
                //             return `${clusteredLabels.map((name)=>name.split(' ').join(" median ")).join("/")}(${d})`;
                //         } else {
                //             return `${clusteredLabels[i].split(' ').join(" median ")}(${d})`;
                //         }
                //     })
                //     .style('font-size', '10px')
                //     .style('text-anchor', 'end')
                createLegend(["Non-critical"], legendsRef, margin, `translate(${(margin.left - (margin.left + margin.right))},0)`, colorScheme, false);
                createLegend(groups.slice(0, 1), legendsGroupRef, margin, `translate(${(margin.left + ((legendsRef.current.getBBox().width === 0 ? 91.03 : legendsRef.current.getBBox().width) + 20) - (margin.left + margin.right))},0)`, groupColor, true);
            }


        }
    });

    return (
        <div>
            <svg id={'plannie-actvityduration-plot'} ref={svgRef} width={'100%'} height={300}>
                <defs>
                    <pattern id="patternStripeA"
                        width="2" height="5"
                        patternUnits="userSpaceOnUse"
                        patternTransform="rotate(45)">
                        <rect width="1" height="1" transform="translate(0,0)" fill="white"></rect>
                    </pattern>
                    <mask id="maskStripe1">
                        <rect x="0" y="0" width="100%" height="100%" fill="url(#patternStripeA)" />
                    </mask>
                </defs>
                <defs>
                    <pattern id="patternStripeB"
                        width="4" height="4"
                        patternUnits="userSpaceOnUse"
                        patternTransform="rotate(45)">
                        <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
                    </pattern>
                    <mask id="maskStripe0">
                        <rect x="0" y="0" width="100%" height="100%" fill="url(#patternStripeB)" />
                    </mask>
                </defs>
                <g ref={legendsRef}></g>
                <g ref={legendsGroupRef}></g>
                <g ref={gRef}>
                    <g ref={xAxisRef}></g>
                    <g ref={yAxisRef}></g>
                    <g ref={yAxisRightRef}></g>
                    <text ref={xLabel}></text>
                    <text ref={yLabel}></text>
                    <text ref={yLabelRight}></text>
                </g>
            </svg>
        </div>
    );
}
GroupedStatckedBarPlot.propTypes = {
    data: PropTypes.array.isRequired,
    groupedStackedBarPlotFilerCallback: PropTypes.func.isRequired,
    dataType: PropTypes.string.isRequired
}
GroupedStatckedBarPlot.propTypes = {
    data: PropTypes.array,
    groupedStackedBarPlotFilerCallback: PropTypes.func,
    dataType: PropTypes.string
}
