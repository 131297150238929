import React, { useState } from "react";
import Joyride, { STATUS } from 'react-joyride';
import tours from '../data/tours';
import { useAppContext, useMount } from '../context/context';

function autoType(searchQuery, inputElement, afterInputSubmitBtn, ifStateSetNeeded, setStateFunc, stateValue) {
    const inputInterval = setInterval(() => {
        const letter = searchQuery.shift();
        inputElement.value += letter;
        if (searchQuery.length === 0) {
            clearInterval(inputInterval);
            if (afterInputSubmitBtn === ".report-save-btn") {
                document.querySelector(".report-save-btn").classList.add('active');
                setTimeout(() => {
                    document.querySelector(".report-save-btn").classList.remove('active');
                    document.querySelector('.report-download-modal .userpopup-close').click();
                }, 2000);
            } else {
                document.querySelector(afterInputSubmitBtn).click();
            }
            if(ifStateSetNeeded){
                setTimeout(()=>{
                    setStateFunc({run: stateValue});
                }, 3000);
            }
        } 
    }, 50);
}
function addToReportAutoHint(generateBtnReference, setStateFunc, value) {
    document.querySelector("#risk .search-results-inner .risk-item:first-child .addreport").click();
    generateBtnReference.click();
    const newReportInput = document.querySelector("#newReportInput");
    const reportname = `Your Report Name_${Date.now()}`.split('')
    autoType(reportname, newReportInput, '.report-save-btn', true, setStateFunc, value);
}
export default function TourGuideBase() {
    const { setState, state: { run, steps, generateBtnRef }, } = useAppContext();
    const [open, setOpen] = useState(false);
    useMount(()=>{
        setState({ steps: tours.steps });
        const tourGuideData = localStorage.getItem("tourguide");
        const tourstartInterval = setInterval(() => {
            if(tourGuideData == null && window.location.pathname === '/search' && sessionStorage.getItem('_uinfo') !== null){
                clearInterval(tourstartInterval);
                const tGuide = { autotour: true, hasaccess: false };
                const userhasAcees = JSON.parse(sessionStorage.getItem('_uinfo')).success;
                if(userhasAcees){
                    localStorage.setItem("tourguide", JSON.stringify(tGuide));
                    setOpen(true);
                }
            }
        }, 1000);
    });
    function startOnClick() {
        setOpen(false);
        setState({ run: true });
    }
    function closeOnClick() {
        setOpen(false);
    }
    const handleJoyrideCallback = (data) => {
        const { status, type, index, action } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        const actionStatus = ['prev', 'skip']
        if (type === 'step:after' && index === 1 && !actionStatus.includes(action)) {
            const pathname = window.location.pathname;
            if (pathname === '/search') {
                const searchInput = document.querySelector('.search-input-field');
                const query = "coating and painting".split('');
                autoType(query, searchInput, '#search-icon-btn', true, setState, true);
                setState({ run: false });
            }
        } else if (type === 'step:before' && index === 7) {
            document.querySelector(".search-results-inner.tour-results").style.overflow = "hidden";
        } else if (type === 'step:after' && index === 8) {
            document.querySelector("#risk .search-results-inner .risk-item:first-child .associations-btns");
        } else if (type === 'step:after' && index === 10) {
            document.querySelector(".search-results-inner.tour-results").style.overflow = "auto";
        } else if (type === 'step:after' && index === 11 && !actionStatus.includes(action)) {
            setState({ run: false });
            addToReportAutoHint(generateBtnRef, setState, true);
        } else if (type === 'step:after' && index === 12) {
            document.querySelector('.tour-advancedsearch').click();
        } else if (finishedStatuses.includes(status)) {
            setState({ run: false, tourActive: false, stepIndex: 0 });
        }
    };
    return (
        <>
            <div className="tour-modal-wrap">
                <div className={`modal ${open? 'd-block': ''}`} tabIndex="-1">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content border-0">
                            <div className="modal-header py-2 border-bottom-0">
                                <h5 className="modal-title">Welcome to Plannie!</h5>
                            </div>
                            <div className="modal-body py-2">
                                <span style={{fontSize: '14px'}}>Click on Start button to have a Plannie tour !</span>
                            </div>
                            <div className="modal-footer py-2 d-flex justify-content-between border-top-0">
                                <button type="button" className="btn btn-secondary rounded-2" style={{fontSize: '10px'}} data-dismiss="modal" onClick={closeOnClick}>Exit</button>
                                <button type="button" className="btn btn-red rounded-2" onClick={startOnClick}>Start</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {open ? <div className="modal-backdrop fade show" style={{ zIndex: 1040, backgroundColor: "rgba(3,3,3,0.6)" }}></div> : null}
            <Joyride
                locale={{ skip: 'Skip Tour', last: 'Finish' }}
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showSkipButton
                steps={steps}
                disableScrolling={true}
                disableOverlayClose={true}
                styles={{
                    options: {
                        zIndex: 10000,
                        primaryColor: "#dd1d21",
                    },
                }}
            />
        </>
    );
}
