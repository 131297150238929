import React from 'react';
import * as d3 from "d3";
function getKeywords(data) {
    const formatted = [];
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const temp = {};
            temp['name'] = key;
            temp['value'] = 1.0;
            temp['keywords'] = []
            for (const kw in data[key]) {
                if (data[key].hasOwnProperty(kw)) {
                    const temp_inner = {}
                    temp_inner['name'] = kw;
                    temp_inner['value'] = data[key][kw]
                    temp['keywords'].push(temp_inner);
                }
            }
            formatted.push(temp);
        }
    }
    return formatted;
}
function KeyTopics(props) {
    const items = getKeywords(props.keywords)
    const color = d3.scaleSequential([0, 1], ["#ffffff", "#fbce07"])
    function onclick(e) {
        let query = document.querySelector('.search-input-field').value;
        query = query === '' ? window.sessionStorage.getItem('query') : query;
        props.keyWordsQueryCallback({ query: query, face: e.target.dataset.name, back: e.target.dataset.main });
    }
    function disableKeywordInteraction(name) {
        return name === "###" ? "none" : "all";
    }
    function showPointer(name) {
        return name === "###" ? "no-drop" : "pointer";
    }
    return (
        <div className="key-topics">
            <div className="component-title mb-2"><span className='c-title'>Alternative Search Terms</span></div>
            <div className="card card-box-shadow p-2">
                {
                    items.length > 0 ? (
                        items.map((mainkey, _index) => (
                            <div className="d-flex flex-wrap" key={mainkey.name}>
                                <div className="key-btn-divider"></div>
                                <>
                                    <span className="k-btn-wrap" style={{ cursor: showPointer(mainkey.name) }} key={mainkey.name} >
                                        <span className="btn btn-sm mr-1 mb-1 rounded-0 key-btn"
                                            data-main={mainkey.name} data-name={mainkey.name}
                                            style={{ backgroundColor: color(parseFloat(mainkey.value)), pointerEvents: disableKeywordInteraction(mainkey.name) }}
                                            onClick={onclick}>{mainkey.name}</span></span>
                                    {
                                        Array.isArray(mainkey.keywords) ? (
                                            mainkey.keywords.map(({ name, value }) => {
                                                return <span className="k-btn-wrap" style={{ cursor: showPointer(mainkey.name) }} key={name}>
                                                    <span className="btn btn-sm mr-1 mb-1 rounded-0 key-btn" data-main={mainkey.name}
                                                        data-name={name}
                                                        style={{ backgroundColor: color(parseFloat(value)), pointerEvents: disableKeywordInteraction(name) }}
                                                        onClick={onclick}>{name}</span></span>
                                            })
                                        ) : null
                                    }
                                </>
                            </div>
                        ))
                    ) : <span className="small d-block text-center my-4">No alternate search terms found.</span>
                }
            </div>
        </div>
    );
}
export default KeyTopics;
