import React, { useEffect } from 'react';
import summaryDescription from '../summaryDescription';
import Tagging from '../qualityComponent/Tagging';
import $ from 'jquery';
import utils from '../app-util';
import { toast } from 'react-toastify';

function PlannieResultCard(props) {
    const mustIncl = document.querySelector('.must-include');
    function addToReportOnChange(e) {
        try {
            props.addToReportOnChange(e);
        } catch (error) {
            console.error(error);
        }
    }

    function mostRelevantOnChange(e) {
        try {
            const targetEl = e.currentTarget;
            const id = targetEl.dataset['id'];
            const notRelevantEl = document.querySelector(`.thumbs-down-btn[data-id='${id}']`);
            if (notRelevantEl.classList.contains('active') || notRelevantEl.dataset.notrelevant === 'true') {
                if (window.confirm('Are you sure to mark this as most relevant?')) {
                    mostNotRelevant(e, "/plannie_add_to_most_relevant", "/plannie_delete_from_most_relevant", 'id', 'plannie');
                }
            } else {
                mostNotRelevant(e, "/plannie_add_to_most_relevant", "/plannie_delete_from_most_relevant", 'id', 'plannie');
            }

        } catch (error) {
            console.error(error);
        }
    }

    function notRelevantOnChange(e) {
        try {
            const targetEl = e.currentTarget;
            const id = targetEl.dataset['id'];
            const notRelevantEl = document.querySelector(`.thumbs-up-btn[data-id='${id}']`);
            if (notRelevantEl.classList.contains('active') || notRelevantEl.dataset.mostrelevant === 'true'){
                if(window.confirm('Are you sure to mark this as not relevant?')){
                    mostNotRelevant(e, "/plannie_add_to_not_relevant", "/plannie_delete_from_not_relevant", 'id', 'plannie');
                }
            } else {
                mostNotRelevant(e, "/plannie_add_to_not_relevant", "/plannie_delete_from_not_relevant", 'id', 'plannie');
            }
        } catch (error) {
            console.error(error);
        }
    }

    function actionOnClick(e) {
        e.preventDefault();
        try {
            props.associationsOnClick({
                type: e.target.dataset.type,
                id: e.target.dataset.id,
                query: sessionStorage.getItem('query'),
                datatype: e.target.dataset.datatype,
                bowtieURL: e.target.dataset.url
            });
        } catch (error) {
            console.error(error);
        }
    }
    function mostNotRelevant(e, addUrl, deleteUrl, idDatasetName, dataType) {
        const targetElement = e.currentTarget;
        const searchQuery = sessionStorage.getItem('query');
        const relevantType = targetElement.dataset.relevanttype
        const requestOption = {
            method: "POST",
            headers: { 'Content-Type': 'application/json', "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
            body: JSON.stringify({ query: searchQuery, data_type: dataType, data_id: targetElement.dataset[idDatasetName]})
        };
        
        if (!targetElement.classList.contains('active') && targetElement.dataset[relevantType.replace(' ', '').toLowerCase()] !== 'true') {
            fetch(addUrl, requestOption)
                .then(res => {
                    return utils.checkSessionStatus(res);
                }).then((results) => {
                    if (!results.status) {
                        toast.warn(`Failed to add in ${relevantType}`);
                    } else {
                        targetElement.classList.add('active');
                        toast.success(`Successfully added to ${relevantType}`);
                    }
                }, (_err) => {
                    toast.error(`Error occured when added to ${relevantType}`);
                });
        } else {
            fetch(deleteUrl, requestOption)
                .then(res => {
                    return utils.checkSessionStatus(res);
                }).then((results) => {
                    if (!results.status) {
                        toast.warn(`Failed to delete from ${relevantType}`);
                    } else {
                        toast.success(`Successfully deleted from ${relevantType}`);
                    }
                }, (_err) => {
                    toast.error(`Error occured on delete from ${relevantType}`);
                });
        }
        
    }
    return (

            <div className={`${props.dataType}-item search-item card mb-3 rounded-0 border-0`}
                key={`#${props.valueConst}${props.dataId}${mustIncl !== null ? mustIncl.value : ""}`}
                data-id={props.dataId}  data-wbsname={props.dataWbsName}
                data-activitystatus={props.dataActivityStatus} 
                data-activitytype={props.dataActivityType}
                data-projectname={props.dataProjectName}
                data-projectcode={props.dataProjectCode} 
                data-activityid={props.dataActivityId}
                data-projectdate={props.dataprojectdate}
                data-lob={props.dataLoB}
                data-region={props.dataRegion}
                data-critical={props.dataCritical}
                data-isexactmatch={props.dataIs_Exact_Match}
                >
                <div className='search-item-wrapper'>
                    <div className='search-item-inner p-2'>

                        <div className={`result-content-item-intro d-flex justify-content-between py-1`}>
                            <div className='titles' data-toggle="collapse"
                                data-target={`#${props.dataType}${props.dataId}`}
                                aria-expanded="false" aria-controls={`#${props.dataType}${props.dataId}`}>
                                {/* <h6 className='main-title' title={props.dataActivityName}>{props.dataActivityName}</h6> */}
                                {/* <p className='sub-title small'>{props.dataDesc.slice(0, 130)}</p>
                                <br /> */}
                            </div>
                            <div className='activity-control'>
                                <div className="activity-control-inner">
                                    <div className="report-controls">
                                        {/* <form>
                                            <div className="form-group form-check mb-0">
                                                <input type="checkbox" className="form-check-input addreport" id={`addReport${props.dataType}${props.dataId}`} data-id={props.dataId}
                                                    data-type={props.dataType} data-title={props.dataActivityName} onChange={addToReportOnChange} />
                                                <div className='Toastify__spinner checkbox-spinner'></div>
                                                <label className="form-check-label" htmlFor={`addReport${props.dataType}${props.dataId}`}>Add to Report</label></div>
                                            <div className="form-group form-check mb-0">
                                                <input type="checkbox" className="form-check-input mostrelevant"
                                                    onChange={mostRelevantOnChange} data-id={props.dataId} id={`mostRelevant${props.dataType}${props.dataId}`}
                                                    defaultChecked={props.mostRelevantList.includes(props.dataId)} disabled={props.globalMostRelevantList.includes(props.dataId) ? true : false}
                                                />
                                                <div className='Toastify__spinner checkbox-spinner'></div>
                                                <label className="form-check-label" htmlFor={`mostRelevant${props.dataType}${props.dataId}`}>Most Relevant</label></div>
                                            <div className="form-group form-check mb-0">
                                                <input type="checkbox" className="form-check-input notrelevant"
                                                    onChange={notRelevantOnChange} data-id={props.dataId} id={`notRelevant${props.dataType}${props.dataId}`}
                                                    defaultChecked={props.notRelevantList.includes(props.dataId)} disabled={props.globalNotRelevantList.includes(props.dataId) ? true : false}
                                                />
                                                <div className='Toastify__spinner checkbox-spinner'></div>
                                                <label className="form-check-label" htmlFor={`notRelevant${props.dataType}${props.dataId}`}>Not Relevant</label>
                                                </div>
                                        </form> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='plannie-result-card-footer'>
                            <div className="content-left">
                                <Tagging tagList={props.dataTags} isCardView={props.isCardView} setModalData={props.setModalData} />
                            </div>
                            {/* <div className="col-4 col-md-3 content-right">
                                <div className="d-flex justify-content-end"> */}
                                    {/* <button className="btn btn-sm action-bowtie" type="button" data-id={props.dataId} data-type="Action"
                                     data-url="get_quality_bow_tie" data-datatype={'quality'} onClick={actionOnClick}>Bowtie</button> */}
                                    {/* <div className='btn-group associations-btns ml-1'>
                                        <button className='btn btn-sm dropdown-toggle' type='button' data-toggle="dropdown" aria-expanded="false">Associations</button>
                                        <div className="dropdown-menu dropdown-menu-sm-right">
                                            <button className="dropdown-item" type='button'  data-id={props.dataId} data-datatype={'quality'}
                                                data-type="Associated Risk" onClick={actionOnClick}>Associated Risk</button>
                                            <button className="dropdown-item" type='button' data-id={props.dataId} data-datatype={'quality'}
                                                data-type="Associated Learning" onClick={actionOnClick}>Associated Learnings</button>
                                            <button className="dropdown-item" type='button' data-id={props.dataId} data-datatype={'quality'}
                                                data-type="Associated MOC" onClick={actionOnClick}>Associated MOC</button>
                                        </div>
                                    </div> */}

                                    {/* <button className="btn btn-sm" data-id={props.dataId} data-datatype={'quality'}
                                    data-type="Associated Risk" onClick={actionOnClick}>Associated Risk</button>
                                    <button className="btn btn-sm ml-2" data-id={props.dataId} data-datatype={'quality'}
                                        data-type="Associated Learning" onClick={actionOnClick}>Associated Learnings</button>
                                    <button className="btn btn-sm ml-2" data-id={props.dataId} data-datatype={'quality'}
                                        data-type="Associated MOC" onClick={actionOnClick}>Associated MOC</button> */}
                                {/* </div>
                            </div> */}
                        </div>

                        

                    </div>
                    <div className='feedback-wrapper '>
                        <div className='d-flex'>
                            <button type='button' className='btn thumbs-btn thumbs-up-btn' aria-label='Relevant result' data-relevanttype="Most Relevant" data-id={props.dataPrimaryKey} data-mostrelevant={props.mostRelevantList.includes(props.dataPrimaryKey.toString())} onClick={mostRelevantOnChange}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon-thumbs-up" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>
                            </button>
                            <button type='button' className='btn thumbs-btn thumbs-down-btn' aria-label='Not relevant result' data-relevanttype="Not Relevant" data-id={props.dataPrimaryKey} data-notrelevant={props.notRelevantList.includes(props.dataPrimaryKey.toString())} onClick={notRelevantOnChange}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon-thumbs-down" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        
    );
}
PlannieResultCard.defaultProps = {
    dataType: "",
    dataId: "",
    dataActivityName: "",
    dataDesc: "",
    dataSummary: "",
    dataLoB: "",
    dataCountry: "",
    dataProject: "",
    dataCharacterstics: "",
    dataDisciplineFunc: "",
    dataEquipmentType: "",
    dataCreatedModified: "",
    dataTags: []
}
export default PlannieResultCard;
