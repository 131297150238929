import React, { useEffect, useRef, useState, useReducer } from 'react';
import { countBy } from 'lodash';
import PlannieDropdown from './plannie-dropdown';
import { Slider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useFilterContext } from '../context/context';
import { clearFilterSelection } from './plannie-FilterUtils';

const slidertheme = createTheme({
    palette: {
        primary: {
            main: '#fbce07',
        }
    },
});
export default function PlannieFilters(planniedata) {
    const timeDelayRef = useRef(null);
    const [isClear, setIsClear] = useState(false);
    const [andSelectValue, setAndSelectValue] = useState({
        wbsname: [],
        activitytype: [],
        activitystatus: [],
        region: [],
        lob:[],
        projectname: [],
        critical: []
    });
    const {  filterValues, dateList, updateDateSlicer } = useFilterContext();
    useEffect(() => {
        document.querySelector('.clearall-plannie').onclick = function() {
            setIsClear(true);
            setAndSelectValue({
                wbsname: [],
                activitytype: [],
                activitystatus: [],
                region: [],
                lob:[],
                projectname: [],
                critical: []
            });
        }
    }, [planniedata, filterValues]);
    function filtersOnChange(value, filtertype, reason) {
        // clear other selected filters because this is OR operation not AND operation
        const activeClearicon = document.querySelector(`.MuiAutocomplete-hasClearIcon .MuiAutocomplete-clearIndicator:not(.select-${filtertype} .MuiAutocomplete-hasClearIcon .MuiAutocomplete-clearIndicator)`);
        if (activeClearicon !== null) {
            // activeClearicon.click();
        }
        try {
            setIsClear(false);
            
            const v = JSON.parse(JSON.stringify(value));
            if(andSelectValue.length > 0){
                v.push(andSelectValue[filtertype]);
            }
            const f_v = [...new Set(v.flat())];
            const previousSelection = andSelectValue;
            const setvalue = JSON.parse(JSON.stringify(andSelectValue));
            setvalue[filtertype] = f_v;
            setAndSelectValue(setvalue);

            planniedata.filterOnChange(setvalue, filtertype, sliderValue, value, previousSelection);
        } catch (error) {
            console.error(error);
        }
    }
    function topNFilterOnChange(e){
        // reset dropdown selection on top n selection
        setAndSelectValue({
            wbsname: [],
            activitytype: [],
            activitystatus: [],
            region: [],
            lob:[],
            projectname: [],
            critical: []
        });
        planniedata.filterOnChange(e.target.value, 'topN');
        clearFilterSelection()
    }
    
    const dates = dateList.map((date)=>Number(date));
    const marks = [...new Set(dates)].map((date) => { return { value: date, label: "" } });
    const minDate = Math.min(...dates), maxDate = Math.max(...dates);
    const [sliderValue, setSliderValue] = React.useState([minDate, maxDate]);

    useEffect(()=>{
        //to update the slider with in max value
        if (updateDateSlicer){
            setSliderValue([minDate, maxDate])
        }

    },[minDate, maxDate, planniedata.isClearAllClicked])

    function handleSliderOnChange(e, newValue, _activeThumb) {
        if (!Array.isArray(newValue)) {
            return;
        }

        clearTimeout(timeDelayRef.current);
        timeDelayRef.current = setTimeout(() => {
            // reset dropdown selection on date range selection
            setAndSelectValue({
                wbsname: [],
                activitytype: [],
                activitystatus: [],
                region: [],
                lob: [],
                projectname: [],
                critical: []
            });
            clearFilterSelection();
            planniedata.filterOnChange(newValue, 'date');
        }, 2000);

        setSliderValue(newValue);
    }
    function compare(a, b) {
        return a.localeCompare(b);
    }

    function fullMatchOnChange(e) {
        let val = false;
        if (e.target.checked) {
            val = true
        }
        document.querySelector("#topNFilter").selectedIndex = 0
        // reset dropdown selection on top n selection
        setAndSelectValue({
            wbsname: [],
            activitytype: [],
            activitystatus: [],
            region: [],
            lob:[],
            projectname: [],
            critical: []
        });
        planniedata.filterOnChange(val, 'fullmatch');
        clearFilterSelection()
    }
    return (
        <div>
            <div className='p-2 mt-2'>
                {/* <label className='fullmatch-label' htmlFor="fullmatch">Full Match</label>
                <select className="custom-select custom-select-md plannie-select-filter" id='fullmatch' defaultValue={''} onChange={fullMatchOnChange}>
                    <option disabled value={''}>Select Full Match</option>
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                </select> */}
                <span className='mr-2'>Default</span>
                <div className="custom-control custom-switch d-inline-block">
                    <input type="checkbox" className="custom-control-input" id="fullmatch" onChange={fullMatchOnChange} />
                    <label className="custom-control-label" htmlFor="fullmatch">Exact Match</label>
                </div>
            </div>
            <div className='p-2 mt-2'>
                <label className='topNFilter-label' htmlFor="topNFilter">Select Top N (Relevance)</label>
                <select className="custom-select custom-select-md plannie-select-filter" id='topNFilter' defaultValue={100} onChange={topNFilterOnChange}>
                    <option disabled value={100}>Select Top N</option>
                    <option value={10}>Top 10</option>
                    <option value={20}>Top 20</option>
                    <option value={30}>Top 30</option>
                </select>
            </div>
            <div className='date-range-slider-wrapper'>
                <div className='px-3 mt-3 date-range-slider-plannie'>
                    <ThemeProvider theme={slidertheme}>
                        <Slider
                            color='primary'
                            getAriaLabel={() => 'Date'}
                            value={sliderValue}
                            onChange={handleSliderOnChange}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${new Date(value).toLocaleDateString()}`}
                            getAriaValueText={(value) => value}
                            min={minDate}
                            max={maxDate}
                            marks={marks}
                        />
                    </ThemeProvider>
                </div>
                <div className='px-2 d-flex justify-content-between mb-3'><span className='small'>{new Date(sliderValue[0]).toLocaleDateString()}</span><span className='small'>{new Date(sliderValue[1]).toLocaleDateString()}</span></div>
            </div>
            <div className='plannie-filter-combobox quality-filter-combobox small p-2'>
                <div className='my-1'>Region</div>
                <PlannieDropdown data={[...new Set(filterValues.Region)].sort(compare)} count={countBy(filterValues.Region)}
                    onChange={filtersOnChange} filterType="region" placeholder="Region" isClear={isClear}/>

                <div className='my-1'>LoB</div>
                <PlannieDropdown data={[...new Set(filterValues.LOB)].sort(compare)} count={countBy(filterValues.LOB)}
                    onChange={filtersOnChange} filterType="lob" placeholder="LoB" isClear={isClear}/>
                
                <div className='my-1'>Project Name</div>
                <PlannieDropdown data={[...new Set(filterValues.Project_Name)].sort(compare)} count={countBy(filterValues.Project_Name)}
                    onChange={filtersOnChange} filterType="projectname" placeholder="Project Name" isClear={isClear}/>

                <div className='my-1'>WBS Name</div>
                <PlannieDropdown data={[...new Set(filterValues.Wbs_Name.flat())].sort(compare)} count={countBy(filterValues.Wbs_Name.flat())}
                    onChange={filtersOnChange} filterType="wbsname" placeholder="WBS Name" isClear={isClear}/>

                <div className='my-1'>Criticality Status</div>
                <PlannieDropdown data={[...new Set(filterValues.Critical)].sort(compare)} count={countBy(filterValues.Critical)}
                    onChange={filtersOnChange} filterType="critical" placeholder="Criticality Status" isClear={isClear} />

                     <div className='text-center'></div>
                <div className='my-1'>Activity Type</div>
                <PlannieDropdown data={[...new Set(filterValues.Activity_Type.flat())].sort(compare)} count={countBy(filterValues.Activity_Type.flat())}
                    onChange={filtersOnChange} filterType="activitytype" placeholder="Activity Type" isClear={isClear}/>
                    <div className='text-center'></div>
                <div className='my-1'>Activity Status</div>
                
                <PlannieDropdown data={[...new Set(filterValues.Activity_Status.flat())].sort(compare)} count={countBy(filterValues.Activity_Status.flat())}
                    onChange={filtersOnChange} filterType="activitystatus" placeholder="Activity Status" isClear={isClear}/>
            </div>
        </div>
    )
}

// PlannieFilters.propTypes = {
//     dropdowns: PropTypes.object,
//     dates: PropTypes.array,
//     filterOnChange: PropTypes.func,
//     isClearAllClicked: PropTypes.bool,
//     setResultCount: PropTypes.func
// }