export const plannieUtils = (() => {
    function formatSearchResultData(result) {
        const newFormat = [];
        if (
            result.primavera !== undefined &&
            Object.keys(result.primavera).length > 1
        ) {
            for (let i = 0; i < result.primavera.Activity_ID.length; i++) {
                newFormat.push({
                    Index: result.primavera.Index[i],
                    Activity_ID: result.primavera.Activity_ID[i],
                    Activity_Name: result.primavera.Activity_Name[i],
                    Activity_Status: result.primavera.Activity_Status[i],
                    Activity_Type: result.primavera.Activity_Type[i],
                    Calender: result.primavera.Calender[i],
                    Critical: result.primavera.Critical[i],
                    Predecessors: result.primavera.Predecessors[i],
                    Successors: result.primavera.Successors[i],
                    Wbs: result.primavera.Wbs[i],
                    Wbs_Name: result.primavera.Wbs_Name[i],
                    Wbs_Path: result.primavera.Wbs_Path[i],
                    Project_Code: result.primavera.Project_Code[i],
                    Project_Name: result.primavera.Project_Name[i],
                    Original_Duration: result.primavera.Original_Duration[i],
                    Free_Float: result.primavera.Free_Float[i],
                    Total_Float: result.primavera.Total_Float[i],
                    Actual_Duration: result.primavera.Actual_Duration[i],
                    Activity_Duration_Variance: result.primavera.Activity_Duration_Variance[i],
                    Predecessors_Count: result.primavera.Predecessors_Count[i],
                    Successors_Count: result.primavera.Successors_Count[i],
                    n_exact_results: result.primavera.n_exact_results[i],
                    Project_Data_Date_Label: result.primavera.Project_Data_Date_Label[i],
                    Predecessor_Names: result.primavera.Predecessor_Names[i],
                    Successor_Names: result.primavera.Successor_Names[i],
                    LOB: result.primavera.LOB[i] === '' ? "Not Tagged" : result.primavera.LOB[i],
                    Region: result.primavera.Region[i] === '' ? "Not Tagged" : result.primavera.Region[i],
                    Project_Data_Date: result.primavera.Project_Data_Date[i] !== "" ?
                        Date.parse(result.primavera.Project_Data_Date[i]) : result.primavera.Project_Data_Date[i],
                    Is_Exact_Match: result.primavera.Is_Exact_Match[i],
                    PrimaryKey: result.primavera.PrimaryKey[i]
                });
            }
            result.primavera.LOB = result.primavera.LOB.map((lob) => (lob === '' ? "Not Tagged" : lob))
            result.primavera.Region = result.primavera.Region.map((region) => (region === '' ? "Not Tagged" : region))
            result.primavera.Project_Data_Date = result.primavera.Project_Data_Date.map((dt)=>Date.parse(dt))
            result.plannieFormatted = newFormat;
            result.primavera.duration_box_graph.isNew = true;//This is to stop rerendering during on click filter using plot
            result.primavera.float_box_graph.isNew = true;
            return result;
        } else {
            return [];
        }
    }

    function formatPlannieGraphData(
        gData,
        groups = ["Critical", "NonCritical"],
        dataType = "primavera"
    ) {
        if (gData !== undefined) {
            const data = gData.data;
            if (gData.data !== undefined) {
                if (Object.keys(data).length > 0) {
                    const formatted = [],
                        newFormat = {};
                    for (let i = 0; i < data[groups[0]].length; ++i) {
                        const temp = {};
                        temp["label"] = gData.x_tick_labels[i];
                        for (const val of groups) {
                            temp[val] = data[val][i];
                        }
                        temp[`${dataType}_ids`] = gData[`data_${dataType}_id`];
                        formatted.push(temp);
                    }
                    newFormat.data = formatted;
                    newFormat.groups = groups;
                    newFormat.x_label = gData.x_label;
                    newFormat.y_label = gData.y_label;
                    newFormat.isNew = true;
                    newFormat.TimeStamp = Date.now.toString();
                    newFormat.median = gData.median;
                    return newFormat;
                } else {
                    return {};
                }
            } else {
                // toast.error("Query doesn't fetch any results, please rephrase."); //susant
                return {};
            }
        } else {
            // toast.error("Query doesn't fetch any results, please rephrase."); //susant
            return {};
        }
    }

    function formatPlannieGroupedBarPlotData(groupData) {
        return [
            formatPlannieGraphData({
                "ColorBy_Label": groupData["ColorBy_Label"],
                "data": groupData["duration_data"],
                "data_primavera_id": groupData["duration_data_primavera_id"],
                "total_data_points": groupData["duration_total_data_points"],
                "x_tick_labels": groupData["x_tick_labels"],
                "x_label": groupData["x_actual_label"],
                "y_label": groupData["y_label"],
                "median": groupData["duration_median"]
            }),
            formatPlannieGraphData({
                "ColorBy_Label": groupData["ColorBy_Label"],
                "data": groupData["baseline_duration_data"],
                "data_primavera_id": groupData["baseline_duration_data_primavera_id"],
                "total_data_points": groupData["baseline_duration_total_data_points"],
                "x_tick_labels": groupData["x_tick_labels"],
                "x_label": groupData["x_planned_label"],
                "y_label": groupData["y_label"],
                "median": groupData["baseline_duration_median"]
            })
        ]
    }
    function getFixedPlotDimension() {
        const element = document.querySelector(".plannie-graph-analysis .tab-pane.active");
        return { width: element.clientWidth, height: element.clientHeight };
    }
    /**
     * @param {Number} x Number to be rounded up
     * @param {Number} n nearest number
     * @returns returns rounded up number
     */
    function roundUpToNearestN(x, n) {
        return Math.ceil(x / n) * n
    }
    return {
        formatData: formatSearchResultData,
        formatPlannieGraphData: formatPlannieGraphData,
        formatPlannieGroupedBarPlotData: formatPlannieGroupedBarPlotData,
        getFixedPlotDimension: getFixedPlotDimension,
        roundUpToNearestN: roundUpToNearestN
    };
})();