import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import utils from '../app-util';
import '../plannieComponent/tagging-table.css'

function template(data, index) {
    if (data.labelName === 'Location') {
        return (<div key={`tag${index}`} className="d-inline-block">{(data.value !== "" && data.value !== "-" ? <ReactCountryFlag countryCode={data.flag} svg /> : <img alt="" src={utils.logo}
                                    style={{ backgroundColor: "transparent", width: "18px" }} />)}<span>&nbsp;{data.value}</span><span className="verticalbar mx-2"></span></div>);
    } else {
        return <div key={`tag${index}`} className="d-inline-block"><span className='project-tag'><span className='tag-name'>{data.labelName} </span>:<span className='tag-value'> {data.value !== "" ? data.value: "--"}</span></span><span className="verticalbar mx-2"></span></div>;
    }
}
export default function Tagging({tagList=[], isCardView=false, setModalData}) {
    function handleModal(e) {
        setModalData({open: true, message: e.target.dataset.content});
    }
    return (
        <div>
            {
                !isCardView ?
                    <div className='tagging-table-wrapper table-responsive'>
                        <table className={`table table-sm`} style={{fontSize: '.875rem'}} >
                            <thead>
                                <tr>
                                    {
                                        tagList.map((data) => {
                                            return (<th key={data.labelName}>{data.labelName}</th>);
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        tagList.map((data) => {
                                            if(["Predecessor Name", "Successor Name"].includes(data.labelName) &&  data.value.length > 0){
                                                return (
                                                    <td key={`${data.labelName}`}>
                                                        {/* <div><details className='show-more-less'><summary>{data.value}</summary><p>{data.value}</p> </details></div> */}
                                                        <button style={{fontSize: 'inherit'}} type="button" className="btn" data-content={data.value} onClick={handleModal}>{data.value.slice(0, 30)}...</button>
                                                    </td>
                                                );
                                            } else {
                                                return (<td key={`${data.labelName}`}>{data.value}</td>);
                                            }
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    :
                    tagList.map((data, index) => {
                        return template(data, index);
                    })
            }
        </div>
    );
}
