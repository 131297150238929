import React, { createContext, useState, useMemo, useCallback, useContext, useEffect, useReducer } from 'react';
import { filterReducer } from './filterReducer';

const useEffectOnce = (effect) => {
    useEffect(effect, []);
}
export const useMount = (fn) => {
    useEffectOnce(() => {
        fn();
    });
};
const useSetState = (initialState = {}) => {
    const [state, set] = useState(initialState)
    const setState = useCallback(patch => {
        set(prevState =>
            Object.assign({}, prevState, patch instanceof Function ? patch(prevState) : patch)
        )
    }, []);

    return [state, setState];
};

const appState = {
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
    generateBtnRef: null // This is for the availability of the generate button 
};
export const AppContext = createContext({
    state: appState,
    setState: () => undefined,
});
AppContext.displayName = 'AppContext';

export function AppProvider(props) {
    const [state, setState] = useSetState(appState);

    const value = useMemo(() => ({
        state,
        setState,
    }),
        [setState, state],
    );

    return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within a AppProvider")
    }
    return context;
}

const filterState = {
    updateDateSlicer: false,
    searchResult: [],
    dateList: [],
    filterValues: {
        LOB: [],
        Region: [],
        Wbs_Name: [],
        Activity_Type: [],
        Activity_Status: [],
        Project_Name: [],
        Critical: []
    },
    activeSelectedFilters: {
        wbsname: [],
        activitytype: [],
        activitystatus: [],
        region: [],
        lob: [],
        projectname: [],
        critical: []
    }
}
export const FilterContext = React.createContext({
    state: filterState,
    setState: () => undefined,
});
FilterContext.displayName = "AuthContext";

export const FilterDispatchContext = React.createContext();
FilterDispatchContext.displayName = "FilterDispatchContext";

export function useFilterContext() {
    const context = React.useContext(FilterContext);
    if (!context) {
        console.warn("FilterProvider context is undefined, please verify you are calling useAuth() as child of a <FilterProvider> component.")
    }
    return context;
}

export function useFilterDispatchContext() {
    const context = React.useContext(FilterDispatchContext);
    if (context === undefined) {
        throw new Error('useFilterDispatchContext must be used within a FilterProvider');
    }
    return context;
}

export const FilterProvider = (props) => {
    const { children } = props;

    const [state, dispatchFilter] = useReducer(filterReducer, filterState);

    return (
        <FilterContext.Provider value={state} {...props}>
            <FilterDispatchContext.Provider value={dispatchFilter}>
                {children}
            </FilterDispatchContext.Provider>
        </FilterContext.Provider>
    );
}
