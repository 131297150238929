import utils from "./app-util";
import { emptyfunctinalCount } from "./filter";

function updateTECOPCount(listOnDisplay) {
    const risk_functional_count = JSON.parse(JSON.stringify(emptyfunctinalCount));
    for (let i = 0; i < listOnDisplay.length; ++i) {
        for (const key in risk_functional_count) {
            if (utils.hasClass(listOnDisplay[i], key.replace(/\s|,|\//g, '_'))) {
                risk_functional_count[key] = risk_functional_count[key] + 1
            }
        }
    }
    return risk_functional_count;
}
function getTheListIfTECOPOn(checkedTagElem, allCards) {
    const tagList = [], filteredElements = [];
    checkedTagElem.forEach(function (element) {
        tagList.push(element.value);
    });

    allCards.forEach(function (element) {
        for (let i = 0; i < tagList.length; ++i) {
            if (utils.hasClass(element, tagList[i].replace(/\s|,|\//g, '_'))) {
                filteredElements.push(element)
            }
        }
    })
    return [...new Set(filteredElements)];
}

function getTheListIfAdvanceSearchOn(advSearchElems, allCards) {
    const advSearchSelections = [];
    advSearchElems.forEach(function (element) {
        advSearchSelections.push(element.dataset.item);
    });
    const emocCardList = []
    allCards.forEach(function (card) {
        advSearchSelections.forEach(function (item) {
            if (card.classList.contains(item.replace(/\s|,|\//g, '_'))) {
                emocCardList.push(card);
            }
        });
    });
    return emocCardList;
}

function getTheListIfYearOn(selectedYearValue, allCards) {
    const filteredList = [];
    if (selectedYearValue !== "") {
        allCards.forEach(function (element) {
            if (new Date(parseInt(element.dataset.modified, 10)).getFullYear() === parseInt(selectedYearValue, 10)) {
                filteredList.push(element);
            }
        });
    }
    return filteredList;
}
function getTheListIfDateRangeOn(selectedDateRangeValue, allCards) {
    const filteredList = [];
    if (selectedDateRangeValue.length > 0) {
        allCards.forEach(function (element) {
            if (utils.isBetweenDate(parseInt(element.dataset.modified, 10), selectedDateRangeValue[0].getTime(), selectedDateRangeValue[1].getTime())) {
                filteredList.push(element);
            }
        });
    }
    return filteredList;
}
function loadFilteredDataForRiskPlots(url, setPlotData) {
    const filterdIds = utils.getVisibleRiskResultIDs();
    const filterLoaderElement = document.querySelector('.filter-loader-riskplot');
    filterLoaderElement.classList.remove('d-none');
    fetch(`/${url}/${filterdIds}`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        filterLoaderElement.classList.add('d-none');
        const graph_data = utils.formatGraphData(results);
        setPlotData(graph_data);
    }, (err) => {
        filterLoaderElement.classList.add('d-none');
        console.error("Error: " + err);
    }).catch((err) => {
        filterLoaderElement.classList.add('d-none');
        console.error("Error: " + err);
    });
}

function loadFilteredDataForEMoCPlots(url, setPlotData) {
    const filterdIds = utils.getVisibleEMoCResultIDs();
    const filterLoaderMoC = document.querySelector('.emoc-filter-plot-loader')
    filterLoaderMoC.classList.remove('d-none');
    fetch(`/${url}/${filterdIds}`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        filterLoaderMoC.classList.add('d-none');
        results.moc_big_rule.isNew = true;
        results.pie_changes_plot.isNew = true;
        results.pie_trends_plot.isNew = true;
        results.cost = [formatGraphNegativeData(results.cost, Object.keys(results.cost.data.pos), "pos", "emoc"), formatGraphNegativeData(results.cost, Object.keys(results.cost.data.neg), "neg", "emoc")]
        setPlotData(results);
    }, (err) => {
        console.error("Error: " + err);
        filterLoaderMoC.classList.add('d-none');
    }).catch((err) => {
        console.error("Error: " + err);
        filterLoaderMoC.classList.add('d-none');
    });
}
function loadFilteredDataForQualityPlots(url, setPlotData) {
    const filterdIds = utils.getVisibleResultIDs('indicesquality', '.quality-item.d-block', '.quality-item.d-none');
    const filterLoaderQuality = document.querySelector('.quality-filter-plot-loader')
    filterLoaderQuality.classList.remove('d-none');
    fetch(`/${url}/${filterdIds}`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        filterLoaderQuality.classList.add('d-none');
        results.heat_map_graph.isNew = true;
        results.process_safety_graph.isNew = true;
        results.root_cause_graph.isNew = true;
        results.mrf_graph.isNew = true;
        setPlotData({
            "heat_map_graph": results.heat_map_graph,
            "cost_graph":  utils.formatGraphData(results.cost_graph, Object.keys(results.cost_graph.data), 'quality'),
            "process_safety_graph": results.process_safety_graph,
            "root_cause_graph": results.root_cause_graph,
            "coq_graph": utils.formatGraphData(results.coq_graph, Object.keys(results.coq_graph.data), 'quality'),
            "mrf_graph": results.mrf_graph
        });
    }, (err) => {
        console.error("Error: " + err);
        filterLoaderQuality.classList.add('d-none');
    }).catch((err) => {
        console.error("Error: " + err);
        filterLoaderQuality.classList.add('d-none');
    });
}
function filterLoader(classSelector) {
    return (
        <div className={`${classSelector} d-none`}>
            <div className='position-absolute d-flex justify-content-center'
                style={{ left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'rgb(0, 0, 0, 0.1)' }}>
                <div className='align-self-center racespinner shadow d-flex justify-content-center' style={{ backgroundColor: "var(--main-bg-color)" }}>
                    <div className='align-self-center'>Loading...</div>
                </div>
            </div>
        </div>
    )
}
function formatGraphNegativeData(gData, groups, plotType, dataType) {
    const data = gData.data[plotType];
    const formatted = [], newFormat = {};
    for (let i = 0; i < data[groups[1]].length; ++i) {
        const temp = {};
        temp['label'] = gData.x_tick_labels[i];
        for (const val of groups) {
            temp[val] = (plotType==='neg' ? (-data[val][i]) : data[val][i])
        }
        temp[`${dataType}_ids`] = gData[`data_${dataType}_id`][plotType]
        formatted.push(temp);
    }
    newFormat.data = formatted;
    newFormat.groups = groups;
    newFormat.x_label = gData.x_label
    newFormat.y_label = gData.y_label
    newFormat.isNew = true;
    return newFormat;
}
export {
    updateTECOPCount, getTheListIfTECOPOn, getTheListIfAdvanceSearchOn,
    getTheListIfYearOn, getTheListIfDateRangeOn, loadFilteredDataForRiskPlots, loadFilteredDataForEMoCPlots, filterLoader,
    loadFilteredDataForQualityPlots, formatGraphNegativeData
}
