import React, { useState, useEffect, useCallback } from "react";
import LogoBranding from "../logo-branding";
import UserProfile from "../user-profile";
import PlannieSidebarMenu from "./plannie-sidebarmenu";
// import SearchAdvanced from "../search-advanced";
import RaceSpinner from "../spinner";
import PlannieSearch from "./plannie-search";
import alternateKeyword from "../alternatekeyword";
import utils from "../app-util";
import { toast } from "react-toastify";
import Plannie from "./plannie-dashboard";
import { emptyfunctinalCount, filterLists } from "../filter";
import PlannieSearchAdvanced from "./plannie-search-advanced";
import {
  displayAllPlannieCard,
  getPlannieFilterResult,
  plannieFilterOnClear,
  topNFilters,
  projectDateFilter,
  ifTopNFilterActive,
  getResultForTopN,
  isDatRangeSliderActive,
  getResultIfDateRangeSelected,
  getResultForFullMatch
} from "./plannie-FilterUtils";
import { plannieUtils } from "./plannie-utils";
import { useFilterContext, useFilterDispatchContext, useMount } from "../context/context";

const PLANNIE_PLOT_REFRESH = ".refresh-plots-for-filter-quality";

export default function PlannieMainLayout(props) {
  const items = filterLists();
  const [loading, setLoading] = useState(false);
  const [orgPlannieData,setOrgPlannieData]= useState(props.location.state.results);
  const [plannieData, setPlannieData] = useState(props.location.state.results);
  const [plannieKW] = useState(props.location.state.results.primavera.keywords);
  const [resultCount, setResultCount] = useState(
    props.location.state.results.primavera.Activity_ID.length
  );

  const [adminInfo] = useState({
    otheruser: props.location.state.otheruser,
    isSuperUser:props.location.state.isSuperUser
  });
  const {  filterValues, activeSelectedFilters, searchResult } = useFilterContext();
  const dispatchFilter = useFilterDispatchContext();
  useMount(()=>{
    sessionStorage.setItem('tempRes', JSON.stringify(props.location.state.results.plannieFormatted));
    dispatchFilter({
      type: 'initial',
      payload: {
        LOB: props.location.state.results.primavera.LOB,
        Region: props.location.state.results.primavera.Region,
        Project_Name: props.location.state.results.primavera.Project_Name,
        Wbs_Name: props.location.state.results.primavera.Wbs_Name,
        Activity_Type: props.location.state.results.primavera.Activity_Type,
        Activity_Status: props.location.state.results.primavera.Activity_Status,
        Critical: props.location.state.results.primavera.Critical
      },
      selection: {
        setResultCount: setResultCount,
        mainData: plannieData.plannieFormatted,
        currentSelected: {},
        allSelected: {},
        basefilterElement: {}
      }
    });
  });
  const [plannieDropdownFilter, setPlannieDropdownFilter] = useState({
    dropdowns: props.location.state.results.primavera, 
    dates: props.location.state.results.primavera.Project_Data_Date,
    LOB: props.location.state.results.primavera.LOB,
    Region: props.location.state.results.primavera.Region,
    Wbs_Name: props.location.state.results.primavera.Wbs_Name,
    Activity_Type: props.location.state.results.primavera.Activity_Type,
    Activity_Status: props.location.state.results.primavera.Activity_Status,
  });
  // const [filterCountsAndStatus, setFilterCountsAndStatus] = useState({
    // searchresultcount: props.location.state.results.primavera.Activity_ID.length,
    // riskFunctionalCount:
    //   props.location.state.results.risk.risk_functional_count,
    // isAdvancedTECOPSearchOn: false,
    // isHeatmapFilterOn: false,
  // });
  // const [filterCountPlannie, setFilterCountPlannie] = useState(
  //   props.location.state.results.primavera.Activity_ID.length
  // );
  const handleSearchOnKeyDown = (inputVal) => {
    document.querySelector('.clearall-plannie').click();
    // document.querySelector('svg#planniePlot.plannie').classList.remove('clickactive');
    document.querySelector(utils.elementSelectors.MUST_INCLUDE_SELECTOR).value = "";
    document.querySelector('#mustExclude').value = "";
    if (inputVal !== "") {
      const val = inputVal;
      loadQuery(val, props, setLoading, updateStates);
    }
  };

  useEffect(() => {
    document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value =
      props.location.state.query;
    window.sessionStorage.setItem("query", props.location.state.query);
  }, [props.location.state.query]);

  function updateStates(results, val) {
    const indices = results.primavera.Index;
    sessionStorage.setItem("indices", JSON.stringify(indices));
    sessionStorage.setItem('query', val);
    const formatedData = plannieUtils.formatData(results);
    sessionStorage.setItem('tempRes', JSON.stringify(formatedData.plannieFormatted));
    props.history.replace({
      pathname: "/dashboard",
      state: { query: val, results: formatedData },
    });
    setPlannieData(formatedData);
    setOrgPlannieData(formatedData);
    setResultCount(formatedData.primavera.Activity_ID.length);
    // setPlannieDropdownFilter({
    //   dropdowns: results, dates: results.primavera.Project_Data_Date,
    //   LOB: props.location.state.results.primavera.LOB,
    //   Region: props.location.state.results.primavera.Region,
    //   Wbs_Name: props.location.state.results.primavera.Wbs_Name,
    //   Activity_Type: props.location.state.results.primavera.Activity_Type,
    //   Activity_Status: props.location.state.results.primavera.Activity_Status,
    // });
    dispatchFilter({
      type: 'initial',
      payload: {
        LOB: results.primavera.LOB,
        Region: results.primavera.Region,
        Project_Name: results.primavera.Project_Name,
        Wbs_Name: results.primavera.Wbs_Name,
        Activity_Type: results.primavera.Activity_Type,
        Activity_Status: results.primavera.Activity_Status,
        Critical: results.primavera.Critical
      },
      selection: {
        setResultCount: setResultCount,
        mainData: formatedData.plannieFormatted,
        currentSelected: {},
        allSelected: {},
        basefilterElement: {}
      }
    });
  }
  function advanceSearchCallback(data) {
    // advancedSearch(data, filterCountsAndStatus, setFilterCountsAndStatus, setFilterValue, updateDropdownList);
  }
  function mustIncludeCallback(data) {
    document.querySelector('.clearall-plannie').click();
    loadQuery(
      `${
        document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR)
          .value
      }`,
      props,
      setLoading,
      updateStates,
      false,
      true
    );
  }
  function stackedBarChartClearBtnOnClick(setFilterCountsAndStatus) {
    let count = 0;
    document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR).forEach(function (card) {
        card.classList.remove('d-none');
        card.classList.add('d-block');
        count += 1;
    });
    utils.clearBarChartHighlight();
}
  function keyWordsQueryCallback(data) {
    document.querySelector('.clearall-plannie').click();
    setLoading(true);
    stackedBarChartClearBtnOnClick();
    //Fetch the new search query using the params
    fetch(
      `/new_query_from_keyword?query=${data.query}&back_value=${data.back}&front_value=${data.face}`,
      {
        headers: {
          "X-CSRFToken": utils.csrf(),
          Authorization: "Bearer " + window.sessionStorage.getItem("_at"),
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          utils.checkSessionStatus(res);
        }
        return res.text();
      })
      .then(
        (results) => {
          props.location.state.query = results;
          document.querySelector(
            utils.elementSelectors.SEARCH_FIELD_SELECTOR
          ).value = results;
          loadQuery(results, props, setLoading, updateStates, true);
        },
        (_err) => {
          setLoading(false);
          toast.warn("An error occurred while fetching results.");
        }
      );
  }

  function loadQuery(
    val,
    props,
    setLoading,
    updateStates,
    iskeywordSearch = false,
    mustIncludeExclude = false
  ) {
    setLoading(true);
    const mustIncludeVal = document.querySelector(utils.elementSelectors.MUST_INCLUDE_SELECTOR).value, mustExcludeVal = document.querySelector('#mustExclude').value;
    let url = "";
    if (mustIncludeExclude) {
      url = `/plannie_results?query=${val}&mi=${mustIncludeVal}&me=${mustExcludeVal}`;
    } else if (mustIncludeVal !== "") {
      window.sessionStorage.setItem("_prevquery", val);
      // val = `${val}/${mustIncludeVal}`;
      url = `/plannie_results?query=${val}&mi=${mustIncludeVal}&me=${mustExcludeVal}`;
    } else {
      url = `/plannie_results?query=${val}`;
    }
    fetch(url, {
      headers: {
        "X-CSRFToken": utils.csrf(),
        Authorization: "Bearer " + window.sessionStorage.getItem("_at"),
      },
    })
      .then((res) => {
        return utils.checkSessionStatus(res);
      })
      .then(
        (results) => {
          setLoading(false);
          if (results.primavera.Activity_Name!==undefined && Object.keys(results.primavera.Activity_Name).length > 0) {
            // As per new requirement mustinclude keyword should not be appended to the search query
            val = mustIncludeVal !== "" ? val.split("/")[0] : val;
            window.sessionStorage.setItem("query", val);
            props.location.state.query = val;
            updateStates(results, val);
          } else {
            setLoading(false);
            iskeywordSearch
              ? toast.warn("Selected search term doesn't fetch any results.")
              : toast.warn("Query doesn't fetch any results, please rephrase.");
            document.querySelector(
              utils.elementSelectors.SEARCH_FIELD_SELECTOR
            ).value = window.sessionStorage.getItem("query");
          }
        },
        (_err) => {
          setLoading(false);
          toast.warn("An error occurred while fetching results.");
        }
      );
  }

  // Store the selected dropdown filters in state to toggle plots for more narrow down the search
  // const [activeSelectedFilters, setActiveSelectedFilters] = useState({
  //   wbsname: [],
  //   activitytype: [],
  //   activitystatus: [],
  //   region: [],
  //   lob:[]
  // });
  // Flag if left sidebar clear All clicked
  const [isClearAllClicked, setIsClearAllClicked] = useState(false);
  const filterOnChangeCallBack = useCallback((value, filiterInputElement, sliderDateRange, currentIndividualSelection, previousSelection) => {
    setIsClearAllClicked(false);
    const fullMatchValue = document.querySelector("#fullmatch").checked;
    const isFullMatchActive = fullMatchValue; // to check if fullmatch selected
    if (filiterInputElement === 'fullmatch') {
      let mainData = JSON.parse(sessionStorage.getItem('tempRes'));
      dispatchFilter({
        type: filiterInputElement,
        payload: {},
        selection: {
          setResultCount: setResultCount,
          mainData: mainData,
          currentSelected: {},
          allSelected: value,
          basefilterElement: {}
        }
      });
      setTimeout(() => {
        document.querySelector(".refresh-plots-for-filter-plannie").click();
      }, 2000);
    }
    else if (filiterInputElement === 'topN') {
      // const [resultCount, dropdownFilterData, dateFilterData] = topNFilters(value);
      // setResultCount(resultCount);
      // setPlannieDropdownFilter({ dropdowns: dropdownFilterData, dates: dateFilterData });
      let mainData = JSON.parse(sessionStorage.getItem('tempRes'));
      let dataTobeFiltered = []
      if (isFullMatchActive) {
        dataTobeFiltered = getResultForFullMatch(mainData, fullMatchValue);
      } else {
        dataTobeFiltered = mainData;
      }
      dispatchFilter({
        type: filiterInputElement,
        payload: {},
        selection: {
          setResultCount: setResultCount,
          mainData: dataTobeFiltered,
          currentSelected: {},
          allSelected: value,
          basefilterElement: {}
        }
      });
      setTimeout(() => {
        document.querySelector(".refresh-plots-for-filter-plannie").click();
      }, 2000);
    } else if (filiterInputElement === 'date') {
      // const [resultCount, dropdownFilterData, dateFilterData] = projectDateFilter(value);
      // setResultCount(resultCount);
      // setPlannieDropdownFilter({dropdowns: dropdownFilterData, dates: dateFilterData });
      const [isTopNSelected, topNVal] = ifTopNFilterActive();
      let mainData = JSON.parse(sessionStorage.getItem('tempRes'));
      let dataTobeFiltered = []
      if(isTopNSelected && isFullMatchActive){
        const selectedFullMatch = getResultForFullMatch(mainData, fullMatchValue);
        dataTobeFiltered = getResultForTopN(selectedFullMatch, topNVal);
      } else if (isTopNSelected) {
        dataTobeFiltered = getResultForTopN(mainData, topNVal);
      } else if(isFullMatchActive){
        dataTobeFiltered = getResultForFullMatch(mainData, fullMatchValue);
      } else {
        dataTobeFiltered = mainData
      }
      dispatchFilter({
        type: filiterInputElement,
        payload: {},
        selection: {
          setResultCount: setResultCount,
          mainData: dataTobeFiltered,
          currentSelected: {},
          allSelected: value,
          basefilterElement: {}
        }
      });

      setTimeout(() => {
      document.querySelector(".refresh-plots-for-filter-plannie").click();
      }, 2000);
    }
    else if (value !== "") {
      // setActiveSelectedFilters(value);
      // const resultCount = getPlannieFilterResult(value, filiterInputElement, sliderDateRange);
      // const [resultCount, dropdownFilterData, dateFilterData] = getPlannieFilterResult(value, filiterInputElement, sliderDateRange);
      // setResultCount(resultCount);
      // setPlannieDropdownFilter({...plannieDropdownFilter, dropdowns: dropdownFilterData});
      
      // ============================
      // const basefilterElement = getPlannieFilterResult(value, filiterInputElement, sliderDateRange);
      // // console.log(previousSelection)
      // const accessParam = {
      //   'lob': 'LOB',
      //   'region': 'Region',
      //   'wbsname': 'Wbs_Name',
      //   'activitytype': 'Activity_Type',
      //   'activitystatus': 'Activity_Status'
      // }
      
      // const filteredData = plannieData.plannieFormatted.filter((item)=>{
        
      //   return value[filiterInputElement].includes(item[accessParam[filiterInputElement]])  
      // });

      // const temp = {
      //   LOB: [],
      //   Region: [],
      //   Wbs_Name: [],
      //   Activity_Type: [],
      //   Activity_Status: [],
      // }
      // filteredData.forEach((item, index) => {
      //   temp.LOB.push(item.LOB);
      //   temp.Region.push(item.Region);
      //   temp.Wbs_Name.push(item.Wbs_Name);
      //   temp.Activity_Type.push(item.Activity_Type);
      //   temp.Activity_Status.push(item.Activity_Status);
      // });
      const [isTopNSelected, topNVal] = ifTopNFilterActive();
      const isDateSelected = isDatRangeSliderActive()
      let mainData = JSON.parse(sessionStorage.getItem('tempRes'));
      let dataTobeFiltered = []
      if (isFullMatchActive && isTopNSelected && isDateSelected) {
        const selectedFullMatch = getResultForFullMatch(mainData, fullMatchValue);
        const topNRes = getResultForTopN(selectedFullMatch, topNVal);
        dataTobeFiltered = getResultIfDateRangeSelected(topNRes);
      } else if (isFullMatchActive && isTopNSelected) {
        const selectedFullMatch = getResultForFullMatch(mainData, fullMatchValue);
        dataTobeFiltered = getResultForTopN(selectedFullMatch, topNVal);
      } else if (isFullMatchActive && isDateSelected) {
        const selectedFullMatch = getResultForFullMatch(mainData, fullMatchValue);
        dataTobeFiltered = getResultIfDateRangeSelected(selectedFullMatch);
      } else if (isTopNSelected && isDateSelected) {
        const topNList = getResultForTopN(mainData, topNVal);
        dataTobeFiltered = getResultIfDateRangeSelected(topNList)
      } else if (isFullMatchActive) {
        dataTobeFiltered = getResultForFullMatch(mainData, fullMatchValue);
      } else if (isTopNSelected) {
        dataTobeFiltered = getResultForTopN(mainData, topNVal);
      } else if (isDateSelected) {
        dataTobeFiltered = getResultIfDateRangeSelected(mainData)
      } else {
        dataTobeFiltered = mainData;
      }

      dispatchFilter({
        type: filiterInputElement,
        payload: {},
        selection: {
          setResultCount: setResultCount,
          mainData: dataTobeFiltered, //JSON.parse(sessionStorage.getItem('tempRes')),
          currentSelected: currentIndividualSelection,
          allSelected: value,
          basefilterElement: {}
        }
      })
      // ============================================
      setTimeout(() => {
        document.querySelector(".refresh-plots-for-filter-plannie").click();
      }, 2000);
    }
  }, []);
  
  
  function clearTopNFilters() {
    setIsClearAllClicked(true);
    document.querySelector("#topNFilter").selectedIndex = 0;
    document.querySelector("#fullmatch").selectedIndex = 0;
    const [resultCount, dropdownFilterData, dateFilterData] = topNFilters('', true);
    // setResultCount(resultCount);
    // setPlannieDropdownFilter({ dropdowns: dropdownFilterData, dates: dateFilterData });
    // setPlannieDropdownFilter({ dropdowns: dropdownFilterData, dates: dateFilterData });
    dispatchFilter({
      type: 'initial',
      payload: dropdownFilterData,
      selection: {
        setResultCount: setResultCount,
        mainData: plannieData.plannieFormatted,
        currentSelected: {},
        allSelected: {},
        basefilterElement: {}
      }
    })
    document.querySelector(".refresh-plots-for-filter-plannie").click();
  };

  return (
    <div className="container-fluid px-0">
      <div className="d-flex align-items-stretch no-gutters">
        <div className="col app-layout-bg-color">
          {/* =================Top Bar=============== */}
          <header className="d-flex justify-content-between align-items-center no-gutters header-bar">
            <div className="" style={{ marginLeft: "1rem" }}>
              {/* <div className="d-flex justify-content-start align-items-center">
                                <div className="d-inline-block">
                                    <img className="logo" alt="" src={utils.logo}/>
                                </div>
                                <div className="d-inline-block">
                                    <h3 className="brand-name pt-3 ml-3">Qurie</h3>
                                </div>
                            </div> */}
              <LogoBranding />
            </div>
            <div className="col-7">
              <div className="my-1 main-page-search-field">
                <PlannieSearch
                  isAdvancedSearch={true}
                  searchCallback={handleSearchOnKeyDown}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center px-2 heaeder-menu">
              <div className="mr-2 mt-1 download-report position-relative">
                {/* <div><a className="download-report-toggle text-decoration-none" href="#downloadReport" data-toggle="collapse" aria-expanded="false">
                                    <img className="mr-1 download-report-toggle-icon" alt="" src={report} /></a></div> */}
              </div>
              <div className="ml-2 mt-1 user-detail">
                <UserProfile
                  isSuperUser={adminInfo.isSuperUser}
                  otheruser={adminInfo.otheruser}
                />
              </div>
            </div>
          </header>
          {/* =================Top Bar End=============== */}
          <div className="container-fluid px-0">
            <div className="d-flex">
              <div className="col-3 sidebar-container tour-sidebar">
                <PlannieSidebarMenu
                  dataType={"plannie"}
                  items={[]}
                  dataMainFormatted={plannieData.plannieFormatted}
                  data={plannieDropdownFilter}
                  filterOnChange={filterOnChangeCallBack}
                  clearTopNFilters={clearTopNFilters}
                  isClearAllClicked={isClearAllClicked}
                />
                <div className="">
                  <PlannieSearchAdvanced
                    className="advanced-search-wrapper"
                    advanceSearchCallback={advanceSearchCallback}
                    mustIncludeExcludeCallback={mustIncludeCallback}
                  />
                  <div className="tour-alternate-searchterm">
                    {alternateKeyword(
                      "plannie",
                      keyWordsQueryCallback,
                      plannieData.primavera.keywords,
                      "learningkw",
                      "emockw"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-9 mt-2 result-panel">

                <div className="disclaimer-n-notification small">
                  <div className="alert alert-warning mb-0 p-2 d-flex">
                    <div className="mx-2 text-danger text-center mb-0 align-self-center">
                      <p className="text-danger mb-0">&#x26A0;</p>
                      <p className="text-danger mb-0">Disclaimer</p>
                    </div>
                    <div className="align-self-center">
                      <p className="mb-0 ml-2">It's essential to recognize that the efficacy of 'Plannie' analysis hinges on the quality of raw data sourced from Primavera hence it's imperative to exercise caution when utilizing and disseminating this data.
                        Please note that 'Plannie' is not intended as a benchmarking tool and is solely for reference purposes.
                      </p>
                    </div>
                  </div>
                </div>
                
                <div
                  className={"tab-wrapper"}
                  style={{ transition: "all 1s ease 0s" }}
                >
                  <ul
                    className="nav nav-tabs tour-data-tabs"
                    id="mainLayoutTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active py-4"
                        id="risk-tab"
                        data-toggle="tab"
                        href="#risk"
                        role="tab"
                        aria-controls="risk"
                        aria-selected="true"
                      >
                        Plannie
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="mainLayoutTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="risk"
                      role="tabpanel"
                      aria-labelledby="risk-tab"
                    >
                      <div className="">
                        <Plannie
                          planniedata={plannieData}
                          setResultCount={setResultCount}
                          resultCount={resultCount}
                          activeSelectedFilters={activeSelectedFilters}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? <RaceSpinner /> : null}
    </div>
  );
}
