import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { LoginUtils } from "../login";
import PromptModal, { Message } from "./PromptModal";

const IDLE_TIME_IN_MINUTE = 30, PROMPT_TIME_IN_SECOND = 30;
const timeout = IDLE_TIME_IN_MINUTE * 60 * 1000; // in miliseconds
const promptBeforeIdle = PROMPT_TIME_IN_SECOND * 1000; // in miliseconds
export function PlannieIdleTimer() {
    const [remaining, setRemaining] = useState(timeout);
    const [openpropmpt, setOpenpropmpt] = useState(false);
    const login = new LoginUtils();
    const onIdle = () => {
        setOpenpropmpt(false);
        console.log('Session idle timeout');
        window.sessionStorage.clear();
        window.location.href = '/timeout';
    };

    const onActive = () => {
        setOpenpropmpt(false);
    };

    const onPrompt = () => {
        setOpenpropmpt(true);
    };

    const { getRemainingTime, activate, getElapsedTime } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    });

    useEffect(() => {
        const idleInterval = setInterval(() => {
            const elapsedTime = Math.ceil(getElapsedTime() / 1000);
            if (elapsedTime % 870 === 0) {
                login.getToken();
            }
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);
        return () => {
            clearInterval(idleInterval);
        }
    });
    const handleContinue = () => {
        activate();
        login.getToken();
    }
    const seconds = remaining > 1 ? 'seconds' : 'second';
    return (
        <PromptModal open={openpropmpt} actionButtnonClick={handleContinue}>
            <Message remaining={remaining} seconds={seconds} />
        </PromptModal>
    )
}
